import { AxiosInstance } from "axios";
import {
  CrmAction,
  CrmActionEntry,
  CrmEntry,
  CrmEntryCreateRequest,
  CrmType,
} from "./Crm.types";
import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";
import { mapReplacer } from "..";

/**
 * API method to create a new CrmAction on the backend
 *
 * @param crmAction The instance to create
 * @param axios The axios instance
 * @returns The newly created instance or undefined in case of error
 */
export const createNewCrmAction = async (
  crmAction: CrmAction,
  axios: AxiosInstance
): Promise<CrmAction | undefined> => {
  return axios
    .post("/crm/manage/action", JSON.stringify(crmAction, mapReplacer))
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during crm action creation", exc);
      generateNotification({
        value: i18n.t("general.notification.error.crmActionCreate"),
        type: NotificationType.ERROR,
      });
      return undefined;
    });
};

/**
 * API method to create a new CrmType on the backend
 *
 * @param crmAction The instance to create
 * @param axios The axios instance
 * @returns The newly created instance or undefined in case of error
 */
export const createNewCrmType = async (
  crmType: CrmType,
  axios: AxiosInstance
): Promise<CrmType | undefined> => {
  return axios
    .post("/crm/manage/type", JSON.stringify(crmType, mapReplacer))
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during crm type creation", exc);
      generateNotification({
        value: i18n.t("general.notification.error.crmTypeCreate"),
        type: NotificationType.ERROR,
      });
      return undefined;
    });
};

/**
 * API method to load all CrmActions
 * @param axios The axios instance
 * @returns All loaded instances
 */
export const getAllCrmActions = async (
  axios: AxiosInstance
): Promise<CrmAction[]> => {
  return axios
    .get("/crm/manage/all/action")
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during crm action load", exc);
      generateNotification({
        value: i18n.t("general.notification.error.crmActionLoad"),
        type: NotificationType.ERROR,
      });
      return [];
    });
};

/**
 * API method to load all CrmTypes
 * @param axios The axios instance
 * @returns All loaded instances
 */
export const getAllCrmTypes = async (
  axios: AxiosInstance
): Promise<CrmType[]> => {
  return axios
    .get("/crm/manage/all/type")
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during crm type load", exc);
      generateNotification({
        value: i18n.t("general.notification.error.crmTypeLoad"),
        type: NotificationType.ERROR,
      });
      return [];
    });
};

/**
 * API method to update the given instance on the backend
 *
 * @param crmAction The updated instaces
 * @param axios The axios instance
 * @returns The backend updated instance
 */
export const updateCrmAction = async (
  crmAction: CrmAction,
  axios: AxiosInstance
): Promise<CrmAction | undefined> => {
  return axios
    .post("/crm/manage/update/action", JSON.stringify(crmAction, mapReplacer))
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during crm action update", exc);
      generateNotification({
        value: i18n.t("general.notification.error.crmActionUpdate"),
        type: NotificationType.ERROR,
      });
      return undefined;
    });
};

/**
 * API method to update the given instance on the backend
 *
 * @param crmAction The updated instaces
 * @param axios The axios instance
 * @returns The backend updated instance
 */
export const updateCrmType = async (
  crmType: CrmType,
  axios: AxiosInstance
): Promise<CrmType | undefined> => {
  return axios
    .post("/crm/manage/update/type", JSON.stringify(crmType, mapReplacer))
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during crm type update", exc);
      generateNotification({
        value: i18n.t("general.notification.error.crmTypeUpdate"),
        type: NotificationType.ERROR,
      });
      return undefined;
    });
};

/**
 * API method to delete the given instance on the backend
 *
 * @param crmAction The instace to delete
 * @param axios The axios instance
 */
export const deleteCrmAction = async (
  crmAction: CrmAction,
  axios: AxiosInstance
): Promise<void> => {
  await axios.post("/crm/manage/delete/action", crmAction).catch((exc) => {
    console.error("Error during crm action delete", exc);
    generateNotification({
      value: i18n.t("general.notification.error.crmActionDelete"),
      type: NotificationType.ERROR,
    });
  });
};

/**
 * API method to delete the given instance on the backend
 *
 * @param crmType The instace to delete
 * @param axios The axios instance
 */
export const deleteCrmType = async (
  crmType: CrmType,
  axios: AxiosInstance
): Promise<void> => {
  axios.post("/crm/manage/delete/type", crmType).catch((exc) => {
    console.error("Error during crm type delete", exc);
    generateNotification({
      value: i18n.t("general.notification.error.crmTypeDelete"),
      type: NotificationType.ERROR,
    });
  });
};

/**
 * API method to create a new CrmEntry on the backend
 *
 * @param crmEntry The instance to create
 * @param axios The axios instance
 * @returns The newly created instance or undefined in case of error
 */
export const createNewCrmEntry = async (
  crmEntry: CrmEntryCreateRequest,
  axios: AxiosInstance
): Promise<CrmEntry | undefined> => {
  return axios
    .post("/crm/", crmEntry)
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during crm entry creation", exc);
      generateNotification({
        value: i18n.t("general.notification.error.crmEntryCreate"),
        type: NotificationType.ERROR,
      });
      return undefined;
    });
};

/**
 * API method to load all CrmEntries
 * @param axios The axios instance
 * @returns All loaded instances
 */
export const getAllCrmEntries = async (
  axios: AxiosInstance
): Promise<CrmEntry[]> => {
  return axios
    .get("/crm/all/entry")
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during crm entry load", exc);
      generateNotification({
        value: i18n.t("general.notification.error.crmEntryLoad"),
        type: NotificationType.ERROR,
      });
      return [];
    });
};

/**
 * API method to load all CrmEntries
 * @param crmEntryId the id of the corresponding crmEntry
 * @param axios The axios instance
 * @returns All loaded instances
 */
export const getAllCrmActionEntries = async (
  crmEntryId: string,
  axios: AxiosInstance
): Promise<CrmActionEntry[]> => {
  return axios
    .get("/crm/all/action", { params: { crmEntryId } })
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during crm action entry load", exc);
      generateNotification({
        value: i18n.t("general.notification.error.crmActionEntryLoad"),
        type: NotificationType.ERROR,
      });
      return [];
    });
};

/**
 * API method to create a new CrmActionEntry on the backend
 *
 * @param crmActionEntry The instance to create
 * @param axios The axios instance
 * @returns The newly created instance or undefined in case of error
 */
export const createNewCrmActionEntry = async (
  crmActionEntry: CrmActionEntry,
  axios: AxiosInstance
): Promise<CrmActionEntry | undefined> => {
  return axios
    .post("/crm/action", crmActionEntry)
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during crm action entry creation", exc);
      generateNotification({
        value: i18n.t("general.notification.error.crmActionEntryCreate"),
        type: NotificationType.ERROR,
      });
      return undefined;
    });
};

/**
 * API method to update the crmEntry on the backend
 *
 * @param crmEntry The updated instance
 * @param axios The axios instance
 * @returns The updated instance
 */
export const updateCrmEntry = async (
  crmEntry: CrmEntry,
  axios: AxiosInstance
): Promise<CrmEntry | undefined> => {
  return axios
    .post("/crm/update/entry", crmEntry)
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during crm entry update", exc);
      generateNotification({
        value: i18n.t("general.notification.error.crmUpdateCrmEntry"),
        type: NotificationType.ERROR,
      });
      return undefined;
    });
};

/**
 * API method to update the given CrmActionEntries on the backend
 *
 * @param entries The updated instances
 * @param axios The axios instance
 */
export const updateMultipleCrmActionEntries = async (
  entries: CrmActionEntry[],
  axios: AxiosInstance
): Promise<void> => {
  await axios.post("/crm/update/action/multiple", entries).catch((exc) => {
    console.error("Error during crm entry update", exc);
    generateNotification({
      value: i18n.t("general.notification.error.crmUpdateCrmActionEntry"),
      type: NotificationType.ERROR,
    });
  });
};
