import { ShiftType } from "../customerLocation/CustomerLocation.types";
import { Schedule } from "../project/Project.types";

/**
 * Protocol to be attached to a project
 */
export interface ProjectProtocol {
  id: string;
  createDate: Date;
  createdBy: string;
  lastUpdate?: Date;
  updatedBy?: string;
  customerId: string;
  orderId: string;
  officeId: string;
  projectId: string;
  shift: ShiftType;
  timeBookings: ProjectTimeBooking[];
  checkResults: ProjectCheckResult[];
  protocolDate: Date;
}

/**
 * enum to decides which parts of a projectProtocol should be created
 */
export enum ProtocolDocumentConfig {
  TIMES = "TIMES",
  PIECES = "PIECES",
  FULL = "FULL",
}

/**
 * timeBookings that are connected to a protocol
 */
export interface ProjectTimeBooking {
  id: string;
  userId: string;
  startTime: number;
  endTime: number;
  userDrivingTime: number;
  customerDrivingTime: number;
  breakTime: number;
  breakTimeUser: number;
  note: string;
  customerArrival: boolean;
  customerDeparture: boolean;
  userArrival: boolean;
  userDeparture: boolean;
  scheduled: boolean;
}

/**
 * Result of a check to be attached to a protocol
 */
export interface ProjectCheckResult {
  id: string;
  articleNumber: string;
  note: string;
  checkedTotal: number;
  io: number;
  nio: number;
  rework: number;
  totalIoAfterRework: number;
  foundErrorPatterns: Map<string, number>;
}

/**
 * Config with the neccessary information to create  protocol
 */
export interface ProtocolConfig {
  projectId: string;
  shift: ShiftType;
  date: Date;
  schedule: Schedule;
}
