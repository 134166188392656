import { Box, Button, Popup, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Customer,
  deleteCustomer,
  generateNotification,
  Right,
  updateSchambeckUserTableConfig,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { ReactComponent as DisabledIcon } from "../../assets/disabled.svg";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";
import { useUser } from "../../components/UserContext";
import { convertCustomersIntoTableEntries } from "../../utils/customer/Customer.utils";
import { isUserAllowedToDo } from "../../utils/user/User.utils";

export const CustomerOverview: React.FC = () => {
  const { user, axios, updateContextUser } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState<string>("");
  const [includeDeactivated, toggleIncludeDeactivated] =
    useState<boolean>(false);
  const [customerToDelete, setCustomerToDelete] = useState<Customer>();

  const { data: customers, mutate: updateCustomers } = useData("CUSTOMER_ALL", {
    config: { fallbackData: [], isPaused: () => !includeDeactivated },
  });
  const { data: activeCustomers, mutate: updateActiveCustomers } = useData(
    "CUSTOMER_ALL_ACTIVE",
    {
      config: { fallbackData: [], isPaused: () => includeDeactivated },
    }
  );
  //Holds tableRows for the customers
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertCustomersIntoTableEntries(
        includeDeactivated ? customers : activeCustomers,
        navigate,
        user,
        setCustomerToDelete
      ),
    [activeCustomers, customers, includeDeactivated, navigate, user]
  );

  /**
   * Helper method to delete a customer
   */
  const handleDeleteCustomer = (): void => {
    if (!customerToDelete?.id) return;
    deleteCustomer(axios, customerToDelete.id).then((success) => {
      setCustomerToDelete(undefined);
      if (success) {
        if (includeDeactivated) updateCustomers();
        else updateActiveCustomers();

        generateNotification({
          type: NotificationType.SUCCESS,
          value: t("general.notification.success.deleteCustomer"),
        });
      }
    });
  };

  return (
    <>
      <TopBar
        title={t("pages.customerOverview.topBarHeadline")}
        onSearch={setFilterValue}
      >
        {isUserAllowedToDo(user.right, Right.CUSTOMER_CREATE) && (
          <>
            <AddIcon
              title={t("general.icons.add")}
              onClick={() => navigate("/customer/create")}
            />
            <DisabledIcon
              style={{ color: includeDeactivated ? "#bc2e46" : "" }}
              title={t("general.icons.showInactive")}
              onClick={() => toggleIncludeDeactivated(!includeDeactivated)}
            />
          </>
        )}
      </TopBar>
      <Popup
        title={customerToDelete?.name}
        isOpen={!!customerToDelete}
        onClose={() => setCustomerToDelete(undefined)}
        buttons={[
          <Button
            value={t("general.buttons.cancel")}
            onClick={() => setCustomerToDelete(undefined)}
          />,
          <Button
            value={t("general.buttons.delete")}
            backgroundColor="#bc2e46"
            onClick={handleDeleteCustomer}
          />,
        ]}
      >
        <p>{t("pages.customerOverview.deletePopupText")}</p>
      </Popup>
      <Box>
        <Table
          tableConfig={user.tableConfig?.get(TableKey.CUSTOMER_OVERVIEW)}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.CUSTOMER_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.customerOverview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
