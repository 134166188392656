import { Box, Checkbox, Dropdown, Input, TopBar } from "@sam/components";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Bike,
  DocumentBucket,
  createNewBike,
  generateDropdownOptions,
  generateNotification,
  updateBike,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { FileOverview } from "../../components/files/FileOverview";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import { generateEmptyBike } from "../../utils/bike/Bike.utils";

const BikeCreateOrEdit: React.FC = () => {
  const { user, axios, updateFootnoteConfig } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const form = useRef<HTMLFormElement>(null);
  const button = useRef<HTMLButtonElement>(null);

  const location = useLocation<{ bike: Bike }>();
  const [bikeToEdit, setBikeToEdit] = useState<Bike>(
    location.state?.bike ?? generateEmptyBike()
  );
  const { data: allOffices } = useData("OFFICES_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  /**
   * Hook to update the footNote containg the create and update data
   */
  useEffect(() => {
    updateFootnoteConfig(bikeToEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bikeToEdit.createDate, updateFootnoteConfig]);
  /**
   * Submit handler to update or create a new bike
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;

    if (bikeToEdit.id)
      updateBike(axios, bikeToEdit).then((updatedBike) => {
        if (updatedBike && redirect) navigate("/bikes");
        else if (updatedBike) {
          setBikeToEdit(updatedBike);
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.saveSuccessfull"),
          });
        }
      });
    else
      createNewBike(axios, bikeToEdit).then((createdBike) => {
        if (createdBike && redirect) navigate("/bikes");
        else if (createdBike) {
          setBikeToEdit(createdBike);
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.saveSuccessfull"),
          });
        }
      });
  };

  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={
          location.state?.bike
            ? t("pages.bikes.create.headlineEdit")
            : t("pages.bikes.create.headlineCreate")
        }
      >
        <SaveButtons buttonRef={button} handleSubmit={handleSubmit} />
      </TopBar>
      <Box
        title={
          location.state?.bike
            ? t("pages.bikes.create.headlineEdit")
            : t("pages.bikes.create.headlineCreate")
        }
      >
        <>
          <div className="three-columns">
            <Input
              type="text"
              value={bikeToEdit.description}
              onChange={(description) =>
                setBikeToEdit({ ...bikeToEdit, description })
              }
              label={t("pages.bikes.create.description")}
            />
            <Input
              label={t("pages.bikes.create.price")}
              type="number"
              value={bikeToEdit.price}
              onChangeNumber={(price) => {
                if (price) setBikeToEdit({ ...bikeToEdit, price });
              }}
            />
            <Dropdown
              options={generateDropdownOptions(allOffices, "name", "id")}
              selectedOption={bikeToEdit.officeId}
              label={t("pages.bikes.create.officeId")}
              onChange={(officeId) =>
                setBikeToEdit((old) => ({ ...old, officeId }))
              }
              required
            />
          </div>
          <Checkbox
            isChecked={!bikeToEdit.disabled}
            label={t("pages.bikes.create.active")}
            onCheck={(disabled) => setBikeToEdit({ ...bikeToEdit, disabled })}
          />
        </>
      </Box>
      {bikeToEdit.id && (
        <Box title={t("pages.bikes.create.documents")}>
          <FileOverview
            bucket={DocumentBucket.BIKE}
            path={`${bikeToEdit.id}/`}
            userId={user.id}
          />
        </Box>
      )}
    </form>
  );
};

export default BikeCreateOrEdit;
