import { Dropdown, Input, TableRow } from "@sam/components";
import { NavigateFunction } from "react-router-dom";
import {
  CustomAddress,
  CustomerUser,
  Gender,
  Office,
  Right,
  SchambeckUser,
  Task,
  generateDropdownOptions,
} from "shared";
import {
  AccountType,
  Customer,
  CustomerTaskPrice,
} from "shared/src/customer/Customer.types";
import { CustomerLocation } from "shared/src/customerLocation/CustomerLocation.types";
import { uid } from "uid";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import i18n from "../../i18n/i18n";
import {
  generateEmptyContactInformation,
  isUserAllowedToDo,
} from "../user/User.utils";

/**
 * Util method to convert Customers into TableEntries
 * @param customers to convert
 * @returns array of TableRows
 */
export const convertCustomersIntoTableEntries = (
  customers: Customer[],
  navigate: NavigateFunction,
  user: SchambeckUser,
  handleDelete: (customer: Customer) => void
): TableRow[] => {
  return customers
    .sort((customerA, customerB) =>
      customerA.name.localeCompare(customerB.name)
    )
    .map((customer) => ({
      id: customer.id,
      onClick: () => navigate("/customer/create", { state: { customer } }),
      content: [
        customer.numberRangeNumber,
        customer.parentCompany || "",
        customer.name,
        customer.contact.street + " " + customer.contact.streetNumber,
        customer.contact.city,
        customer.contact.countryCode
          ? new Intl.DisplayNames("DE", { type: "region" }).of(
              customer.contact.countryCode
            ) || ""
          : "-",
        customer.disabled
          ? i18n.t("pages.customerOverview.deactivated")
          : i18n.t("pages.customerOverview.active"),
        <div className="table-action__icon-wrapper">
          <EditIcon
            title={i18n.t("general.icons.edit")}
            width={30}
            onClick={(evt) => {
              evt.stopPropagation();
              navigate("/customer/create", { state: { customer } });
            }}
          />
          {user && isUserAllowedToDo(user.right, Right.ALL) && (
            <DeleteIcon
              className="delete"
              onClick={(evt) => {
                evt.stopPropagation();
                handleDelete(customer);
              }}
            />
          )}
        </div>,
      ],
    }));
};

/**
 * Util method to filter valid customers to create an offer or project
 * @param customers array of customers that is valid
 *  for now it just checks for a hard lock, can be expanded with new rules
 * @returns Array of filtered Customers
 */
export const filterValidCustomers = (customers: Customer[]): Customer[] =>
  customers.filter((localCustomer) => !localCustomer.hardLocked);

/**
 * Util to convert the given customer task prices into editable table rows
 *
 * @param prices All custom prices for the customer
 * @param allOffices Loaded list of all offices for the dropdown
 * @param allTasks Loaded list of all tasks for the dropdown
 * @param onUpdate Callback for the update
 * @param onDelete Callback for the delete
 * @returns Table rows for every custom price
 */
export const convertCustomerTaskPricesIntoTableEntries = (
  prices: CustomerTaskPrice[],
  allOffices: Office[],
  allTasks: Task[],
  onUpdate: (
    newValue: string | number,
    key: keyof CustomerTaskPrice,
    id: string
  ) => void,
  onDelete: (idToDelete: string) => void
): TableRow[] => {
  return prices.map(
    (price): TableRow => ({
      id: price.id,
      content: [
        <Dropdown
          options={generateDropdownOptions(allOffices, "name", "id")}
          selectedOption={price.officeId}
          onChange={(officeId) => onUpdate(officeId, "officeId", price.id)}
        />,
        <Dropdown
          options={generateDropdownOptions(allTasks, "title", "id")}
          selectedOption={price.taskId}
          onChange={(taskId) => onUpdate(taskId, "taskId", price.id)}
        />,
        <Input
          type="number"
          value={price.price}
          onChangeNumber={(newPrice) => onUpdate(newPrice, "price", price.id)}
        />,
        <Input
          type="number"
          value={price.extraCharge}
          onChangeNumber={(extraCharge) =>
            onUpdate(extraCharge, "extraCharge", price.id)
          }
        />,
        <Input
          type="number"
          value={price.vlPrice}
          onChangeNumber={(vlPrice) => onUpdate(vlPrice, "vlPrice", price.id)}
        />,
        <DeleteIcon
          title={i18n.t("general.icons.delete")}
          onClick={() => onDelete(price.id)}
          width={35}
        />,
      ],
    })
  );
};

/**
 * Helper method to convert customerUsers into TableRows
 * @param customerUsers array of customerUsers
 * @param customers array of customers
 * @param navigate NavigateFunction used at the icons to navigate to edit
 * @returns array of tableRows
 */
export const convertCustomerUserIntoTableEntries = (
  customerUsers: CustomerUser[],
  navigate: NavigateFunction,
  customerLocations: CustomerLocation[],
  deleteAction?: (id: string) => void
): TableRow[] => {
  return customerUsers
    .sort((userA, userB) => userA.lastName.localeCompare(userB.lastName))
    .map((customerUser) => {
      const locations: string[] = customerUser.locations.map(
        (locationId) =>
          customerLocations.find((location) => location.id === locationId)
            ?.name || "-"
      );
      return {
        id: customerUser.id,
        onClick: () =>
          navigate("/customer/user/create", { state: { customerUser } }),
        content: [
          `${customerUser.lastName}, ${customerUser.firstName}`,
          locations.join(", "),
          customerUser.contact.mail,
          customerUser.contact.mobile,
          customerUser.contact.phone,
          customerUser.disabled
            ? i18n.t("pages.customerUser.overview.deactivated")
            : i18n.t("pages.customerUser.overview.active"),
          <>
            <EditIcon
              title={i18n.t("general.icons.edit")}
              width={30}
              onClick={(evt) => {
                evt.stopPropagation();
                navigate("/customer/user/create", { state: { customerUser } });
              }}
            />
            {deleteAction && (
              <DeleteIcon
                title={i18n.t("general.icons.delete")}
                width={30}
                onClick={(evt) => {
                  evt.stopPropagation();
                  deleteAction(customerUser.id);
                }}
              />
            )}
          </>,
        ],
      };
    });
};

/**
 * Util method to generate an empty customer
 * @param override override setter
 * @returns generated empty customer
 */
export const generateEmptyCustomer = (
  override?: Partial<Customer>
): Customer => ({
  id: undefined!,
  annotation: "",
  brancheId: "",
  contact: generateEmptyContactInformation(),
  createDate: new Date(),
  createdBy: "",
  invoiceRecipient: true,
  lastUpdate: new Date(),
  name: "",
  updatedBy: "",
  vatNumber: "",
  disabled: false,
  paymentCondition: undefined,
  factored: false,
  numberRangeNumber: 0,
  hardLocked: false,
  softLocked: false,
  customPrices: [],
  companyAccountTypes: new Map<string, AccountType>(),
  icoNumber: "",
  ...override,
});

/**
 * Helper to quickly generate an empty instance of CustomerTaskPrice
 *
 * @param override Optional override fields
 * @returns A new and empty instsance of CustomerTaskPrice
 */
export const generateEmptyCustomerTaskPrice = (
  override?: Partial<CustomerTaskPrice>
): CustomerTaskPrice => ({
  id: uid(),
  extraCharge: 0,
  officeId: "",
  price: 0,
  taskId: "",
  vlPrice: 0,
  ...override,
});

/**
 * Util method to generate an empty customerUser
 * @param override override setter
 * @returns generated empty customer
 */
export const generateEmptyCustomerUser = (
  override?: Partial<CustomerUser>
): CustomerUser => ({
  id: undefined!,
  contact: generateEmptyContactInformation(),
  createDate: new Date(),
  createdBy: "",
  customerId: "",
  firstName: "",
  gender: Gender.MALE,
  lastName: "",
  lastUpdated: undefined!,
  title: "",
  updatedBy: undefined!,
  disabled: false,
  locations: [],
  ...override,
});

/**
 * Util method to create an empty CustomAddresss
 * @param override  Partial address to adjust the created object
 * @returns created customAddress
 */
export const generateEmptyCustomAddress = (
  override?: Partial<CustomAddress>
): CustomAddress => ({
  name: "",
  city: "",
  street: "",
  streetNumber: "",
  zip: "",
  countryCode: "",
  ...override,
});
