// Generated with util/create-component.js
export interface TableComponentProps {
  maxItemsCount?: number;
  rows: TableRow[];
  header: TableHeader[];
  filterOptions?: FilterConfig;
  pagination?: boolean;
  paginationConfig?: PaginationConfig;
  showIndex?: boolean;
  expandable?: boolean;
  minWidth?: string;
  filterValue?: string;
  tableConfig?: TableHeader[];
  updateTableConfig?: (header: TableHeader[]) => unknown;
}

export enum TableKey {
  USER_OVERVIEW = "USER_OVERVIEW",
  CAR_OVERVIEW = "CAR_OVERVIEW",
  ABSENCE_REASON_OVERVIEW = "ABSENCE_REASON_OVERVIEW",
  BIKE_OVERVIEW = "BIKE_OVERVIEW",
  BRANCHE_OVERVIEW = "BRANCHE_OVERVIEW",
  BUSINESS_AREA_OVERVIEW = "BUSINESS_AREA_OVERVIEW",
  CHANGE_HISTORY = "CHANGE_HISTORY",
  COMPANY_OVERVIEW = "COMPANY_OVERVIEW",
  CRM_ENTRY_OVERVIEW = "CRM_ENTRY_OVERVIEW",
  CRM_MANAGE_OVERVIEW = "CRM_MANAGE_OVERVIEW",
  CURRENCY_OVERVIEW = "CURRENCY_OVERVIEW",
  CUSTOMER_OVERVIEW = "CUSTOMER_OVERVIEW",
  CUSTOMER_ARTICLE_OVERVIEW = "CUSTOMER_ARTICLE_OVERVIEW",
  FEEDBACK_ENTRY_OVERVIEW = "FEEDBACK_ENTRY_OVERVIEW",
  CUSTOMER_LOCATION_OVERVIEW = "CUSTOMER_LOCATION_OVERVIEW",
  CUSTOMER_PROJECT_OVERVIEW = "CUSTOMER_PROJECT_OVERVIEW",
  CUSTOMER_USER_OVERVIEW = "CUSTOMER_USER_OVERVIEW",
  DEPARTMENT_OVERVIEW = "DEPARTMENT_OVERVIEW",
  DUNNING_OVERVIEW = "DUNNING_OVERVIEW",
  DUNNING_LEVEL_OVERVIEW = "DUNNING_LEVEL_OVERVIEW",
  HOLIDAY_DECIDE = "HOLIDAY_DECIDE",
  HOLIDAY_OVERVIEW = "HOLIDAY_OVERVIEW",
  INVENTORY_OVERVIEW = "INVENTORY_OVERVIEW",
  INVOICE_OVERVIEW = "INVOICE_OVERVIEW",
  ACCOUNTING_OVERVIEW = "ACCOUNTING_OVERVIEW",
  OFFICE_OVERVIEW = "OFFICE_OVERVIEW",
  OFFER_OVERVIEW = "OFFER_OVERVIEW",
  PAYMENT_CONDITION_OVERVIEW = "PAYMENT_CONDITION_OVERVIEW",
  PDL_OVERVIEW = "PDL_OVERVIEW",
  PROJECT_OVERVIEW = "PROJECT_OVERVIEW",
  TASK_OVERVIEW = "TASK_OVERVIEW",
  USER_FUNCTION_OVERVIEW = "USER_FUNCTION_OVERVIEW",
  USER_LANGUAGE_OVERVIEW = "USER_LANGUAGE_OVERVIEW",
  USER_ROLE_OVERVIEW = "USER_ROLE_OVERVIEW",
  WORK_EQUIPMENT_OVERVIEW = "WORK_EQUIPMENT_OVERVIEW",
  WORK_QUALIFICATION_OVERVIEW = "WORK_QUALIFICATION_OVERVIEW",
  PROTOCOL_OVERVIEW = "PROTOCOL_OVERVIEW",
}

export interface TableRow {
  id: string;
  content: (string | JSX.Element | number)[];
  onClick?(): void;
  children?: React.ReactNode;
  background?: string;
}

export interface TableHeader {
  text: string;
  sortable?: boolean;
  growFactor?: number;
  hideColumnOnSmall?: boolean;
  backgroundColor?: string;
  border?: string;
  visible?: boolean;
  showSum?: boolean;
  sortByDate?: boolean;
}

export interface PaginationConfig {
  max?: number;
  next?: string;
  previous?: string;
  pageSize: number;
}

export interface FilterConfig {
  items: JSX.Element;
  title?: string;
  height?: number;
}

export interface SortConfig {
  colIndex: number;
  reverse: boolean;
}
