import { AxiosInstance } from "axios";
import { LockDate } from "./LockDate.types";
import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";

/**
 * Util method to get the active lockDate
 * @param axios instance of axios
 * @returns Promise containing the active LockDate or undefined if nothing was found
 */
export const getActiveLockDate = async (
  axios: AxiosInstance,
  companyId: string
): Promise<LockDate | undefined> => {
  return axios
    .get("lockdate/", { params: { companyId } })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.WARNING,
        value: i18n.t("general.notification.error.getLockDate"),
      });
      console.error("No active lockDate was found", exc);
    });
};

/**
 * API Method to create a new LockDate
 * @param axios instance of axios
 * @returns Promise containing the date of the new lockDate
 */
export const createNewLockDate = async (
  axios: AxiosInstance,
  lockDate: LockDate
): Promise<LockDate | undefined> => {
  return axios
    .post("lockdate/", lockDate)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while saving lockDate", exc);
      generateNotification({
        type: NotificationType.WARNING,
        value: i18n.t("general.notification.error.createLockDate"),
      });
    });
};

/**
 * API Method to load all active lockDates
 * @param axios instance of axios
 * @returns  Array of lockDates
 */
export const getAllActiveLockDates = async (
  axios: AxiosInstance
): Promise<LockDate[]> => {
  return axios
    .get("lockdate/all")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading lockDate", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getActiveLockDates"),
      });
    });
};
