import { Button, TableRow } from "@sam/components";
import { Holiday, SimpleUser } from "shared";
import { ReactComponent as EyeIcon } from "../../assets/eye.svg";
import { ReactComponent as PendingIcon } from "../../assets/pending.svg";
import { ReactComponent as DeniedIcon } from "../../assets/cancel.svg";
import { ReactComponent as ApprovedIcon } from "../../assets/approved.svg";
import { NavigateFunction } from "react-router-dom";
import { Right, UserRight } from "shared/src/userRole/UserRole.types";
import {
  getUserNameForSimpleUser,
  isUserAllowedToDo,
} from "../user/User.utils";
import { Dayjs } from "dayjs";
import i18n from "../../i18n/i18n";
import {
  AgendaEntry,
  AgendaEntryGroup,
} from "@sam/components/src/Agenda/Agenda.types";

/**
 * Helper to transform given holidays to Tablerows
 * @param holidays to create rows from
 * @param navigate to create Icons
 * @returns Tablerows
 */
export const convertHolidaysIntoTableEntries = (
  userRights: UserRight,
  holidays: Holiday[],
  users: SimpleUser[],
  navigate: NavigateFunction
): TableRow[] => {
  return holidays.map((holiday) => ({
    id: holiday.id,
    content: [
      getUserNameForSimpleUser(holiday.userId, users),
      holiday.startDate.toLocaleDateString("de-DE"),
      holiday.endDate.toLocaleDateString("de-DE"),
      holiday.totalAmount,
      <div>
        {isUserAllowedToDo(userRights, Right.HOLIDAY_READ) && (
          <EyeIcon
            width={40}
            onClick={() => navigate(`/holiday/create`, { state: { holiday } })}
          />
        )}
      </div>,
    ],
  }));
};

export const generateEmptyHoliday = (override?: Partial<Holiday>): Holiday => ({
  approved: false,
  createDate: new Date(),
  denied: false,
  deputyId: "",
  endDate: new Date(),
  id: undefined!,
  officeId: "",
  startDate: new Date(),
  totalAmount: 1,
  userId: "",
  ...override,
});

/**
 *  Helper method to calculate vacation days left
 * @param totalVacationDays of the user
 * @param existingHolidays to subtract from the total days
 * @returns number of vacation days left
 */
export const calculateVacationDaysLeft = (
  totalVacationDays: number,
  existingHolidays: Holiday[]
): number => {
  let vacationDaysUsed: number = 0;
  existingHolidays.map((holiday) => (vacationDaysUsed += holiday.totalAmount));
  return totalVacationDays - vacationDaysUsed;
};

/**
 * Util method to check if a day is in a given range
 * @param day to check
 * @param startDate of the range to check
 * @param endDate of the range to check
 * @returns boolean if the date is in the range or not
 */
export const checkIfDayIsInRange = (
  day: Dayjs,
  startDate: Date,
  endDate: Date
): boolean =>
  day.isSame(startDate, "date") ||
  (day.isAfter(startDate) && day.isBefore(endDate)) ||
  day.isSame(endDate);

/**
 * Util method to convert holidayRequests to TableEntries
 * @param holidayRequests to convert into TableEntries
 * @param users to get the name of the requesting persons
 * @returns Array of TableRows
 */
export const convertHolidayRequestsToTableEntries = (
  holidayRequests: Holiday[],
  users: SimpleUser[],
  navigate: NavigateFunction
): TableRow[] => {
  return holidayRequests.map((request) => ({
    id: request.id,
    content: [
      getUserNameForSimpleUser(request.userId, users),
      request.createDate.toLocaleDateString(),
      request.startDate.toLocaleDateString(),
      request.endDate.toLocaleDateString(),
      request.totalAmount,
      <Button
        value={i18n.t("general.buttons.edit")}
        maxWidth={100}
        disabled={request.approved || request.denied}
        onClick={() => navigate(`/holiday/edit/decide`, { state: { request } })}
      />,
      <div className="holiday-requests__table-icon-wrapper">
        {request.approved ? (
          <ApprovedIcon width={30} />
        ) : request.denied ? (
          <DeniedIcon width={30} />
        ) : (
          <PendingIcon width={30} />
        )}
      </div>,
    ],
  }));
};

/**
 * Util method that combines absences and holidays to AgendaEntries
 * @param users list of all users that are present in the absences and holidays to get the name from
 * @param absences absences that should be converted and displayed in the Agenda
 * @param holidays holidays that should be converted and displayed in the Agenda
 * @returns Array of AgendaEntries
 */
export const generateAgendaEntries = (
  users: SimpleUser[],
  holidays: Holiday[]
): AgendaEntryGroup[] => {
  const groups: AgendaEntryGroup[] = users.map((user) => {
    const entries: AgendaEntry[] = holidays
      .filter((holiday) => holiday.userId === user.id && !holiday.denied)
      .map((holiday) => ({
        id: holiday.id,
        background: holiday.denied || holiday.approved ? "#34B15E" : "#D3F4E4",
        border: `1px solid #34B15E`,
        start: holiday.startDate.toISOString().split("T")[0],
        end: holiday.endDate.toISOString().split("T")[0],
        title: i18n.t("general.agendaEntries.holiday"),
        onClick: () => {},
        color: holiday.denied || holiday.approved ? "white" : "black",
      }));

    const group: AgendaEntryGroup = {
      groupName: getUserNameForSimpleUser(user.id, users),
      id: user.id,
      entries,
    };

    return group;
  });

  return groups;
};
