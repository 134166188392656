import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { updateSchambeckUserTableConfig, useData } from "shared";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";
import { convertCustomerUserIntoTableEntries } from "../../utils/customer/Customer.utils";
import { useUser } from "../../components/UserContext";

export const CustomerUserOverview: React.FC = () => {
  const { axios, updateContextUser, user } = useUser();

  const { data: loadedCustomerUsers } = useData("CUSTOMER_USERS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerLocations } = useData("CUSTOMER_LOCATION_ALL", {
    config: { fallbackData: [] },
  });
  const [filterValue, setFilterValue] = useState<string>("");

  const { t } = useTranslation();
  const navigate = useNavigate();

  //Hook to convert customerUsers into TableEntries after loading
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertCustomerUserIntoTableEntries(
        loadedCustomerUsers,
        navigate,
        loadedCustomerLocations
      ),
    [loadedCustomerUsers, navigate, loadedCustomerLocations]
  );

  return (
    <>
      <TopBar
        title={t("pages.customerUser.overview.topBarHeadline")}
        onSearch={setFilterValue}
      >
        <AddIcon
          title={t("general.icons.add")}
          onClick={() => navigate("/customer/user/create")}
        />
      </TopBar>
      <Box>
        <Table
          tableConfig={user.tableConfig?.get(TableKey.CUSTOMER_USER_OVERVIEW)}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.CUSTOMER_USER_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.customerUser.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
