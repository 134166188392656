import {
  Box,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Option,
  Popup,
  Table,
  TextArea,
  TopBar,
} from "@sam/components";
import { TableHeader, TableRow } from "@sam/components/src/Table/Table.types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  checkForExistingCustomerVatId,
  checkForOpenProjects,
  createNewCustomer,
  CustomerType,
  CustomerUser,
  generateCountryCodeOptions,
  generateNotification,
  getAllCustomerUsersForCustomer,
  PaymentCondition,
  Project,
  unassignMultipleUserFromCustomer,
  updateCustomer,
  useData,
} from "shared";
import {
  AccountType,
  Customer,
  CustomerTaskPrice,
} from "shared/src/customer/Customer.types";
import { CustomerLocation } from "shared/src/customerLocation/CustomerLocation.types";
import { NotificationType } from "shared/src/notification/notification.types";
import {
  getAllProjectsByInvoiceRecipient,
  getAllProjectsByWorkingLocationCustomer,
  getAllProjectsForCustomer,
} from "shared/src/project/Project.axios";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import {
  convertCustomerTaskPricesIntoTableEntries,
  convertCustomerUserIntoTableEntries,
  generateEmptyCustomer,
  generateEmptyCustomerTaskPrice,
  generateEmptyCustomerUser,
} from "../../utils/customer/Customer.utils";
import {
  convertCustomerLocationsIntoTableEntries,
  generateEmptyCustomerLocation,
} from "../../utils/customerLocation/CustomerLocation.utils";
import { convertRelatedProjectsIntoTableEntries } from "../../utils/project/Project.utils";
import { convertSimpleUsersToTimeAtCustomerTableRows } from "../../utils/user/User.utils";

export const CustomerCreateOrEdit: React.FC = () => {
  const { axios, updateFootnoteConfig } = useUser();
  const location = useLocation<{ customer?: Customer }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  const [isDuplicateVatId, toggleDuplicateVatId] = useState<boolean>(false);
  const [isProjectPopupOpen, toggleProjectPopupOpen] = useState<boolean>(false);
  const [isVatWarningPopupOpen, toggleVatWarningPopupOpen] =
    useState<boolean>(false);

  const [customerLocationRows, setCustomerLocationRows] = useState<TableRow[]>(
    []
  );
  const [customerToEdit, setCustomerToEdit] = useState<Customer>(
    location.state?.customer || generateEmptyCustomer()
  );
  const [customerUsers, setCustomerUsers] = useState<CustomerUser[]>([]);
  const [customerUserToRemove, setCustomerUserToRemove] = useState<string[]>(
    []
  );
  const [dropdownOptions, setDropdownOptions] = useState<Option[]>([]);
  const { data: loadedBranches } = useData("BRANCHES_ALL", {
    config: { fallbackData: [] },
  });
  const [relatedProjects, setRelatedProjects] = useState<Project[]>([]);
  const [selectedCustomerType, setSelectedCustomerType] =
    useState<CustomerType>(CustomerType.CUSTOMER);

  const { data: allCustomerLocations } = useData("CUSTOMER_LOCATION_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedPaymentConditions } = useData("PAYMENT_CONDITION_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allOffices } = useData("OFFICES_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: allTasks } = useData("TASKS_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedSimpleUsers } = useData("SIMPLEUSERS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allCompanies } = useData("COMPANY_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  /**
   * Hook to update the footNote containg the create and update data
   */
  useEffect(() => {
    updateFootnoteConfig(customerToEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerToEdit.createDate, updateFootnoteConfig]);
  /**
   * Util method to check if a vatNumber already exists
   */
  const handleVatOrIcoIdChange = (
    keyToCheck: keyof Pick<Customer, "vatNumber" | "icoNumber">
  ): void => {
    if (
      (keyToCheck === "vatNumber" && !customerToEdit.vatNumber) ||
      (keyToCheck === "icoNumber" && !customerToEdit.icoNumber)
    )
      return;
    checkForExistingCustomerVatId(
      axios,
      customerToEdit[keyToCheck],
      customerToEdit?.id
    ).then((numberExists) => {
      toggleDuplicateVatId(numberExists);
      if (numberExists) {
        generateNotification({
          type: NotificationType.INFO,
          value: t(`general.notification.info.${keyToCheck}Exists`),
        });
      } else toggleDuplicateVatId(false);
    });
  };
  /**
   * TableRows containing the employee experience
   */
  const employeeExpirienceRows: TableRow[] = useMemo(
    () =>
      convertSimpleUsersToTimeAtCustomerTableRows(
        loadedSimpleUsers,
        customerToEdit.id
      ),
    [customerToEdit.id, loadedSimpleUsers]
  );

  /**
   * tableRows according to the selected filter
   */
  const relatedRows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertRelatedProjectsIntoTableEntries(relatedProjects, navigate),
    [navigate, relatedProjects]
  );

  /**
   * Loads projects according to the selected Filter
   */
  useEffect(() => {
    if (!customerToEdit.id) return;
    switch (selectedCustomerType) {
      case CustomerType.INVOCIE_RECIPIENT:
        getAllProjectsByInvoiceRecipient(axios, customerToEdit.id).then(
          setRelatedProjects
        );
        break;
      case CustomerType.WORKING_LOCATION:
        getAllProjectsByWorkingLocationCustomer(axios, customerToEdit.id).then(
          setRelatedProjects
        );
        break;
      case CustomerType.CUSTOMER:
        getAllProjectsForCustomer(axios, customerToEdit.id).then(
          setRelatedProjects
        );
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerToEdit.id, selectedCustomerType]);
  /**
   * holds options for all paymentConditions
   */
  const paymentConditionOptions: Option[] = useMemo((): Option[] => {
    return loadedPaymentConditions.map((condition) => ({
      label: condition.description,
      value: condition.id,
    }));
  }, [loadedPaymentConditions]);

  /**
   * Helper to locally remove the customer user
   */
  const locallyRemoveCustomer = useCallback((id: string): void => {
    setCustomerUserToRemove((old) => old.concat(id));
    setCustomerUsers((old) => old.filter((user) => user.id !== id));
  }, []);

  /**
   * Helper to quickly update a single office account type
   * @param newValue The user entered value
   * @param officeId The id of the office to update
   */
  const updateOfficeAccountType = (
    newValue: AccountType,
    officeId: string
  ): void => {
    const customerCopy: Customer = { ...customerToEdit };
    if (!customerCopy.companyAccountTypes)
      customerCopy.companyAccountTypes = new Map<string, AccountType>();
    customerCopy.companyAccountTypes.set(officeId, newValue);
    setCustomerToEdit({ ...customerCopy });
  };

  /**
   * holds the contact person table rows
   */
  const customerUserRows: TableRow[] = useMemo((): TableRow[] => {
    return convertCustomerUserIntoTableEntries(
      customerUsers,
      navigate,
      allCustomerLocations,
      locallyRemoveCustomer
    );
  }, [allCustomerLocations, customerUsers, locallyRemoveCustomer, navigate]);

  //Hook to load all customerUsers and convert them into TableEntries if we are editing and not creating a new customer
  useEffect(() => {
    if (!customerToEdit.id) return;
    getAllCustomerUsersForCustomer(axios, customerToEdit.id).then(
      (loadedCustomerUsers) => setCustomerUsers(loadedCustomerUsers)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerToEdit.id, navigate]);

  //Hook to set tableRows of customerLocations
  useEffect(() => {
    const locationsToDisplay: CustomerLocation[] = allCustomerLocations.filter(
      (loc) => loc.customerId === customerToEdit.id
    );
    setCustomerLocationRows(
      convertCustomerLocationsIntoTableEntries(locationsToDisplay, navigate)
    );
  }, [allCustomerLocations, customerToEdit, navigate]);

  //UseEffect hook to set the dropdownOptions after all branches are loaded
  useEffect(
    () =>
      setDropdownOptions(
        loadedBranches.map((branch) => ({
          label: branch.name,
          value: branch.id,
        }))
      ),
    [loadedBranches]
  );

  /**
   * Submit handler that creates or edits a customer
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = (redirect: boolean, confirmed?: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;
    if (
      !confirmed &&
      (!customerToEdit.vatNumber || customerToEdit.vatNumber === "") &&
      (!customerToEdit.icoNumber || customerToEdit.icoNumber === "")
    ) {
      toggleVatWarningPopupOpen(true);
      return;
    }

    Promise.all([
      unassignMultipleUserFromCustomer(customerUserToRemove, axios),
      customerToEdit.id
        ? updateCustomer(axios, customerToEdit)
        : createNewCustomer(axios, customerToEdit),
    ]).then(([, savedCustomer]) => {
      if (redirect && savedCustomer) navigate("/customer");
      else if (savedCustomer) {
        setCustomerToEdit(savedCustomer);
        generateNotification({
          type: NotificationType.SUCCESS,
          value: t("general.notification.success.saveSuccessfull"),
        });
      }
    });
  };

  /**
   * Util method to handle a change of the paymentCondition and update the customer according to it
   * @param id of the selected paymentCondition
   */
  const handlePaymentConditionChange = (id: string): void => {
    const foundCondition: PaymentCondition | undefined =
      loadedPaymentConditions?.find((condition) => condition.id === id);
    setCustomerToEdit({
      ...customerToEdit,
      paymentCondition: foundCondition,
    });
  };

  /**
   * Helper to update a nested customer task price
   * @param newValue The user entered value
   * @param key The key to update
   * @param id The id of the custom price to update
   */
  const updateCustomPrice = (
    newValue: string | number,
    key: keyof CustomerTaskPrice,
    id: string
  ): void => {
    const customPriceToUpdate: CustomerTaskPrice | undefined =
      customerToEdit.customPrices.find((price) => price.id === id);
    if (!customPriceToUpdate) return;
    const customPrices: CustomerTaskPrice[] = customerToEdit.customPrices
      .filter((price) => price.id !== id)
      .concat([{ ...customPriceToUpdate, [key]: newValue }]);
    setCustomerToEdit((old) => ({ ...old, customPrices }));
  };

  /**
   * Helper to delete a single customer task price entry
   * @param idToDelete The id of the customer task price to delete
   */
  const deleteCustomerPrice = (idToDelete: string): void => {
    setCustomerToEdit((old) => ({
      ...old,
      customPrices: old.customPrices.filter((price) => price.id !== idToDelete),
    }));
  };

  /**
   * Util method to toggle an info popup, in case the customer has open projects
   */
  const handleLockCustomer = (): void => {
    checkForOpenProjects(axios, customerToEdit.id).then((hasOpenProjects) => {
      toggleProjectPopupOpen(hasOpenProjects);
      setCustomerToEdit({ ...customerToEdit, hardLocked: true });
    });
  };

  return (
    <form
      className="customer-create__wrapper"
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate("/customer")}
        title={
          location.state?.customer
            ? t("pages.customerCreateOrEdit.topBarHeadlineEdit")
            : t("pages.customerCreateOrEdit.topBarHeadlineCreate")
        }
      >
        <SaveButtons
          handleSubmit={handleSubmit}
          buttonRef={button}
          disabled={isDuplicateVatId}
        />
      </TopBar>
      <Popup
        title={t("pages.customerCreateOrEdit.popup.title")}
        buttons={[
          <Button
            value={t("general.buttons.confirm")}
            onClick={() => toggleProjectPopupOpen(false)}
          />,
        ]}
        isOpen={isProjectPopupOpen}
        onClose={() => toggleProjectPopupOpen(false)}
      >
        <p>{t("pages.customerCreateOrEdit.popup.text")}</p>
      </Popup>
      <Popup
        isOpen={isVatWarningPopupOpen}
        onClose={() => toggleVatWarningPopupOpen(false)}
        buttons={[
          <Button
            value={t("general.buttons.cancel")}
            onClick={() => toggleVatWarningPopupOpen(false)}
          />,
          <Button
            value={t("general.buttons.confirm")}
            onClick={() => {
              handleSubmit(false, true);
              toggleVatWarningPopupOpen(false);
            }}
          />,
        ]}
      >
        <p>{t("pages.customerCreateOrEdit.popup.vatIcoText")}</p>
      </Popup>
      <Box>
        <p className="customer-create__box-headline">
          {t("pages.customerCreateOrEdit.baseData")}
        </p>
        <div className="three-columns">
          {customerToEdit.id && (
            <Input
              disabled
              type="text"
              label={t("pages.customerCreateOrEdit.numberRangeNumber")}
              value={customerToEdit.numberRangeNumber}
            />
          )}
          <div>
            <Checkbox
              isChecked={!customerToEdit.disabled}
              label={t("pages.customerCreateOrEdit.active")}
              onCheck={(disabled) =>
                setCustomerToEdit({
                  ...customerToEdit,
                  disabled: !disabled,
                })
              }
            />
            <Checkbox
              isChecked={customerToEdit.invoiceRecipient}
              label={t("pages.customerCreateOrEdit.invoiceReceipient")}
              onCheck={() =>
                setCustomerToEdit({
                  ...customerToEdit,
                  invoiceRecipient: !customerToEdit.invoiceRecipient,
                })
              }
            />
            <Checkbox
              isChecked={customerToEdit.factored}
              label={t("pages.customerCreateOrEdit.factored")}
              onCheck={(factored) =>
                setCustomerToEdit({
                  ...customerToEdit,
                  factored,
                })
              }
            />
          </div>
          <div>
            <Checkbox
              isChecked={customerToEdit.softLocked}
              label={t("pages.customerCreateOrEdit.softLocked")}
              onCheck={(softLocked) =>
                setCustomerToEdit({
                  ...customerToEdit,
                  softLocked,
                })
              }
            />
            <Checkbox
              isChecked={customerToEdit.hardLocked}
              label={t("pages.customerCreateOrEdit.hardLocked")}
              onCheck={(hardLocked) =>
                hardLocked
                  ? handleLockCustomer()
                  : setCustomerToEdit({
                      ...customerToEdit,
                      hardLocked,
                    })
              }
            />
          </div>
          <Input
            required
            type="text"
            label={t("pages.customerCreateOrEdit.name")}
            value={customerToEdit.name}
            onChange={(name) => setCustomerToEdit({ ...customerToEdit, name })}
          />
          <Input
            type="text"
            label={t("pages.customerCreateOrEdit.internalName")}
            value={customerToEdit.internalName}
            onChange={(internalName) =>
              setCustomerToEdit({ ...customerToEdit, internalName })
            }
          />
          <Input
            type="text"
            label={t("pages.customerCreateOrEdit.vatNumber")}
            value={customerToEdit.vatNumber}
            onChange={(vatNumber) =>
              setCustomerToEdit({ ...customerToEdit, vatNumber })
            }
            onBlur={() => handleVatOrIcoIdChange("vatNumber")}
          />
          <Input
            type="text"
            label={t("pages.customerCreateOrEdit.icoNumber")}
            value={customerToEdit.icoNumber}
            onChange={(icoNumber) =>
              setCustomerToEdit({ ...customerToEdit, icoNumber })
            }
            onBlur={() => handleVatOrIcoIdChange("icoNumber")}
          />

          <Input
            type="text"
            label={t("pages.customerCreateOrEdit.annotation")}
            value={customerToEdit.annotation}
            onChange={(annotation) =>
              setCustomerToEdit({ ...customerToEdit, annotation })
            }
          />
          <Dropdown
            label={t("pages.customerCreateOrEdit.branch")}
            options={dropdownOptions}
            onChange={(brancheId) =>
              setCustomerToEdit({ ...customerToEdit, brancheId })
            }
            selectedOption={customerToEdit.brancheId}
          />
          <Dropdown
            options={paymentConditionOptions}
            selectedOption={customerToEdit?.paymentCondition?.id || ""}
            onChange={handlePaymentConditionChange}
            label={t("pages.customerCreateOrEdit.paymentCondition")}
          />
          <Input
            type="text"
            value={customerToEdit.parentCompany}
            onChange={(parentCompany) =>
              setCustomerToEdit({ ...customerToEdit, parentCompany })
            }
            label={t("pages.customerCreateOrEdit.group")}
          />
          <TextArea
            value={customerToEdit.internalAnnotation || ""}
            label={t("pages.customerCreateOrEdit.internalAnnotation")}
            onChange={(internalAnnotation) =>
              setCustomerToEdit({ ...customerToEdit, internalAnnotation })
            }
          />
          <TextArea
            value={customerToEdit.locationInfo || ""}
            label={t("pages.customerCreateOrEdit.locationInfo")}
            onChange={(locationInfo) =>
              setCustomerToEdit({ ...customerToEdit, locationInfo })
            }
          />
        </div>
      </Box>
      <Box>
        <p className="customer-create__box-headline">
          {t("pages.customerCreateOrEdit.contactInformation")}
        </p>
        <div className="three-columns">
          <Input
            label={t("pages.customerCreateOrEdit.phone")}
            type="text"
            value={customerToEdit.contact.phone}
            onChange={(phone) =>
              setCustomerToEdit({
                ...customerToEdit,
                contact: { ...customerToEdit.contact, phone },
              })
            }
          />
          <Input
            type="text"
            value={customerToEdit.contact.mobile}
            onChange={(mobile) =>
              setCustomerToEdit({
                ...customerToEdit,
                contact: { ...customerToEdit.contact, mobile },
              })
            }
            label={t("pages.customerCreateOrEdit.mobile")}
          />
          <Input
            type="email"
            value={customerToEdit.contact.mail}
            onChange={(mail) =>
              setCustomerToEdit({
                ...customerToEdit,
                contact: { ...customerToEdit.contact, mail },
              })
            }
            label={t("pages.customerCreateOrEdit.mail")}
          />

          <Input
            type="text"
            value={customerToEdit.contact.website || ""}
            onChange={(website) =>
              setCustomerToEdit({
                ...customerToEdit,
                contact: { ...customerToEdit.contact, website },
              })
            }
            label={t("pages.customerCreateOrEdit.website")}
          />
          <Input
            type="text"
            value={customerToEdit.contact.fax || ""}
            onChange={(fax) =>
              setCustomerToEdit({
                ...customerToEdit,
                contact: { ...customerToEdit.contact, fax },
              })
            }
            label={t("pages.customerCreateOrEdit.fax")}
          />
        </div>
        <div className="three-columns">
          <Input
            type="text"
            value={customerToEdit.contact.street}
            onChange={(street) =>
              setCustomerToEdit({
                ...customerToEdit,
                contact: { ...customerToEdit.contact, street },
              })
            }
            label={t("pages.customerCreateOrEdit.street")}
          />
          <Input
            type="text"
            value={customerToEdit.contact.streetNumber}
            onChange={(streetNumber) =>
              setCustomerToEdit({
                ...customerToEdit,
                contact: { ...customerToEdit.contact, streetNumber },
              })
            }
            label={t("pages.customerCreateOrEdit.streetNumber")}
          />
          <Dropdown
            selectedOption={customerToEdit.contact.countryCode}
            label={t("pages.customerCreateOrEdit.country")}
            onChange={(countryCode) =>
              setCustomerToEdit({
                ...customerToEdit,
                contact: {
                  ...customerToEdit.contact,
                  countryCode,
                },
              })
            }
            options={generateCountryCodeOptions()}
          />
          <Input
            type="text"
            value={customerToEdit.contact.zip}
            onChange={(zip) =>
              setCustomerToEdit({
                ...customerToEdit,
                contact: { ...customerToEdit.contact, zip },
              })
            }
            label={t("pages.customerCreateOrEdit.zip")}
          />
          <Input
            type="text"
            value={customerToEdit.contact.city}
            onChange={(city) =>
              setCustomerToEdit({
                ...customerToEdit,
                contact: { ...customerToEdit.contact, city },
              })
            }
            label={t("pages.customerCreateOrEdit.city")}
          />
        </div>
      </Box>
      {customerToEdit.id && (
        <>
          <Box>
            <div className="customer-create__box-header">
              <p className="customer-create__box-headline">
                {t("pages.customerCreateOrEdit.customerUsers")}
              </p>
              <AddIcon
                title={t("general.icons.add")}
                width={30}
                onClick={() => {
                  const customerUser: CustomerUser = generateEmptyCustomerUser({
                    customerId: customerToEdit.id,
                  });
                  navigate("/customer/user/create", {
                    state: { customerUser },
                  });
                }}
              />
            </div>
            <Table
              rows={customerUserRows}
              header={
                t("pages.customerCreateOrEdit.userTableHeader", {
                  returnObjects: true,
                }) as TableHeader[]
              }
            />
          </Box>
          <Box>
            <div className="customer-create__box-header">
              <p className="customer-create__box-headline">
                {t("pages.customerCreateOrEdit.customerLocations")}
              </p>
              <AddIcon
                title={t("general.icons.add")}
                width={30}
                onClick={() => {
                  const location: CustomerLocation =
                    generateEmptyCustomerLocation({
                      customerId: customerToEdit.id,
                    });
                  navigate("/customer/location/create", {
                    state: { location },
                  });
                }}
              />
            </div>
            <Table
              rows={customerLocationRows}
              header={
                t("pages.customerCreateOrEdit.locationTableHeader", {
                  returnObjects: true,
                }) as TableHeader[]
              }
            />
          </Box>
          <Box>
            <div className="customer-create__box-header">
              <p className="customer-create__box-headline">
                {t("pages.customerCreateOrEdit.prices")}
              </p>
              <AddIcon
                title={t("general.icons.add")}
                width={30}
                onClick={() =>
                  setCustomerToEdit((old) => ({
                    ...old,
                    customPrices: [
                      ...(old.customPrices || []),
                      generateEmptyCustomerTaskPrice(),
                    ],
                  }))
                }
              />
            </div>
            <Table
              header={
                t("pages.customerCreateOrEdit.pricesTableHeader", {
                  returnObjects: true,
                }) as TableHeader[]
              }
              rows={convertCustomerTaskPricesIntoTableEntries(
                customerToEdit.customPrices,
                allOffices,
                allTasks,
                updateCustomPrice,
                deleteCustomerPrice
              )}
            />
          </Box>
          <Box title={t("pages.customerCreateOrEdit.customerExperience")}>
            <Dropdown
              onChange={(type) => setSelectedCustomerType(type as CustomerType)}
              options={Object.values(CustomerType).map((type) => ({
                label: t(`pages.customerCreateOrEdit.customerType.${type}`),
                value: type,
              }))}
            />
            <Table
              rows={relatedRows}
              header={
                t("pages.customerCreateOrEdit.relatedTableHeader", {
                  returnObjects: true,
                }) as TableHeader[]
              }
            />
          </Box>
          <Box title={t("pages.customerCreateOrEdit.employeeExperience")}>
            <Table
              rows={employeeExpirienceRows}
              header={
                t("pages.customerCreateOrEdit.experienceTableHeader", {
                  returnObjects: true,
                }) as TableHeader[]
              }
            />
          </Box>
        </>
      )}
      <Box title={t("pages.customerCreateOrEdit.accountingTitle")}>
        {allCompanies.map((company) => (
          <Dropdown
            key={company.id}
            label={company.name}
            onChange={(newValue: AccountType) =>
              updateOfficeAccountType(newValue, company.id)
            }
            options={Object.keys(AccountType).map((type) => ({
              label: t(`pages.customerCreateOrEdit.accountType.${type}`),
              value: type,
            }))}
            selectedOption={customerToEdit.companyAccountTypes?.get(company.id)}
          />
        ))}
      </Box>
    </form>
  );
};
