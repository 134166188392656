import { LockDate } from "shared";

/**
 * Util method to create an empty LockDate
 * @param override Partial lockDate
 * @returns created lockDate
 */
export const generateEmptyLockDate = (override?: Partial<LockDate>) => ({
  active: true,
  companyId: "",
  createDate: undefined!,
  createdBy: undefined!,
  date: new Date(),
  id: undefined!,
  ...override,
});
