import React, { useEffect, useRef } from "react";
import { DataSet, DataGroup, DataItem } from "vis-timeline/standalone";
import { Timeline, TimelineOptions } from "vis-timeline";
import { AgendaProps } from "./Agenda.types";
import "./Agenda.scss";
import { Button } from "..";
import dayjs from "dayjs";
import { generateIsoDateForDateAndTime } from "shared";

export const Agenda: React.FC<AgendaProps> = ({
  entryGroups,
  addItem,
  start,
  end,
  deleteItem = () => {},
  handleEdit = () => {},
  filterValue,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  /**
   * Timeline to be used to draw the agenda on
   */
  let timeLine: Timeline;
  const options: TimelineOptions = {
    verticalScroll: true,
    zoomKey: "ctrlKey",
    editable: true,
    onRemove: (item) => deleteItem(item.id.toString()),
    onAdd: (newItem) => (addItem ? addItem(newItem) : () => {}),
    orientation: "top",
    stack: false,
    showWeekScale: true,
    height: `${window.screen.height * 0.55}px`,
    start: start ? start : dayjs().add(-1, "week").toISOString(),
    end: end ? end : dayjs().add(1, "week").toISOString(),
    locale: "de", // needs to be set additionally in the moment instance... WIP
  };

  //useEffect to convert the entryGroups into Datasets to use in the timeline component
  useEffect(() => {
    const groups: DataSet<DataGroup> = new DataSet();
    const items: DataSet<DataItem> = new DataSet();
    if (!containerRef.current) return;
    // loops over swimlanes
    entryGroups.forEach((group) => {
      if (
        !filterValue ||
        group.groupName
          .toLocaleLowerCase()
          .includes(filterValue.toLocaleLowerCase())
      )
        groups.add({
          id: group.id,
          content: group.groupName,
          options: group.options,
        });

      // loops over entries in swimlane
      group.entries.forEach((entry) => {
        items.add({
          content: entry.title,
          id: entry.id,
          start: generateIsoDateForDateAndTime(new Date(entry.start), 0),
          end: generateIsoDateForDateAndTime(new Date(entry.end), 1440),
          group: group.id,
          style: `background: ${entry.background}; 
          color: ${entry.color || ""}; 
          border: ${entry.border}; 
          border-radius: 4px;
          opacity: 80%`,
        });
      });
    });
    // this is needed so the ref gets "reset" prior the render
    containerRef.current.innerHTML = "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timeLine = new Timeline(containerRef.current, items, groups, options);
    timeLine.on("click", (properties) => {
      if (properties.what === "item") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const selectedItem = items.get(properties.item) as any as DataItem;
        handleEdit(String(selectedItem.id));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryGroups]);

  return (
    <div className="agenda-component__wrapper">
      <div className="agenda-component__agenda" ref={containerRef}></div>
      <div className="agenda-component__buttons">
        <Button value={"+"} onClick={() => timeLine.zoomIn(0.5)} />
        <Button value={"-"} onClick={() => timeLine.zoomOut(0.5)} />
      </div>
    </div>
  );
};
