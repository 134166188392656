import { Box, Button, Input, Table, TableRow, TopBar } from "@sam/components";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Language, Period, getProofOfActivityPdf, useData } from "shared";
import { useUser } from "../../components/UserContext";
import { convertUsersIntoSelectableTableRows } from "../../utils/user/User.utils";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { downloadFile } from "../../utils/files/Files.utils";

export const ActivityProofCreate: React.FC = () => {
  const { axios } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [period, setPeriod] = useState<Period>({
    endDate: new Date(),
    startDate: new Date(),
  });
  const { data: loadedUsers } = useData("SCHAMBECK_USERS_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  /**
   * TableRows containing all users to select from
   */
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertUsersIntoSelectableTableRows(
        loadedUsers,
        setSelectedUsers,
        selectedUsers
      ),
    [loadedUsers, selectedUsers]
  );

  /**
   * Method to get the documents for the selected users in the selected period
   */
  const getDocumentFile = (): Promise<void> => {
    return getProofOfActivityPdf(axios, {
      userIds: selectedUsers,
      period,
      language: Language.DE,
    }).then(
      (doc) =>
        doc && downloadFile(doc, t("pages.activityProof.create.fileName"))
    );
  };
  return (
    <>
      <TopBar
        onBackClick={() => navigate(-1)}
        title={t("pages.activityProof.create.topBarHeadline")}
      >
        <Button value={t("general.buttons.create")} onClick={getDocumentFile} />
      </TopBar>
      <Box>
        <Input
          type="date"
          value={period.startDate}
          onChangeDate={(startDate) =>
            startDate && setPeriod({ ...period, startDate })
          }
          label={t("pages.activityProof.create.periodStart")}
        />
        <Input
          type="date"
          value={period.endDate}
          onChangeDate={(endDate) =>
            endDate && setPeriod({ ...period, endDate })
          }
          label={t("pages.activityProof.create.periodEnd")}
        />
        <Table
          rows={rows}
          header={
            t("pages.activityProof.create.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
