import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { updateSchambeckUserTableConfig, useData } from "shared";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { convertOffersIntoTableEntries } from "../../utils/order/Order.utils";
import { useUser } from "../../components/UserContext";

export const CustomerProjectOverview: React.FC = () => {
  const [filterValue, setFilterValue] = useState<string>("");
  const { axios, updateContextUser, user } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: loadedCustomerProjects } = useData("CUSTOMER_PROJECT", {
    config: { fallbackData: [] },
  });

  const { data: loadedCustomers } = useData("CUSTOMER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedOrders } = useData("ORDER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerLocations } = useData("CUSTOMER_LOCATION_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerContacts } = useData("CUSTOMER_USERS_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * Holds the entries as a TableRow Array
   */
  const rows: TableRow[] = useMemo((): TableRow[] => {
    return convertOffersIntoTableEntries(
      loadedCustomerProjects,
      loadedOrders,
      loadedCustomers,
      loadedCustomerLocations,
      loadedCustomerContacts,
      navigate
    );
  }, [
    loadedCustomerContacts,
    loadedCustomerLocations,
    loadedCustomerProjects,
    loadedCustomers,
    loadedOrders,
    navigate,
  ]);

  return (
    <>
      <TopBar title="Projekt-Bestellungen" onSearch={setFilterValue}>
        <PlusIcon
          title={t("general.icons.add")}
          onClick={() => navigate("/customer/project/edit")}
        />
      </TopBar>
      <Box>
        <Table
          tableConfig={user.tableConfig?.get(
            TableKey.CUSTOMER_PROJECT_OVERVIEW
          )}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.CUSTOMER_PROJECT_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.order.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
