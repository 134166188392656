import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getActiveLockDate, LockDate } from "shared";
import { useUser } from "../../components/UserContext";

export const useLockDate = (
  companyId?: string
): [Date | undefined, Dispatch<SetStateAction<LockDate | undefined>>] => {
  const [activeLockDate, setActiveLockDate] = useState<LockDate>();
  const { axios } = useUser();

  useEffect(() => {
    if (!companyId) return;
    getActiveLockDate(axios, companyId).then((loadedDate) =>
      setActiveLockDate(loadedDate)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);
  return [activeLockDate?.date, setActiveLockDate];
};
