import {
  Box,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Popup,
  TopBar,
} from "@sam/components";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CustomerUser,
  Gender,
  createNewCustomerUser,
  deactivateCustomerUser,
  generateCountryCodeOptions,
  generateDropdownOptions,
  generateNotification,
  updateCustomerUser,
  useData,
} from "shared";
import { getAllCustomerLocationsForCustomer } from "shared/src/customerLocation/CustomerLocation.axios";
import { CustomerLocation } from "shared/src/customerLocation/CustomerLocation.types";
import { NotificationType } from "shared/src/notification/notification.types";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import { generateEmptyCustomerUser } from "../../utils/customer/Customer.utils";

export const CreateOrEditCustomerUsers: React.FC = () => {
  const { axios, updateFootnoteConfig } = useUser();
  const location = useLocation<{ customerUser?: CustomerUser }>();
  const { data: allDepartments } = useData("DEPARTMENT_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const [customerUserToEdit, setCustomerUserToEdit] = useState<CustomerUser>(
    location.state?.customerUser || generateEmptyCustomerUser()
  );
  const [customerLocations, setCustomerLocations] = useState<
    CustomerLocation[]
  >([]);

  //Hook to load the customerLocations after the customer changes
  useEffect(() => {
    getAllCustomerLocationsForCustomer(
      axios,
      customerUserToEdit.customerId
    ).then(setCustomerLocations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerUserToEdit.customerId]);

  /**
   * Hook to update the footNote containg the create and update data
   */
  useEffect(() => {
    updateFootnoteConfig(customerUserToEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerUserToEdit.createDate, updateFootnoteConfig]);

  const { data: loadedCustomer } = useData("CUSTOMER_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const [showPopup, toggleShowPopup] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  /**
   * Util method to create or update a customerUser
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;

    customerUserToEdit.id
      ? updateCustomerUser(axios, customerUserToEdit).then(
          (updatedCustomerUser) => {
            if (redirect && updatedCustomerUser) navigate(-1);
            else if (updatedCustomerUser) {
              setCustomerUserToEdit(updatedCustomerUser);
              generateNotification({
                type: NotificationType.SUCCESS,
                value: t("general.notification.success.saveSuccessfull"),
              });
            }
          }
        )
      : createNewCustomerUser(axios, customerUserToEdit).then(
          (createdCustomerUser) => {
            if (redirect && createdCustomerUser) navigate("/customer/user");
            else if (createdCustomerUser) {
              setCustomerUserToEdit(createdCustomerUser);
              generateNotification({
                type: NotificationType.SUCCESS,
                value: t("general.notification.success.saveSuccessfull"),
              });
            }
          }
        );
  };
  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <Popup
        isOpen={showPopup}
        onClose={() => toggleShowPopup(false)}
        title={t("pages.customerUser.createOrEdit.deactivatePopupTitle")}
        buttons={[
          <Button
            value={t("general.buttons.yes")}
            backgroundColor="#BC2E46"
            onClick={() =>
              deactivateCustomerUser(axios, customerUserToEdit.id).then(() =>
                navigate("/customer/user")
              )
            }
          />,
          <Button
            value={t("general.buttons.no")}
            onClick={() => toggleShowPopup(false)}
          />,
        ]}
      >
        <p>{t("general.popup.deactivateCustomerUser")}</p>
      </Popup>
      <TopBar
        title={t("pages.customerUser.createOrEdit.topbarHeadlineCreate")}
        onBackClick={() => navigate("/customer/user")}
      >
        {customerUserToEdit.id && (
          <Button
            value={t("general.buttons.deactivate")}
            backgroundColor="#BC2E46"
            onClick={() => toggleShowPopup(true)}
          />
        )}
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
      </TopBar>
      <Box>
        <>
          <div className="three-columns">
            <Dropdown
              onChange={(gender) =>
                setCustomerUserToEdit({
                  ...customerUserToEdit,
                  gender: gender as Gender,
                })
              }
              label={t("pages.customerUser.createOrEdit.gender")}
              selectedOption={customerUserToEdit.gender}
              options={Object.values(Gender).map((gender) => ({
                value: gender,
                label: t(`general.gender.${gender}`),
              }))}
            />
            <Input
              type="text"
              required
              value={customerUserToEdit.firstName}
              onChange={(firstName) =>
                setCustomerUserToEdit({ ...customerUserToEdit, firstName })
              }
              label={t("pages.customerUser.createOrEdit.firstName")}
            />
            <Input
              type="text"
              value={customerUserToEdit.title}
              onChange={(title) =>
                setCustomerUserToEdit({ ...customerUserToEdit, title })
              }
              label={t("pages.customerUser.createOrEdit.title")}
            />
            <Input
              required
              type="text"
              value={customerUserToEdit.lastName}
              onChange={(lastName) =>
                setCustomerUserToEdit({ ...customerUserToEdit, lastName })
              }
              label={t("pages.customerUser.createOrEdit.lastName")}
            />
            <Dropdown
              options={
                loadedCustomer.map((customer) => ({
                  value: customer.id,
                  label: customer.name,
                })) || []
              }
              selectedOption={customerUserToEdit.customerId}
              onChange={(customerId) =>
                setCustomerUserToEdit({ ...customerUserToEdit, customerId })
              }
              label={t("pages.customerUser.createOrEdit.customer")}
            />
            <Dropdown
              multi
              onChangeMultiple={(locations) =>
                setCustomerUserToEdit({ ...customerUserToEdit, locations })
              }
              options={generateDropdownOptions(customerLocations, "name", "id")}
              selectedMultiOptions={customerUserToEdit.locations}
              label={t("pages.customerUser.createOrEdit.locations")}
            />
            <Dropdown
              options={generateDropdownOptions(allDepartments, "title", "id")}
              selectedOption={customerUserToEdit.departmentId}
              onChange={(departmentId) =>
                setCustomerUserToEdit({ ...customerUserToEdit, departmentId })
              }
              label={t("pages.customerUser.createOrEdit.departmentId")}
            />
            <Input
              required
              type="text"
              value={customerUserToEdit.departmentDetail}
              onChange={(departmentDetail) =>
                setCustomerUserToEdit({
                  ...customerUserToEdit,
                  departmentDetail,
                })
              }
              label={t("pages.customerUser.createOrEdit.departmentDetails")}
            />
            <div>
              <Checkbox
                onCheck={(disabled) =>
                  setCustomerUserToEdit({
                    ...customerUserToEdit,
                    disabled: !disabled,
                  })
                }
                isChecked={!customerUserToEdit.disabled}
                label={t("pages.customerUser.createOrEdit.active")}
              />
              <Checkbox
                onCheck={(defaultInvoiceContact) =>
                  setCustomerUserToEdit({
                    ...customerUserToEdit,
                    defaultInvoiceContact,
                  })
                }
                isChecked={!!customerUserToEdit.defaultInvoiceContact}
                label={t(
                  "pages.customerUser.createOrEdit.defaultInvoiceContact"
                )}
              />
              <Checkbox
                onCheck={(defaultDunningContact) =>
                  setCustomerUserToEdit({
                    ...customerUserToEdit,
                    defaultDunningContact,
                  })
                }
                isChecked={!!customerUserToEdit.defaultDunningContact}
                label={t(
                  "pages.customerUser.createOrEdit.defaultDunningContact"
                )}
              />
            </div>
          </div>
        </>
      </Box>
      <Box title={t("pages.customerUser.createOrEdit.contact")}>
        <div className="three-columns">
          <Input
            label={t("pages.customerUser.createOrEdit.phone")}
            type="text"
            value={customerUserToEdit.contact.phone}
            onChange={(phone) =>
              setCustomerUserToEdit({
                ...customerUserToEdit,
                contact: { ...customerUserToEdit.contact, phone },
              })
            }
          />
          <Input
            type="text"
            value={customerUserToEdit.contact.mobile}
            onChange={(mobile) =>
              setCustomerUserToEdit({
                ...customerUserToEdit,
                contact: { ...customerUserToEdit.contact, mobile },
              })
            }
            label={t("pages.customerUser.createOrEdit.mobile")}
          />
          <Input
            type="email"
            value={customerUserToEdit.contact.mail}
            onChange={(mail) =>
              setCustomerUserToEdit({
                ...customerUserToEdit,
                contact: { ...customerUserToEdit.contact, mail },
              })
            }
            label={t("pages.customerUser.createOrEdit.mail")}
          />

          <Input
            type="text"
            value={customerUserToEdit.contact.fax || ""}
            onChange={(fax) =>
              setCustomerUserToEdit({
                ...customerUserToEdit,
                contact: { ...customerUserToEdit.contact, fax },
              })
            }
            label={t("pages.customerUser.createOrEdit.fax")}
          />
        </div>
        <div className="three-columns">
          <Input
            type="text"
            value={customerUserToEdit.contact.street}
            onChange={(street) =>
              setCustomerUserToEdit({
                ...customerUserToEdit,
                contact: { ...customerUserToEdit.contact, street },
              })
            }
            label={t("pages.customerUser.createOrEdit.street")}
          />
          <Input
            type="text"
            value={customerUserToEdit.contact.streetNumber}
            onChange={(streetNumber) =>
              setCustomerUserToEdit({
                ...customerUserToEdit,
                contact: { ...customerUserToEdit.contact, streetNumber },
              })
            }
            label={t("pages.customerUser.createOrEdit.streetNumber")}
          />
          <Dropdown
            selectedOption={customerUserToEdit.contact.countryCode}
            label={t("pages.customerUser.createOrEdit.country")}
            onChange={(countryCode) =>
              setCustomerUserToEdit({
                ...customerUserToEdit,
                contact: {
                  ...customerUserToEdit.contact,
                  countryCode,
                },
              })
            }
            options={generateCountryCodeOptions()}
          />
          <Input
            type="text"
            value={customerUserToEdit.contact.city}
            onChange={(city) =>
              setCustomerUserToEdit({
                ...customerUserToEdit,
                contact: { ...customerUserToEdit.contact, city },
              })
            }
            label={t("pages.customerUser.createOrEdit.city")}
          />
          <Input
            type="text"
            value={customerUserToEdit.contact.zip}
            onChange={(zip) =>
              setCustomerUserToEdit({
                ...customerUserToEdit,
                contact: { ...customerUserToEdit.contact, zip },
              })
            }
            label={t("pages.customerUser.createOrEdit.zip")}
          />
        </div>
      </Box>
    </form>
  );
};
