import { Box, Table, TableRow, TopBar } from "@sam/components";
import { useTranslation } from "react-i18next";
import { updateSchambeckUserTableConfig, useData } from "shared";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { convertCompaniesToTableRows } from "../../utils/company/company.utils";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import { useUser } from "../../components/UserContext";

export const CompanyOverview: React.FC = () => {
  const { axios, updateContextUser, user } = useUser();
  const { data: loadedCompanies } = useData("COMPANY_ALL", {
    config: { fallbackData: [] },
  });
  const { t } = useTranslation();
  const navigate = useNavigate();

  //TableRows to display all companies
  const rows: TableRow[] = useMemo(
    () => convertCompaniesToTableRows(loadedCompanies, navigate),
    [loadedCompanies, navigate]
  );
  return (
    <>
      <TopBar title={t("pages.company.overview.topBarHeadline")}>
        <PlusIcon
          title={t("general.icons.add")}
          onClick={() => navigate("/company/edit")}
        />
      </TopBar>
      <Box>
        <Table
          tableConfig={user.tableConfig?.get(TableKey.COMPANY_OVERVIEW)}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.COMPANY_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          header={
            t("pages.company.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
          rows={rows}
        />
      </Box>
    </>
  );
};
