import { Box, Button, Input, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getMultipleProtocolPdfs,
  Period,
  updateSchambeckUserTableConfig,
  useData,
} from "shared";
import { useUser } from "../../components/UserContext";
import { downloadFile } from "../../utils/files/Files.utils";
import { convertProjectsIntoTableEntriesForProtocols } from "../../utils/project/Project.utils";

export const ProtocolOverview: React.FC = () => {
  const { axios, user, updateContextUser } = useUser();
  const { t } = useTranslation();

  const [selectedPeriod, setSelectedPeriod] = useState<Period>();
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");

  //Hook to set the start and endDate on the first render cycle
  useEffect(() => {
    const startDate: Date = new Date();
    startDate.setMonth(
      new Date().getMonth() < 11 ? startDate.getMonth() - 1 : 0
    );
    const endDate: Date = new Date();
    setSelectedPeriod({ startDate, endDate });
  }, []);

  const { data: loadedProjects } = useData("PROJECT_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerLocations } = useData("CUSTOMER_LOCATION_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerUsers } = useData("CUSTOMER_USERS_ALL", {
    config: { fallbackData: [] },
  });

  const { data: loadedCustomers } = useData("CUSTOMER_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  const { data: loadedOrders } = useData("ORDER_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * Holds rows with all projects
   */
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertProjectsIntoTableEntriesForProtocols(
        loadedProjects,
        loadedCustomers,
        loadedCustomerLocations,
        loadedCustomerUsers,
        loadedOrders,
        setSelectedProjects,
        selectedProjects
      ),
    [
      loadedProjects,
      loadedCustomers,
      loadedCustomerLocations,
      loadedCustomerUsers,
      loadedOrders,
      selectedProjects,
    ]
  );

  return (
    <>
      <TopBar
        title={t("pages.protocolOverview.topBarHeadline")}
        onSearch={setFilterValue}
      />
      <Box>
        <div className="three-columns">
          <Input
            type="date"
            value={selectedPeriod?.startDate}
            label={t("pages.protocolOverview.rangeStart")}
            onChangeDate={(startDate) =>
              startDate &&
              selectedPeriod &&
              setSelectedPeriod({ ...selectedPeriod, startDate })
            }
          />
          <Input
            type="date"
            value={selectedPeriod?.endDate}
            label={t("pages.protocolOverview.rangeEnd")}
            onChangeDate={(endDate) =>
              endDate &&
              selectedPeriod &&
              setSelectedPeriod({ ...selectedPeriod, endDate })
            }
          />
          <Button
            value={t("general.buttons.download")}
            disabled={!selectedPeriod}
            onClick={() =>
              selectedPeriod &&
              getMultipleProtocolPdfs(
                axios,
                selectedProjects,
                selectedPeriod
              ).then((file) => {
                if (file)
                  downloadFile(file, t("pages.protocolOverview.fileName"));
              })
            }
          />
        </div>
      </Box>
      <Box>
        <Table
          tableConfig={user.tableConfig?.get(TableKey.PROTOCOL_OVERVIEW)}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.PROTOCOL_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.protocolOverview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
