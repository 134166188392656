import { Language } from "../Language/Language.types";
import { Contact } from "../user/User.types";

/**
 * Class that is used for offices and servicepoints of Schambeck
 */
export interface Office {
  id: string;
  createdBy: string;
  createDate: Date;
  lastUpdated?: Date;
  updatedBy?: string;
  disabled: boolean;
  name: string;
  contact: Contact;
  officeType: OfficeType;
  numberIdentifier: number;
  officeId?: string;
  companyId: string;
  generalMailConfiguration: SmtpConfiguration;
  accountingMailConfiguration: SmtpConfiguration;
  additionalInfos: Map<AdditionalInfoType, string>;
  receiptSignature: string;
  hrRoleId: string;
  mailSignature: string;
  changeMailings: string[];
}

/**
 * Interface that holds the necessary configuration for a oficces mails
 */
export interface SmtpConfiguration {
  id: string;
  createDate?: Date;
  createdBy?: string;
  lastUpdated?: Date;
  updatedBy?: string;
  name: string;
  description: string;
  sendingAddress: string;
  sendingName: string;
  smtpServer: string;
  smtpPassword: string;
  smtpPort: string;
  tlsEnabled: boolean;
}
/**
 * enum to differenciate between a full office or a service point
 */
export enum OfficeType {
  MAIN_OFFICE = "MAIN_OFFICE",
  SERVICE_POINT = "SERVICE_POINT",
}

/**
 * Class for the different companys inside the Schambeck Group (the
 * so called "Gesellschaft")
 */
export interface Company {
  id: string;
  createDate: Date;
  createdBy: string;
  lastUpdate?: Date;
  updatedBy?: string;
  name: string;
  numberIdentifier: number;
  disabled: boolean;
  taxRate: number;
  wkz: string;
  receiptLanguage: Language;
  ceoName: string;
  location: string;
  courtLocation: string;
  /**
   * Time of day in minutes
   */
  nightBonusStart: number;
  /**
   * Time of day in minutes
   */
  nightBonusEnd: number;
  agbPath: Map<Language, string>;
}

/**
 * enum for different types of texts that are displayed on documents
 */
export enum AdditionalInfoType {
  OFFER_TOP = "OFFER_TOP",
  OFFER_BOTTOM = "OFFER_BOTTOM",
}
