import {
  Agenda,
  Box,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Option,
  Table,
  TableRow,
  TextArea,
  TopBar,
} from "@sam/components";
import { AgendaEntryGroup } from "@sam/components/src/Agenda/Agenda.types";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Bike,
  Car,
  ContractInformation,
  DayOfWeek,
  DocumentBucket,
  DriverLicenseType,
  EducationQualification,
  EmploymentType,
  FamilyState,
  Gender,
  Language,
  Position,
  RequiredState,
  SchambeckUser,
  Schedule,
  ScheduleType,
  TaxClass,
  WorkQualification,
  WorkScheduleDistribution,
  createSchambeckUser,
  deleteSchedule,
  generateCountryCodeOptions,
  generateDropdownOptions,
  generateNewSchedule,
  generateNotification,
  updateBike,
  updateCar,
  updateSchambeckUser,
  updateSchedule,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { Right } from "shared/src/userRole/UserRole.types";
import { ReactComponent as TimeIcon } from "../../assets/time.svg";
import { FileOverview } from "../../components/files/FileOverview";
import { NotAllowed } from "../../components/NotAllowed";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { ScheduleEdit } from "../../components/schedule/ScheduleEdit";
import { useUser } from "../../components/UserContext";
import WorkScheduleEdit from "../../components/userEdit/WorkScheduleEdit";
import {
  generateEmptyAppointmentDetails,
  generateEmptySchedule,
} from "../../utils/project/Project.utils";
import {
  generateEmptySchambeckUser,
  generateUserAgendaScheduleEntries,
  generateWorkTimeSchedulesForUser,
  getSubordinatesForUser,
  isUserAllowedToDo,
  updatePropationEnd,
} from "../../utils/user/User.utils";

const UserCreateOrEditPage: React.FC = () => {
  const { user, axios, updateFootnoteConfig } = useUser();
  const location = useLocation<{ user?: SchambeckUser }>();
  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  const [editUser, setEditUser] = useState<SchambeckUser>(
    location.state?.user || generateEmptySchambeckUser(user.id)
  );
  const [carToUpdate, setCarToUpdate] = useState<Car>();
  const [selectedWorkingDays, setSelectedWorkingDays] = useState<DayOfWeek[]>(
    []
  );
  const [selectedQualification, setSelectedQualification] =
    useState<string>("");
  const [createdPreciseEntries, setPreciseEntries] = useState<
    Map<DayOfWeek, number>
  >(new Map<DayOfWeek, number>());
  const [bikeToEdit, setBikeToEdit] = useState<Bike>();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { data: offices } = useData("OFFICES_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: users } = useData("SCHAMBECK_USERS_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: roles } = useData("ROLES_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: customers } = useData("CUSTOMER_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: cars } = useData("CARS_ALL_AVAILABLE_USER", {
    params: { carId: user.contractInformation.carId },
    config: { fallbackData: [] },
  });

  const { data: bikes } = useData("BIKES_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedPdlProviders } = useData("PDL_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedQualifications } = useData("QUALIFICATIONS_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: allUserFunctions } = useData("USER_FUNCTION_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allProject } = useData("PROJECT_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allAbsenceReasons } = useData("ABSENCE_REASONS_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: allUserSchedules, mutate: updateUserSchedules } = useData(
    "SCHEDULE_USER_ALL",
    {
      config: { fallbackData: [] },
      params: { referenceId: editUser.id },
    }
  );

  const { data: loadedHolidays } = useData("HOLIDAYS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedLanguages } = useData("USER_LANGUAGE_ALL", {
    config: { fallbackData: [] },
  });

  const [scheduleToEdit, setScheduleToEdit] = useState<Schedule>();

  /**
   * Dropdown options for userLanguages
   */
  const userLanguageOptions: Option[] = useMemo(
    (): Option[] => generateDropdownOptions(loadedLanguages, "name", "id"),
    [loadedLanguages]
  );

  /**
   * Hook to update the footNote containg the create and update data
   */
  useEffect(() => {
    updateFootnoteConfig(editUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editUser.createDate, updateFootnoteConfig]);

  /**
   * Hook to get options for the possible userFunctions
   */
  const functionOptions: Option[] = useMemo(
    (): Option[] =>
      allUserFunctions.map((userFunction) => ({
        label: userFunction.names.get(Language.DE),
        value: userFunction.id,
      })),
    [allUserFunctions]
  );

  // TableRows to display the time in hours, that the user worked for different customers
  const customerTime: TableRow[] = useMemo((): TableRow[] => {
    const rows: TableRow[] = [];
    editUser.customerTimes.forEach((value, customerId) =>
      rows.push({
        id: customerId,
        content: [
          customers.find((customer) => customer.id === customerId)?.name ||
            t("pages.user.create.noCustomer"),
          (value / 60).toFixed(1),
        ],
      })
    );
    return rows;
  }, [editUser.customerTimes, customers, t]);

  // initialize the qualification filter with the first value
  useEffect(() => {
    if (selectedQualification || allUserFunctions.length === 0) return;
    setSelectedQualification(allUserFunctions[0].id);
  }, [allUserFunctions, selectedQualification]);

  /**
   * Qualifications to be displayed based on the active filter
   */
  const qualificationsToDisplay: WorkQualification[] = useMemo(
    (): WorkQualification[] =>
      loadedQualifications.filter((quali) =>
        quali.userFunctionIds.includes(selectedQualification)
      ),
    [loadedQualifications, selectedQualification]
  );

  /**
   * Holds the entries needed to display the agenda
   */
  const agendaEntries: AgendaEntryGroup[] = useMemo((): AgendaEntryGroup[] => {
    if (!allUserSchedules) return [];
    return generateUserAgendaScheduleEntries(
      allUserSchedules,
      editUser.id,
      allProject,
      allAbsenceReasons
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProject, editUser, allUserSchedules, loadedHolidays]);

  //UseEffect to set selected working Days based on the users contractInformation
  useEffect(() => {
    setSelectedWorkingDays(
      editUser.contractInformation.workSchedules
        .filter((scheduleDay) => scheduleDay.workingHours !== 0)
        .map((schedule) => schedule.dayOfWeek)
    );
  }, [editUser]);
  /**
   * Helper method to convert roleOptions into an array of options
   * @returns array of options with the label as name and id as value
   */
  const generateRoleOptions = (): Option[] =>
    generateDropdownOptions(roles, "name", "id");

  /**
   * Helper method to generate dropdown options from cars
   * @returns Array of options
   */
  const generateCarOptions = useCallback((): Option[] => {
    const options: Option[] = cars.map((filteredCar) => {
      return {
        label: `${filteredCar.registrationPlate}, ${filteredCar.brand} ${filteredCar.model}`,
        value: filteredCar.id,
      };
    });

    return options;
  }, [cars]);

  const generateBikeOptions = (): Option[] =>
    generateDropdownOptions(bikes, "description", "id");

  /**
   * Helper method to handle the change of a car
   * @param carId of the selected Car
   * @returns void
   */
  const handleChangeCar = (carId: string): void => {
    setEditUser({
      ...editUser,
      contractInformation: { ...editUser.contractInformation, carId: carId },
    });
    const selectedCar: Car | undefined = cars.find((car) => car.id === carId);
    if (!selectedCar) return;
    setCarToUpdate({ ...selectedCar, userId: editUser.id });
  };
  /**
   * Helper method to save or edit user
   * updates or creates user depending on isEdit
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = async (redirect: boolean): Promise<void> => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;

    const userToSave: SchambeckUser = generateWorkTimeSchedulesForUser(
      editUser,
      selectedWorkingDays,
      createdPreciseEntries
    );
    if (carToUpdate) await updateCar(axios, carToUpdate);
    if (bikeToEdit) await updateBike(axios, bikeToEdit);
    if (
      userToSave.contractInformation.workScheduleDistribution ===
        WorkScheduleDistribution.DAILY &&
      userToSave.contractInformation.workSchedules.length === 0
    ) {
      generateNotification({
        value: t("general.notification.error.worktimeDistribution"),
        type: NotificationType.WARNING,
      });
      return;
    }
    userToSave.id
      ? updateSchambeckUser(userToSave, axios).then((savedUser) => {
          if (savedUser && redirect) navigate(-1);
          else if (savedUser) {
            setEditUser({ ...savedUser, password: "" });
            generateNotification({
              value: t("general.notification.success.saveSuccessfull"),
              type: NotificationType.SUCCESS,
            });
          }
        })
      : createSchambeckUser(userToSave, axios).then((savedUser) => {
          if (savedUser && redirect) {
            navigate(-1);
          } else if (savedUser) {
            setEditUser({ ...savedUser, password: "" });
            generateNotification({
              value: t("general.notification.success.saveSuccessfull"),
              type: NotificationType.SUCCESS,
            });
          }
        });
  };

  /**
   * Helper to create a new schedule entry on the backend and reset the local state
   */
  const handleScheduleCreate = (schedule: Schedule): void => {
    if (!schedule.id)
      generateNewSchedule(schedule, axios).then((schedule) => {
        if (!schedule) return;
        updateUserSchedules([schedule]);
        setScheduleToEdit(undefined);
      });
    else {
      updateSchedule(axios, schedule).then((schedule) => {
        if (!schedule) return;
        updateUserSchedules([schedule]);
        setScheduleToEdit(undefined);
      });
    }
  };
  /**
   * Helper method to delete a schedule
   */
  const handleDeleteSchedule = (id: string): void => {
    const schedule: Schedule | undefined = allUserSchedules.find((schedule) =>
      id.includes(schedule.id)
    );
    if (schedule && schedule.type === ScheduleType.USER_ABSENCE)
      deleteSchedule(axios, schedule.id).then((success) => {
        success &&
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.deleteSchedule"),
          });

        setScheduleToEdit(undefined);
        updateUserSchedules();
      });
    else {
      setScheduleToEdit(undefined);
      generateNotification({
        type: NotificationType.INFO,
        value: t("general.notification.error.selectedProjectPlanning"),
      });
    }
  };

  /**
   * Helper method to add or remove a qualification
   * @param qualificationId id of the qualification to remove or add
   */
  const handleQualificationCheck = (qualificationId: string): void => {
    if (editUser.workQualifications?.includes(qualificationId))
      setEditUser({
        ...editUser,
        workQualifications: editUser.workQualifications.filter(
          (quali) => quali !== qualificationId
        ),
      });
    else
      setEditUser({
        ...editUser,
        workQualifications: [...editUser.workQualifications, qualificationId],
      });
  };

  /**
   * Helper method to update a Schedule
   * @param id of the schedule to update
   */
  const handleEditSchedule = (id: string): void => {
    const schedule: Schedule | undefined = allUserSchedules.find((schedule) =>
      id.includes(schedule.id)
    );
    if (schedule?.type === ScheduleType.USER_ABSENCE)
      setScheduleToEdit(schedule);
    else {
      setScheduleToEdit(undefined);
      generateNotification({
        type: NotificationType.INFO,
        value: t("general.notification.error.selectedProjectPlanning"),
      });
    }
  };

  /**
   * Helper method to update the contractInformation
   * @param key of cintractInfo to update
   * @param newValue new value for the key
   */
  const updateContract = (
    key: keyof ContractInformation,
    newValue: Date | string | number | boolean | undefined
  ): void => {
    let contractInformation: ContractInformation = {
      ...editUser.contractInformation,
      [key]: newValue,
    };
    // in case the entry date gets updated update the propation end as well
    if (key === "entryDate" && newValue) {
      contractInformation = {
        ...contractInformation,
        propationEndDate: updatePropationEnd(newValue as Date),
      };
    }
    setEditUser((old) => ({ ...old, contractInformation }));
  };

  return isUserAllowedToDo(user.right, Right.USER_CREATE) ? (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={
          editUser.firstName + editUser.lastName === ""
            ? t("pages.user.create.newUser")
            : `${editUser.firstName} ${editUser.lastName}`
        }
      >
        <div className="user-create__topbar-content hide-on-small">
          <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
          <Button
            value={<TimeIcon />}
            onClick={() =>
              navigate("/timekeeping", { state: { userId: editUser.id } })
            }
          />
          <Dropdown
            options={[
              {
                label: t("pages.user.create.mainInformation"),
                value: "main-information",
              },
              {
                label: t("pages.user.create.sami"),
                value: "sami-information",
              },
              {
                label: t("pages.user.create.legend.contactInformation"),
                value: "contact-business",
              },
              {
                label: t("pages.user.create.legend.contactInformationPrivate"),
                value: "contact-information",
              },
              {
                label: t("pages.user.create.legend.privateInformation"),
                value: "private-information",
              },
              {
                label: t("pages.user.create.secretInformation"),
                value: "secret-information",
              },
              {
                label: t("pages.user.create.contractInformation"),
                value: "contract-information",
              },
              {
                label: t("pages.user.create.qualifications"),
                value: "qualifications",
              },
              {
                label: t("pages.user.create.documents"),
                value: "documents",
              },
              {
                label: t("pages.user.create.schedules"),
                value: "schedules",
              },
              {
                label: t("pages.user.create.userTimes"),
                value: "user-times",
              },
            ]}
            onChange={(value) => {
              const elem = document.getElementById(value);
              elem?.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
            selectedOption={"main-information"}
          />
        </div>
      </TopBar>
      <Box
        id="main-information"
        title={t("pages.user.create.legend.mainInformation")}
      >
        <fieldset className="three-columns">
          <Dropdown
            label={t("pages.user.create.gender.label")}
            selectedOption={editUser.gender}
            options={Object.values(Gender).map((gender) => ({
              label: t(`pages.user.create.gender.${gender}`),
              value: gender,
            }))}
            onChange={(gender) =>
              setEditUser({ ...editUser, gender: gender as Gender })
            }
          />
          <Input
            type="text"
            value={editUser.title}
            onChange={(title) => setEditUser({ ...editUser, title })}
            label={t("pages.user.create.title")}
          />
          <Input
            required
            type="text"
            value={editUser.firstName}
            onChange={(firstName) => setEditUser({ ...editUser, firstName })}
            label={t("pages.user.create.firstName")}
          />
          <Input
            required
            type="text"
            value={editUser.lastName}
            onChange={(lastName) => setEditUser({ ...editUser, lastName })}
            label={t("pages.user.create.lastName")}
          />
          <TextArea
            value={editUser.annotation || ""}
            onChange={(annotation) => setEditUser({ ...editUser, annotation })}
            label={t("pages.user.create.annotation")}
          />
          <Input
            type="text"
            value={editUser.personalNumber}
            onChange={(personalNumber) =>
              setEditUser({ ...editUser, personalNumber })
            }
            label={t("pages.user.create.personalNumber")}
          />
          <Checkbox
            label={t("pages.user.create.active")}
            isChecked={!editUser.disabled}
            onCheck={() =>
              setEditUser({ ...editUser, disabled: !editUser.disabled })
            }
          />
          <Dropdown
            label={t("pages.user.create.office")}
            onChange={(officeId) =>
              setEditUser({
                ...editUser,
                contractInformation: {
                  ...editUser.contractInformation,
                  officeId,
                },
              })
            }
            selectedOption={editUser.contractInformation.officeId}
            options={offices.map((office) => ({
              label: office.name,
              value: office.id,
            }))}
          />
          <Dropdown
            label={t("pages.user.create.currentOffice")}
            onChange={(currentOfficeId) =>
              setEditUser({
                ...editUser,
                contractInformation: {
                  ...editUser.contractInformation,
                  currentOfficeId,
                },
              })
            }
            selectedOption={editUser.contractInformation.currentOfficeId}
            options={offices.map((office) => ({
              label: office.name,
              value: office.id,
            }))}
          />
          <Dropdown
            label={t("pages.user.create.supervisor")}
            selectedOption={editUser.supervisorId}
            options={generateDropdownOptions(allUserFunctions, "title", "id")}
            onChange={(supervisorId) =>
              setEditUser({ ...editUser, supervisorId: supervisorId })
            }
          />
          <Dropdown
            label={t("pages.user.create.deputy")}
            selectedOption={editUser.deputyId}
            options={generateDropdownOptions(allUserFunctions, "title", "id")}
            onChange={(deputyId) => setEditUser({ ...editUser, deputyId })}
          />
          {getSubordinatesForUser(users, editUser.id).length > 0 && (
            <div>
              <p>{t("pages.user.create.supervisorOf")}</p>
              <ul>
                {getSubordinatesForUser(users, editUser.id).map(
                  (subordinateUser) => (
                    <li
                      key={`subordinate-user-${subordinateUser.id}`}
                    >{`${subordinateUser.firstName} ${subordinateUser.lastName}`}</li>
                  )
                )}
              </ul>
            </div>
          )}
        </fieldset>
      </Box>
      <Box title={t("pages.user.create.sami")} id="sami-information">
        <div className="three-columns">
          <Input
            value={editUser.username}
            onChange={(username) => setEditUser({ ...editUser, username })}
            type="text"
            label={t("pages.user.create.username")}
          />
          <Input
            value={editUser.password}
            onChange={(password) => setEditUser({ ...editUser, password })}
            type="text"
            label={t("pages.user.create.password")}
          />
          <Dropdown
            multi
            onChangeMultiple={(availableOffices) =>
              setEditUser((old) => ({ ...old, availableOffices }))
            }
            options={generateDropdownOptions(offices, "name", "id")}
            label={t("pages.user.create.availableOffices")}
            selectedMultiOptions={editUser.availableOffices}
          />
          <Dropdown
            label={t("pages.user.create.userRole")}
            options={generateRoleOptions()}
            onChange={(newRole) =>
              setEditUser({
                ...editUser,
                right: {
                  ...editUser.right,
                  role: roles.filter((role) => role.id === newRole)[0],
                },
              })
            }
            selectedOption={editUser?.right?.role?.id || ""}
          />
          <div className="user-create__password-info__wrapper">
            <p className="user-create__password-info__wrapper__title">
              {t("pages.user.create.passwordPolicy.title")}
            </p>
            <ul className="user-create__password-info__wrapper__list">
              <li>{t("pages.user.create.passwordPolicy.characters")}</li>
              <li>{t("pages.user.create.passwordPolicy.specialCharacters")}</li>
              <li>{t("pages.user.create.passwordPolicy.letterCasing")}</li>
              <li>{t("pages.user.create.passwordPolicy.name")}</li>
              <li>{t("pages.user.create.passwordPolicy.schambeck")}</li>
              <li>{t("pages.user.create.passwordPolicy.lastTwo")}</li>
            </ul>
          </div>
        </div>
      </Box>
      <Box
        id="contact-business"
        title={t("pages.user.create.legend.contactInformation")}
      >
        <fieldset className="three-columns">
          <Input
            type="text"
            value={editUser.contact.phone}
            onChange={(phone) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, phone },
              })
            }
            label={t("pages.user.create.phone")}
          />
          <Input
            type="text"
            value={editUser.contact.mobile}
            onChange={(mobile) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, mobile },
              })
            }
            label={t("pages.user.create.mobile")}
          />
          <Input
            type="text"
            value={editUser.contact.fax || ""}
            onChange={(fax) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, fax },
              })
            }
            label={t("pages.user.create.fax")}
          />
          <Input
            type="email"
            value={editUser.contact.mail}
            onChange={(mail) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, mail },
              })
            }
            label={t("pages.user.create.mail")}
          />
          <Input
            type="text"
            value={editUser.contact.street}
            onChange={(street) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, street },
              })
            }
            label={t("pages.user.create.street")}
          />
          <Input
            type="text"
            value={editUser.contact.streetNumber}
            onChange={(streetNumber) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, streetNumber },
              })
            }
            label={t("pages.user.create.streetNumber")}
          />
          <Dropdown
            selectedOption={editUser.contact.countryCode}
            label={t("pages.user.create.country")}
            onChange={(countryCode) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, countryCode },
              })
            }
            options={generateCountryCodeOptions()}
          />
          <Input
            type="text"
            value={editUser.contact.zip}
            onChange={(zip) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, zip },
              })
            }
            label={t("pages.user.create.zip")}
          />
          <Input
            type="text"
            value={editUser.contact.city}
            onChange={(city) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, city },
              })
            }
            label={t("pages.user.create.city")}
          />
        </fieldset>
      </Box>
      <Box
        id="contact-information"
        title={t("pages.user.create.legend.contactInformationPrivate")}
      >
        <fieldset className="three-columns">
          <Input
            type="text"
            value={editUser.contact.phonePrivate}
            onChange={(phonePrivate) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, phonePrivate },
              })
            }
            label={t("pages.user.create.phone")}
          />
          <Input
            type="text"
            value={editUser.contact.mobilePrivate}
            onChange={(mobilePrivate) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, mobilePrivate },
              })
            }
            label={t("pages.user.create.mobile")}
          />
          <Input
            type="email"
            value={editUser.contact.mailPrivate}
            onChange={(mailPrivate) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, mailPrivate },
              })
            }
            label={t("pages.user.create.mail")}
          />
          <Input
            type="text"
            value={editUser.contact.streetPrivate}
            onChange={(streetPrivate) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, streetPrivate },
              })
            }
            label={t("pages.user.create.street")}
          />
          <Input
            type="text"
            value={editUser.contact.streetNumberPrivate}
            onChange={(streetNumberPrivate) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, streetNumberPrivate },
              })
            }
            label={t("pages.user.create.streetNumber")}
          />
          <Dropdown
            selectedOption={editUser.contact.countryCodePrivate}
            label={t("pages.user.create.country")}
            onChange={(countryCodePrivate) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, countryCodePrivate },
              })
            }
            options={generateCountryCodeOptions()}
          />
          <Input
            type="text"
            value={editUser.contact.zipPrivate}
            onChange={(zipPrivate) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, zipPrivate },
              })
            }
            label={t("pages.user.create.zip")}
          />
          <Input
            type="text"
            value={editUser.contact.cityPrivate}
            onChange={(cityPrivate) =>
              setEditUser({
                ...editUser,
                contact: { ...editUser.contact, cityPrivate },
              })
            }
            label={t("pages.user.create.city")}
          />
        </fieldset>
      </Box>
      <Box
        id="private-information"
        title={t("pages.user.create.legend.privateInformation")}
      >
        <fieldset className="three-columns">
          <Input
            type="date"
            required
            value={editUser.privateInformation?.birthDate || new Date()}
            onChangeDate={(birthDate) => {
              if (birthDate)
                setEditUser({
                  ...editUser,
                  privateInformation: {
                    ...editUser.privateInformation,
                    birthDate,
                  },
                });
            }}
            label={t("pages.user.create.birthDate")}
          />
          <Input
            type="text"
            value={editUser.privateInformation.birthCity}
            onChange={(birthCity) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  birthCity,
                },
              })
            }
            label={t("pages.user.create.birthCity")}
          />
          <Input
            type="text"
            value={editUser.privateInformation.passportNumber}
            onChange={(passportNumber) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  passportNumber,
                },
              })
            }
            label={t("pages.user.create.passportNumber")}
          />
          <Input
            type="date"
            value={
              editUser.privateInformation.residencePermitEndDate || new Date()
            }
            onChangeDate={(residencePermitEndDate) => {
              if (residencePermitEndDate)
                setEditUser({
                  ...editUser,
                  privateInformation: {
                    ...editUser.privateInformation,
                    residencePermitEndDate,
                  },
                });
            }}
            label={t("pages.user.create.residencePermitStartDate")}
          />
          <Input
            type="date"
            value={editUser.privateInformation.workPermitEndDate || new Date()}
            onChangeDate={(workPermitEndDate) => {
              if (workPermitEndDate)
                setEditUser({
                  ...editUser,
                  privateInformation: {
                    ...editUser.privateInformation,
                    workPermitEndDate,
                  },
                });
            }}
            label={t("pages.user.create.workPermitEndDate")}
          />
          <Dropdown
            options={Object.values(EducationQualification).map((type) => ({
              label: t(`general.educationQualifications.${type}`),
              value: type,
            }))}
            selectedOption={editUser.privateInformation.educationQualification}
            label={t("pages.user.create.educationQualification")}
            onChange={(type) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  educationQualification: type as EducationQualification,
                },
              })
            }
          />
          <Dropdown
            options={Object.values(DriverLicenseType).map((type) => ({
              label: t(`general.driverLicenseTypes.${type}`),
              value: type,
            }))}
            selectedMultiOptions={
              editUser.privateInformation.driverLicenseTypes
            }
            multi
            label={t("pages.user.create.driverLicenseType")}
            onChangeMultiple={(types: DriverLicenseType[]) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  driverLicenseTypes: types,
                },
              })
            }
          />
          <Dropdown
            options={Object.values(RequiredState).map((req) => ({
              label: t(`general.requiredStates.${req}`),
              value: req,
            }))}
            selectedOption={editUser.privateInformation.driverLicenseCopyState}
            label={t("pages.user.create.driverLicenseCopyState")}
            onChange={(state) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  driverLicenseCopyState: state as RequiredState,
                },
              })
            }
          />
          <Input
            label={t(`pages.user.create.contract.yearlyStackerIntro`)}
            value={editUser.contractInformation.yearlyStackerIntro}
            onChangeDate={(newValue) =>
              updateContract("yearlyStackerIntro", newValue)
            }
            type="date"
          />
          <Input
            label={t(`pages.user.create.contract.eyeTestDate`)}
            value={editUser.contractInformation.eyeTestDate}
            onChangeDate={(newValue) => updateContract("eyeTestDate", newValue)}
            type="date"
          />
          <Input
            label={t(`pages.user.create.contract.eyeTestDeadline`)}
            value={editUser.contractInformation.eyeTestDeadline}
            onChangeNumber={(newValue) =>
              updateContract("eyeTestDeadline", newValue)
            }
            type="number"
            minValue={0}
            maxValue={36}
          />
        </fieldset>
      </Box>
      <Box
        title={t("pages.user.create.secretInformation")}
        id="secret-information"
      >
        <div className="three-columns">
          <Input
            label={t(`pages.user.create.contract.wage`)}
            value={editUser.contractInformation.wage}
            onChange={(newValue) => updateContract("wage", newValue)}
            type="text"
          />
          <Input
            label={t(`pages.user.create.contract.salary`)}
            value={editUser.contractInformation.salary}
            onChangeNumber={(newValue) => updateContract("salary", newValue)}
            type="number"
          />
          <Input
            type="text"
            value={editUser.privateInformation.taxId}
            onChange={(taxId) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  taxId,
                },
              })
            }
            label={t("pages.user.create.taxId")}
          />
          <Input
            type="text"
            value={editUser.privateInformation.healthInsurance}
            onChange={(healthInsurance) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  healthInsurance,
                },
              })
            }
            label={t("pages.user.create.healthInsurance")}
          />
          <Input
            type="text"
            value={editUser.privateInformation.religion}
            onChange={(religion) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  religion,
                },
              })
            }
            label={t("pages.user.create.religion")}
          />
          <Checkbox
            isChecked={editUser.privateInformation.children}
            onCheck={(children) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  children,
                },
              })
            }
            label={t("pages.user.create.children")}
          />
          <Dropdown
            options={Object.values(TaxClass).map((tax) => ({
              label: t(`pages.user.create.taxClasses.${tax}`),
              value: tax,
            }))}
            selectedOption={editUser.privateInformation.taxClass}
            label={t("pages.user.create.taxClass")}
            onChange={(tax) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  taxClass: tax as TaxClass,
                },
              })
            }
          />
          <Dropdown
            options={Object.values(FamilyState).map((fam) => ({
              label: t(`general.familyStates.${fam}`),
              value: fam,
            }))}
            selectedOption={editUser.privateInformation.familyState}
            label={t("pages.user.create.familyState")}
            onChange={(state) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  familyState: state as FamilyState,
                },
              })
            }
          />
          <Input
            type="text"
            value={editUser.privateInformation.bankAccount.owner}
            onChange={(owner) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  bankAccount: {
                    ...editUser.privateInformation.bankAccount,
                    owner,
                  },
                },
              })
            }
            label={t("pages.user.create.bankAccount.owner")}
          />
          <Input
            type="text"
            value={editUser.privateInformation.bankAccount.iban}
            onChange={(iban) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  bankAccount: {
                    ...editUser.privateInformation.bankAccount,
                    iban,
                  },
                },
              })
            }
            label={t("pages.user.create.bankAccount.iban")}
          />
          <Input
            type="text"
            value={editUser.privateInformation.bankAccount.bic}
            onChange={(bic) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  bankAccount: {
                    ...editUser.privateInformation.bankAccount,
                    bic,
                  },
                },
              })
            }
            label={t("pages.user.create.bankAccount.bic")}
          />
          <Input
            type="text"
            value={editUser.privateInformation.disabledLevel}
            onChange={(disabledLevel) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  disabledLevel,
                },
              })
            }
            label={t("pages.user.create.disabledLevel")}
          />
          <Checkbox
            isChecked={editUser.privateInformation.severlyDisabled}
            onCheck={(severlyDisabled) =>
              setEditUser({
                ...editUser,
                privateInformation: {
                  ...editUser.privateInformation,
                  severlyDisabled,
                },
              })
            }
            label={t("pages.user.create.severlyDisabled")}
          />
        </div>
      </Box>
      <Box
        id="contract-information"
        title={t("pages.user.create.legend.contractInformation")}
      >
        <fieldset>
          <p className="user-create__contract-category">
            {t("pages.user.create.legend.jobInformation")}
          </p>
          <div className="three-columns">
            <Dropdown
              options={functionOptions}
              selectedOption={editUser.userFunctionId}
              label={t("pages.user.create.userFunction")}
              onChange={(userFunctionId) =>
                setEditUser({ ...editUser, userFunctionId })
              }
            />
            <Dropdown
              label={t(`pages.user.create.contract.employmentTypeTitle`)}
              onChange={(newValue) =>
                updateContract("employmentType", newValue)
              }
              selectedOption={editUser.contractInformation.employmentType}
              options={Object.keys(EmploymentType).map((type) => ({
                label: t(`pages.user.create.contract.employmentType.${type}`),
                value: type,
              }))}
            />
            {editUser.contractInformation.employmentType ===
              EmploymentType.PDL && (
              <Dropdown
                required={
                  editUser.contractInformation.employmentType ===
                  EmploymentType.PDL
                }
                label={t(`pages.user.create.contract.pdl`)}
                options={generateDropdownOptions(
                  loadedPdlProviders,
                  "name",
                  "id"
                )}
                selectedOption={editUser.contractInformation.pdlId}
                onChange={(pdlId) =>
                  setEditUser({
                    ...editUser,
                    contractInformation: {
                      ...editUser.contractInformation,
                      pdlId,
                    },
                  })
                }
              />
            )}
            <Dropdown
              label={t(`pages.user.create.contract.position`)}
              onChange={(newValue) => updateContract("position", newValue)}
              selectedOption={editUser.contractInformation.position}
              options={Object.keys(Position).map((pos) => ({
                label: t(`pages.user.create.contract.${pos}`),
                value: pos,
              }))}
            />
            <Input
              label={t(`pages.user.create.contract.entryDate`)}
              value={editUser.contractInformation.entryDate}
              onChangeDate={(newValue) => updateContract("entryDate", newValue)}
              type="date"
            />
            <Input
              label={t(`pages.user.create.contract.leavingDate`)}
              value={editUser.contractInformation.leavingDate}
              onChangeDate={(newValue) =>
                updateContract("leavingDate", newValue)
              }
              type="date"
            />
            <Input
              label={t(`pages.user.create.contract.reentryDate`)}
              value={editUser.contractInformation.reentryDate}
              onChangeDate={(newValue) =>
                updateContract("reentryDate", newValue)
              }
              type="date"
            />
            {editUser.contractInformation.employmentHistory &&
              Array.from(
                editUser.contractInformation.employmentHistory.entries()
              ).map(([key, value]) => (
                <p>
                  {key.toLocaleDateString()}:
                  {t(`general.employmentChange.${value}`)}
                </p>
              ))}
            <Input
              label={t(`pages.user.create.contract.holidayAmount`)}
              value={editUser.contractInformation.holidayAmount}
              onChangeNumber={(newValue) =>
                updateContract("holidayAmount", newValue)
              }
              type="number"
            />
            <Input
              disabled
              value={
                editUser.vacationDaysLeft.get(new Date().getFullYear())
                  ? editUser.vacationDaysLeft.get(new Date().getFullYear())
                  : editUser.contractInformation.holidayAmount
              }
              type="text"
              label={t(`pages.user.create.contract.holidaysLeft`, {
                replace: { YEAR: new Date().getFullYear() },
              })}
            />
          </div>
          <p className="user-create__contract-category">
            {t(`pages.user.create.contract.workTime`)}
          </p>
          <div>
            <WorkScheduleEdit
              updateUser={(contractInformation) =>
                setEditUser((old) => ({ ...old, contractInformation }))
              }
              contractInformation={editUser.contractInformation}
              createdPreciseEntries={createdPreciseEntries}
              selectedDays={selectedWorkingDays}
              setPreciseEntries={setPreciseEntries}
              setSelectedDays={setSelectedWorkingDays}
            />
          </div>
          <p className="user-create__contract-category">
            {t(`pages.user.create.contract.furtherInformation`)}
          </p>
          <div className="three-columns">
            <Input
              label={t(`pages.user.create.contract.propationEndDate`)}
              value={editUser.contractInformation.propationEndDate}
              onChangeDate={(newValue) =>
                updateContract("propationEndDate", newValue)
              }
              type="date"
            />
            <Input
              label={t(`pages.user.create.contract.vwl`)}
              value={editUser.contractInformation.vwl}
              disabled={!editUser.contractInformation.secondaryActivityAllowed}
              onChange={(newValue) => updateContract("vwl", newValue)}
              type="text"
            />
            <div>
              <Checkbox
                label={t(`pages.user.create.contract.secondaryActivityAllowed`)}
                isChecked={
                  editUser.contractInformation.secondaryActivityAllowed
                }
                onCheck={(checked) =>
                  updateContract("secondaryActivityAllowed", checked)
                }
              />
              <Checkbox
                isChecked={
                  editUser.contractInformation.capitalAccumulationAllowed
                }
                label={t(
                  `pages.user.create.contract.capitalAccumulationAllowed`
                )}
                onCheck={(checked) =>
                  updateContract("capitalAccumulationAllowed", checked)
                }
              />
              <Checkbox
                label={t(`pages.user.create.contract.propationFinished`)}
                isChecked={editUser.contractInformation.propationFinished}
                onCheck={(checked) =>
                  updateContract("propationFinished", checked)
                }
              />
            </div>
            <Input
              label={t(`pages.user.create.contract.azk`)}
              value={editUser.contractInformation.azk}
              onChange={(newValue) => updateContract("azk", newValue)}
              type="text"
            />
            <Dropdown
              disabled={!isUserAllowedToDo(user.right, Right.CAR_EDIT)}
              options={generateCarOptions()}
              selectedOption={editUser.contractInformation.carId}
              onChange={handleChangeCar}
              label={t("pages.user.create.car")}
            />
            <Dropdown
              label={t("pages.user.create.bike")}
              selectedOption={editUser.contractInformation.bycicleId}
              options={generateBikeOptions()}
              onChange={(bycicleId) => {
                setEditUser({
                  ...editUser,
                  contractInformation: {
                    ...editUser.contractInformation,
                    bycicleId,
                  },
                });
                setBikeToEdit(
                  bikes.find((bycicle) => bycicle.id === bycicleId)
                );
              }}
            />
          </div>
          <div className="three-columns">
            <TextArea
              value={editUser.contractInformation.description}
              onChange={(newValue) => updateContract("description", newValue)}
              label={t(`pages.user.create.contract.description`)}
            />
          </div>
        </fieldset>
      </Box>
      <Box id="qualifications" title={t("pages.user.create.qualifications")}>
        <fieldset className="three-columns">
          <Dropdown
            options={generateDropdownOptions(allUserFunctions, "title", "id")}
            label={t("pages.user.create.type")}
            selectedOption={selectedQualification}
            onChange={(quali) => setSelectedQualification(quali)}
          />
          <Dropdown
            label={t("pages.user.create.userLanguages")}
            options={userLanguageOptions}
            multi
            onChangeMultiple={(userLanguages) =>
              setEditUser({ ...editUser, userLanguages })
            }
          />
          {qualificationsToDisplay.map((qualification) => (
            <Checkbox
              isChecked={editUser.workQualifications?.includes(
                qualification.id
              )}
              label={qualification.names.get(Language.DE)}
              onCheck={() => handleQualificationCheck(qualification.id)}
            />
          ))}
        </fieldset>
      </Box>
      {editUser.id && (
        <Box title={t("pages.user.create.documents")} id="documents">
          <FileOverview
            bucket={DocumentBucket.USER}
            path={`${editUser.id}/`}
            userId={user.id}
          />
        </Box>
      )}
      <Box title={t("pages.user.create.schedules")} id="schedules">
        <Agenda
          deleteItem={handleDeleteSchedule}
          handleEdit={handleEditSchedule}
          entryGroups={agendaEntries}
          addItem={(item) =>
            setScheduleToEdit(
              generateEmptySchedule({
                startDate: new Date(item.start || new Date()),
                endDate: new Date(item.start || new Date()),
                referenceId: item.group?.toString() || "",
                appointmentDetail: {
                  ...generateEmptyAppointmentDetails(),
                  reasonId: "",
                },
                type: ScheduleType.USER_ABSENCE,
              })
            )
          }
        />
        {scheduleToEdit && (
          <ScheduleEdit
            handleScheduleCreate={handleScheduleCreate}
            handleScheduleDelete={(id) => handleDeleteSchedule(id)}
            schedule={scheduleToEdit}
            updateSchedule={setScheduleToEdit}
          />
        )}
      </Box>
      <Box title={t("pages.user.create.userTimes")} id="user-times">
        <Table
          rows={customerTime}
          header={
            t("pages.user.create.userTimesTableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </form>
  ) : (
    <NotAllowed neccessaryRight={Right.USER_CREATE} />
  );
};
export default UserCreateOrEditPage;
