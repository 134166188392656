import { useKeycloak } from "@react-keycloak/web";
import axios, { AxiosInstance } from "axios";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { SchambeckUser, loadSchambeckUserById } from "shared";
import { updateEnvironment } from "shared/src/environment";
import { handleResponseConversion } from "../utils/axios/axios.utils";

/**
 * The context model which is available in the useUser hook
 */
interface UserContextData {
  user: SchambeckUser;
  axios: AxiosInstance;
  update: (selectedOffices: string[]) => Promise<void>;
  updateContextUser: (user: SchambeckUser) => void;
  footNoteConfig: FootNoteConfig | undefined;
  updateFootnoteConfig: (config: unknown) => void;
}
/**
 * Holds information for the create and update data of an object
 */
interface FootNoteConfig {
  createDate?: Date;
  createdBy?: string;
  updatedBy?: string;
  lastUpdated?: Date;
}
/**
 * Created react context
 */
const UserContext = createContext<UserContextData>(undefined!);

/**
 * A user provider which provides the user context to all children
 *
 * @param fallback The fallback component which is rendered if the user is not logged in
 * @param loading The loading component which is rendered while the user is loading
 * @param children The children which are rendered if the user is logged in
 */
export const UserProvider: React.FC<{
  fallback?: ReactNode;
  loading?: ReactNode;
  children: (user: SchambeckUser) => ReactNode;
}> = ({ fallback, loading, children }) => {
  const { keycloak, initialized } = useKeycloak();
  const [contextState, setContextState] = useState<{
    user: SchambeckUser;
    axios: AxiosInstance;
  }>();

  const [footNoteConfig, setFootNoteConfig] = useState<FootNoteConfig>();

  /**
   * Util method to check any given object for the values of the footnote
   * @param object type unknown to accept any Object, all available data gets set
   */
  const updateFootnoteConfig = (object: unknown): void => {
    const newConfig: FootNoteConfig = {};
    if (typeof object !== "object" || object == null) return;
    if ("createDate" in object && object["createDate"] instanceof Date) {
      newConfig.createDate = object["createDate"];
    }
    if ("lastUpdated" in object && object["lastUpdated"] instanceof Date) {
      newConfig.lastUpdated = object["lastUpdated"];
    }
    if ("createdBy" in object && typeof object["createdBy"] === "string") {
      newConfig.createdBy = object["createdBy"];
    }
    if ("updatedBy" in object && typeof object["updatedBy"] === "string") {
      newConfig.updatedBy = object["updatedBy"];
    }
    setFootNoteConfig(newConfig);
  };

  /**
   * Resets the config of the create and update data on page change
   */
  useEffect(() => {
    setFootNoteConfig(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  /**
   * Function to redefine the axios instance with the current Id Token and fetch the current user
   */
  const update = useCallback(
    async (selectedOffices: string[] = []) => {
      if (!keycloak.token) {
        setContextState(undefined);
        return;
      }

      const databaseId: string = await keycloak
        .loadUserInfo()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((resp: any) => resp.databaseId);

      // --- Axios ---
      // let token = keycloak.token;
      keycloak.onAuthRefreshSuccess = () => {
        const instance = axios.create({
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            "Content-Type": "application/json;charset=UTF-8",
            "X-Selected-Offices": selectedOffices.join(","),
          },
          baseURL: import.meta.env.VITE_BACKEND_URL,
        });
        instance.interceptors.response.use(handleResponseConversion);
        updateContextValues(instance, databaseId, selectedOffices);
      };
      const instance = axios.create({
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
          "Content-Type": "application/json;charset=UTF-8",
          "X-Selected-Offices": selectedOffices.join(","),
        },
        baseURL: import.meta.env.VITE_BACKEND_URL,
      });
      instance.interceptors.response.use(handleResponseConversion);
      // --- User ---
      updateContextValues(instance, databaseId, selectedOffices);
    },
    [keycloak]
  );

  const updateContextValues = (
    instance: AxiosInstance,
    databaseId: string,
    selectedOffices: string[]
  ): void => {
    loadSchambeckUserById(databaseId, instance).then((user) => {
      if (user == null) return;

      // set states
      setContextState(user ? { user, axios: instance } : undefined);
      updateEnvironment(
        user
          ? { user, axios: instance, selectedOffices }
          : { user: undefined, axios: undefined, selectedOffices }
      );
    });
  };
  const updateUser = (user: SchambeckUser): void => {
    if (!contextState?.axios) return;
    // set states
    setContextState({ user, axios: contextState.axios });
    updateEnvironment({ user, axios: contextState.axios });
  };

  /**
   * effect to load the data of the currently logged in user.
   * triggers everytime the auth state change.
   */
  useEffect(() => {
    if (!initialized) return;
    update();
  }, [initialized, keycloak, update]);

  // user & axios is defined therefore logged in
  if (contextState)
    return (
      <UserContext.Provider
        value={{
          user: contextState.user,
          axios: contextState.axios,
          updateContextUser: updateUser,
          update,
          footNoteConfig,
          updateFootnoteConfig,
        }}
      >
        {children(contextState.user)}
      </UserContext.Provider>
    );

  // auth not loading and auth user undefined therefore logged out
  if (initialized && !keycloak.token) {
    localStorage.clear();
    return <>{fallback}</>;
  }

  // anywhere else should be in loading state
  return <>{loading}</>;
};

/**
 * Simple shortcut for `useContext(UserContext)`.
 *
 * @returns {@link UserContext}
 */
export const useUser = () => useContext(UserContext);
