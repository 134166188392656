import { Schedule, ScheduleType } from "shared";
import dayjs from "dayjs";

/**
 * Util method to get a list of unique absent userIds
 * @param schedules to search in
 * @param date to get the amount of absent users
 * @returnsarray of userIds
 */
export const getAbsentUserList = (
  schedules: Schedule[],
  date: dayjs.Dayjs
): string[] => {
  const absentUserIds: string[] = schedules
    .filter((schedule) => {
      return (
        schedule.type === ScheduleType.USER_ABSENCE &&
        (date.isSame(schedule.startDate, "date") ||
          (date.isAfter(schedule.startDate) &&
            date.isBefore(schedule.endDate)) ||
          date.isSame(schedule.endDate, "date"))
      );
    })
    .map((schedule) => schedule.referenceId);
  return [...new Set<string>(absentUserIds)];
};
