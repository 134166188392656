import { Language } from "../Language/Language.types";
import { Task } from "../order/Order.types";

/**
 * Interface for offers with information about the state of the offer and the content
 */
export interface Offer {
  id: string;
  orderId: string;
  createDate: Date;
  createdBy: string;
  lastUpdate: Date;
  updatedBy: string;
  providedBy: OfferProvidedBy;
  language: Language;
  annotation: string;
  plannedAmountWorker: number;
  offeredTasks: OfferedTask[];
  acceptDate?: Date;
  deniedDate?: Date;
  numberRangeNumber?: string;
  customerId: string;
  offerState: OfferState;
  topInfo: boolean;
  bottomInfo: boolean;
  showSum: boolean;
  offerType: OfferType;
  sendDates: Date[];
}

/**
 * Used to seperate customerProject Orders from basic offers
 */
export enum OfferType {
  STANDARD = "STANDARD",
  CUSTOMER_PROJECT = "CUSTOMER_PROJECT",
}

/**
 * enum for offerStates to represent if the order is penig or decided
 */
export enum OfferState {
  OFFER_ACCEPTED = "OFFER_ACCEPTED",
  OFFER_DECLINED = "OFFER_DECLINED",
  OFFER_PENDING = "OFFER_PENDING",
  OFFER_PREP = "OFFER_PREP",
}

/**
 * Interface for offeredTasks that are used i Offers to display a single Position with the task and the amount
 */
export interface OfferedTask {
  id: string;
  index: number;
  task: Task;
  amount: number;
  totalAmount: number;
  annotation: string;
}

/**
 * Interface that contains all neccessary information to create an offer as a pdf document
 */
export interface OfferConfiguration {
  userId: string;
  offerId: string;
  language: Language;
  showSum: boolean;
  offerTop: boolean;
  offerBottom: boolean;
}

/**
 * enum to store how the offer was provided to the company
 */
export enum OfferProvidedBy {
  UNKNOWN = "UNKNOWN",
  CLIENT = "CLIENT",
  WORKPLACE = "WORKPLACE",
  CUSTOMER_PROJECT = "CUSTOMER_PROJECT",
}
