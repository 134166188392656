import {
  Box,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Option,
  Popup,
  Table,
  TableRow,
  TextArea,
  TopBar,
} from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Customer,
  CustomerUser,
  DocumentBucket,
  Language,
  MailType,
  Offer,
  OfferType,
  Office,
  Order,
  OrderConfirmationMailConfiguration,
  ProjectInvoiceType,
  ProtocolDocumentConfig,
  checkProjectForUnbilledProtocols,
  generateDropdownOptions,
  generateNotification,
  getCustomerById,
  getCustomerUserById,
  getExistingOfferDocument,
  getOfferById,
  getOfficeById,
  getOrderById,
  sendOrderConfirmation,
  updateOrder,
  useData,
} from "shared";
import { getCustomerLocationById } from "shared/src/customerLocation/CustomerLocation.axios";
import {
  CustomerLocation,
  Shift,
  ShiftType,
} from "shared/src/customerLocation/CustomerLocation.types";
import { NotificationType } from "shared/src/notification/notification.types";
import {
  completeProject,
  completeProjectWithInvoice,
  deactivateProject,
  getOrderConfirmationPdf,
  getProjectDescriptionPdf,
  updateProject,
} from "shared/src/project/Project.axios";
import {
  ErrorPattern,
  Project,
  Tooling,
} from "shared/src/project/Project.types";
import { ReactComponent as XIcon } from "../../assets/cancel.svg";
import { ReactComponent as CheckIcon } from "../../assets/check-circle.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { FileOverview } from "../../components/files/FileOverview";
import { MailPopup } from "../../components/mailPopup/MailPopup";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { SoftLockedPopup } from "../../components/SoftLockedPopup";
import { useUser } from "../../components/UserContext";
import { generateEmptyShift } from "../../utils/customerLocation/CustomerLocation.utils";
import { downloadFile } from "../../utils/files/Files.utils";
import { generateEmptyMailConfiguration } from "../../utils/mail/Mail.utils";
import {
  convertOfferedTasksIntoTableEntries,
  generateDropdownOptionsForCustomerUsers,
  generateDropdownOptionsForCustomers,
} from "../../utils/order/Order.utils";
import {
  convertErrorPatternsIntoTableRows,
  convertWorkInstructionsIntoTableRows,
  generateEmptyProtocolMailingSettings,
  generateNewErrorPattern,
} from "../../utils/project/Project.utils";
import { getUserNameForSimpleUser } from "../../utils/user/User.utils";

export const ProjectEdit: React.FC = () => {
  const { axios, user, updateFootnoteConfig } = useUser();
  const location = useLocation<{ project: Project; order?: Order }>();
  const { data: loadedTasks } = useData("TASKS_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  const [projectToEdit, setProjectToEdit] = useState<Project | undefined>(
    location.state?.project
  );
  const [offer, setOffer] = useState<Offer>();
  const [offeredTasksRows, setOfferedTaskRows] = useState<TableRow[]>([]);
  const [customer, setCustomer] = useState<Customer>();
  const [order, setOrder] = useState<Order>();
  const [softlockConfirmed, toggleSoftlockConfirmed] = useState<boolean>(false);
  const [softLockedCustomer, setSoftLockedCustomer] = useState<Customer>();
  const [customerLocation, setCustomerLocation] = useState<CustomerLocation>();
  const [customerUser, setCustomerUser] = useState<CustomerUser>();
  const [office, setOffice] = useState<Office>();
  const [newFeedback, setNewFeedback] = useState<Map<string, number>>(
    new Map<string, number>()
  );
  const [documentToShow, setDocumentToShow] = useState<string>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [uneditedPatterns, setUneditedPatterns] = useState<ErrorPattern[]>();
  const [isEdit, toggleEdit] = useState<boolean>();
  const [isDeletePopupOpen, toggleDeletePopupOpen] = useState<boolean>(false);
  const [isCompletePopupOpen, toggleCompletePopupOpen] =
    useState<boolean>(false);
  const [isMailPopupOpen, toggleMailPopupOpen] = useState<boolean>(false);
  const [mailConfig, setMailConfig] =
    useState<OrderConfirmationMailConfiguration>({
      ...generateEmptyMailConfiguration(),
      projectId: projectToEdit?.id || "",
      type: MailType.ORDER_CONFIRMATION,
      subject: t("pages.project.edit.mailSubject"),
      receiver: "",
      language: Language.DE,
    });

  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  const instructions: TableRow[] = useMemo((): TableRow[] => {
    if (!projectToEdit) return [];
    return convertWorkInstructionsIntoTableRows(projectToEdit, navigate);
  }, [projectToEdit, navigate]);

  const { data: loadedCustomerUsers } = useData("CUSTOMER_USERS_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomers } = useData("CUSTOMER_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  const { data: loadedCustomerFeedbackEntries } = useData("FEEDBACK_ALL", {
    config: { fallbackData: [] },
  });

  const { data: allWorkingEquipment } = useData("WORK_EQUIPMENT_ALL", {
    config: { fallbackData: [] },
  });

  const { data: loadedCurrencies } = useData("CURRENCY_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  const { data: customerLocations } = useData("CUSTOMER_LOCATION_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  const [hasUnbilledProjects, toggleHasUnbilledProjects] =
    useState<boolean>(false);

  // Hook to check for projects withunbilled protocols
  useEffect(() => {
    if (!projectToEdit?.id || !isCompletePopupOpen) return;
    checkProjectForUnbilledProtocols(axios, projectToEdit.id).then(
      toggleHasUnbilledProjects
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompletePopupOpen, projectToEdit?.id]);

  /**
   * Hook to update the footNote containg the create and update data
   */
  useEffect(() => {
    updateFootnoteConfig(projectToEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectToEdit?.createDate, updateFootnoteConfig]);

  /**
   * Keeps rows with the customerFeedbackEntries
   */
  const feedbackRows: TableRow[] = useMemo((): TableRow[] => {
    if (!order) return [];
    return loadedCustomerFeedbackEntries.map((feedbackCategory) => ({
      id: feedbackCategory.id,
      content: [
        feedbackCategory.title,
        <Input
          minValue={1}
          maxValue={6}
          type="number"
          onChangeNumber={(value) =>
            setNewFeedback((old) => old.set(feedbackCategory.id, value))
          }
          value={newFeedback.get(feedbackCategory.id)}
        />,
      ],
    }));
  }, [loadedCustomerFeedbackEntries, newFeedback, order]);
  /**
   * holds tableRows for errorPatterns
   */
  const errorPatternRows: TableRow[] = useMemo(
    () =>
      convertErrorPatternsIntoTableRows(
        projectToEdit?.errorPatterns || [],
        setProjectToEdit,
        !isEdit
      ),
    [projectToEdit, isEdit]
  );

  //Hook to load the customer, order and offer for a project
  useEffect(() => {
    if (!projectToEdit || order?.id) return;
    Promise.all([
      getCustomerById(axios, projectToEdit.customerId),
      getOfferById(axios, projectToEdit.acceptedOfferId),
    ]).then(([loadedCustomer, loadedOffer]) => {
      setCustomer(loadedCustomer);
      setOffer(loadedOffer);
    });
    if (location.state?.order) {
      setOrder(location.state.order);
    } else
      getOrderById(axios, projectToEdit.orderId).then((loadedOrder) =>
        setOrder(loadedOrder)
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, projectToEdit]);

  // in case there is no order number and the customer has a soft lock show the softlock popup
  useEffect(() => {
    if (softlockConfirmed || !order || !customer) return;
    if (customer.softLocked && !order.customerOrderNumber)
      setSoftLockedCustomer(customer);
  }, [customer, order, softlockConfirmed]);

  //Hook to load customerLocation and the offered positions on the project after the order was loaded
  useEffect(() => {
    if (!order) return;
    Promise.all([
      getCustomerLocationById(axios, order.locationId),
      getCustomerUserById(axios, order.customerContact),
      getOfficeById(axios, order.officeId),
    ]).then(([loadedCustomerLocation, loadedCustomerUser, loadedOffice]) => {
      setCustomerLocation(loadedCustomerLocation);
      setCustomerUser(loadedCustomerUser);
      setOffice(loadedOffice);
      if (loadedOffice?.generalMailConfiguration)
        setMailConfig({
          ...mailConfig,
          smtpConfig: loadedOffice?.generalMailConfiguration,
          receiver: loadedCustomerUser?.contact.mail || "",
          receiverCC: order.additionalReceivers || [],
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  //Hook to convert offered tasks to be displayed in the table
  useEffect(() => {
    if (!offer || !customer) return;
    setOfferedTaskRows(
      convertOfferedTasksIntoTableEntries(
        offer.offeredTasks,
        loadedTasks,
        order?.officeId || "",
        order?.currencyId || "",
        customer,
        undefined,
        undefined,
        true
      )
    );
  }, [offer, loadedTasks, order?.officeId, order?.currencyId, customer]);

  /**
   * Helper method to update the mailSettings for the projects protocols
   * @param checked new value for the element
   * @param value decides which value gets updated
   */
  const handleMailSettingUpdate = (value: ProtocolDocumentConfig): void => {
    if (!projectToEdit) return;
    setProjectToEdit({
      ...projectToEdit,
      protocolMailing: {
        receivers: projectToEdit.protocolMailing?.receivers || [],
        setting: value,
      },
    });
  };

  /**
   * Holds the possible options for the receivers of the protocol mail
   */
  const protocolReceiverOptions: Option[] = useMemo(
    (): Option[] =>
      loadedCustomerUsers
        .filter(
          (customerUser) =>
            customerUser.customerId === order?.customerId ||
            customerUser.customerId === order?.workingLocation.customerId ||
            customerUser.customerId === order?.invoiceRecipient.customerId
        )
        .map((selectableUser) => ({
          label: `${selectableUser.firstName} ${selectableUser.lastName}`,
          value: selectableUser.contact.mail,
        })),
    [loadedCustomerUsers, order]
  );

  /**
   * Submit handler that updates the project
   * @param redirect decides if the method should navigate back after success

   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity() || !projectToEdit || !order) return;

    Promise.all([
      updateProject(axios, projectToEdit),
      updateOrder(axios, order),
    ]).then(([updatedProject, updatedOrder]) => {
      if (updatedOrder && updatedProject && redirect) navigate("/project");
      else if (updatedOrder && updatedProject) {
        setOrder(updatedOrder);
        setProjectToEdit(updatedProject);
        generateNotification({
          type: NotificationType.SUCCESS,
          value: t("general.notification.success.saveSuccessfull"),
        });
      }
    });
  };

  /**
   * Helper method to handle Downloading an existing offer for the project
   */
  const handleShowOffer = async (): Promise<void> => {
    if (!offer) return;
    const document: Blob | undefined = await getExistingOfferDocument(
      axios,
      offer?.id
    );
    if (!document) return;
    setDocumentToShow(URL.createObjectURL(document));
  };

  /**
   * Util method to update or create a shift
   * @param type type of the Shift
   * @param updateData partial of updateData to update
   */
  const handleUpdateShift = (
    type: ShiftType,
    updateData: Partial<Shift>
  ): void => {
    if (!order) return;
    const existingShift: Shift | undefined = order?.shifts?.find(
      (shift) => shift.type === type
    );
    if (existingShift)
      setOrder({
        ...order,
        shifts: order.shifts.map((shift) =>
          shift.type === type ? { ...shift, ...updateData } : shift
        ),
      });
    else
      setOrder({
        ...order,
        shifts: order.shifts
          ? [
              ...order.shifts,
              generateEmptyShift({
                customerId: order.customerId,
                type,
                locationId: order.locationId,
                ...updateData,
              }),
            ]
          : [
              generateEmptyShift({
                customerId: order.customerId,
                type,
                locationId: order.locationId,
                ...updateData,
              }),
            ],
      });
  };
  /**
   * Helper method to generate Inputs for different shifts and update the order object onChange
   * @returns Array of JSX Elements
   */
  const generateShiftInputs = (): JSX.Element[] => {
    if (!order) return [];
    return Object.values(ShiftType).map((type) => (
      <div className="project-edit__shift-wrapper" key={`shift-type-${type}`}>
        <Input
          type="time"
          onChangeTime={(startTime) => handleUpdateShift(type, { startTime })}
          value={order.shifts?.find((shift) => shift.type === type)?.startTime}
          label={t(`pages.project.edit.startTime.${type}`)}
        />
        <Input
          type="time"
          onChangeTime={(endTime) => handleUpdateShift(type, { endTime })}
          value={order.shifts?.find((shift) => shift.type === type)?.endTime}
          label={t(`pages.project.edit.endTime.${type}`)}
        />
      </div>
    ));
  };

  /**
   * Helper method to add an empty errorPattern to the project
   */
  const handleAddErrorPattern = (): void => {
    if (!projectToEdit) return;
    setProjectToEdit({
      ...projectToEdit,
      errorPatterns: [
        ...projectToEdit.errorPatterns,
        generateNewErrorPattern({
          index: projectToEdit.errorPatterns.length,
        }),
      ],
    });
  };

  /**
   * DropdownOptions to choose a customer for a different invoiceRecipient
   */
  const customerDropdownOptions: Option[] = useMemo(
    (): Option[] => generateDropdownOptionsForCustomers(loadedCustomers),
    [loadedCustomers]
  );

  /**
   * DropdownOptions to choose a customerUser for a different invoiceRecipient
   */
  const customerUserDropdownOptions: Option[] = useMemo((): Option[] => {
    if (!order?.invoiceRecipient?.customerId) return [];
    return generateDropdownOptionsForCustomerUsers(
      loadedCustomerUsers,
      order.invoiceRecipient.customerId
    );
  }, [loadedCustomerUsers, order?.invoiceRecipient?.customerId]);

  /**
   * Util method to download the projectDescription
   */
  const handleDownloadDescription = async (): Promise<void> => {
    if (!projectToEdit) return;
    return getProjectDescriptionPdf(axios, projectToEdit.id).then(
      (loadedFile) =>
        loadedFile &&
        downloadFile(loadedFile, t("pages.project.edit.descriptionFileName"))
    );
  };

  /*
   * Helper method to set the projectState to completed
   */
  const handleCompleteProject = (createInvoice: boolean): void => {
    if (!projectToEdit?.id) {
      generateNotification({
        type: NotificationType.ERROR,
        value: t("general.notification.error.completeProjectId"),
      });
      return;
    }
    if (createInvoice) {
      completeProjectWithInvoice(axios, {
        endDate: new Date(),
        projectId: projectToEdit.id,
        userId: user.id,
      }).then((createdInvoice) => {
        if (createdInvoice)
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.completeProject"),
          });
        navigate("/project");
      });
    } else
      completeProject(axios, projectToEdit.id).then((project) => {
        if (project) {
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.completeProject"),
          });
          navigate("/project");
        }
      });
  };

  /**
   * Helper method to handle the orderConfirmationPreview
   */
  const handleConfirmationPreview = async (): Promise<void> => {
    if (!projectToEdit?.id) return;
    const document: Blob | undefined = await getOrderConfirmationPdf(
      axios,
      projectToEdit.id,
      Language.DE
    );
    if (!document) return;
    setDocumentToShow(URL.createObjectURL(document));
  };

  /**
   * Update method to set the selected receivers for a protocol
   * @param selectedOptions of the dropdown
   */
  const handleChangeReceiver = (selectedOptions: string[]): void => {
    if (!projectToEdit) return;
    setProjectToEdit({
      ...projectToEdit,
      protocolMailing: {
        ...generateEmptyProtocolMailingSettings(),
        ...projectToEdit.protocolMailing,
        receivers: selectedOptions,
      },
    });
  };

  /**
   * Helper method to send the order confirmation per mail
   * @returns Promise containing a boolean depending on the success
   */
  const handleSendOrderConfirmation = async (): Promise<boolean> => {
    if (!projectToEdit) return Promise.resolve(false);
    else return sendOrderConfirmation(axios, mailConfig);
  };

  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      {documentToShow && (
        <Popup
          isOpen={!!documentToShow}
          onClose={() => setDocumentToShow(undefined)}
        >
          <div className="file-preview__wrapper">
            <object
              data={documentToShow}
              type={"application/pdf"}
              width={"1000px"}
              height={"700px"}
            />
            <Button
              value={t("general.buttons.download")}
              maxWidth={"300px"}
              onClick={() => {
                const link = document.createElement("a");
                link.href = documentToShow;
                link.download =
                  t("pages.project.edit.orderConfirmationFilename", {
                    replace: {
                      numberRangeNumber: projectToEdit?.numberRangeNumber,
                    },
                  }) + ".pdf";
                link.click();
              }}
            />
          </div>
        </Popup>
      )}
      <TopBar
        title={t("pages.project.edit.topBarHeadline")}
        onBackClick={() => navigate(-1)}
      >
        <Button
          value={t("general.buttons.completeProject")}
          onClick={() => toggleCompletePopupOpen(true)}
        />
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
        <Button
          value={t("general.buttons.deliveryNote")}
          onClick={() =>
            order &&
            navigate("/delivery", { state: { project: projectToEdit } })
          }
        />
        <Button
          value={t("general.buttons.description")}
          onClick={handleDownloadDescription}
        />
        <Button
          value={t("general.buttons.orderConfirmation")}
          onClick={() => toggleMailPopupOpen(!isMailPopupOpen)}
        />
        <Button
          value={t("general.buttons.orderConfirmationPreview")}
          onClick={handleConfirmationPreview}
        />
      </TopBar>
      <SoftLockedPopup
        closePopup={() => {
          setSoftLockedCustomer(undefined);
          toggleSoftlockConfirmed(true);
        }}
        customer={softLockedCustomer}
      />
      <Popup
        isOpen={isCompletePopupOpen}
        onClose={() => toggleCompletePopupOpen(false)}
        title={t("pages.project.edit.completePopupTitle")}
        buttons={[
          <Button
            type="button"
            value={t("general.buttons.cancel")}
            onClick={() => toggleCompletePopupOpen(false)}
          />,
          <Button
            type="button"
            value={t("general.buttons.createInvoice")}
            onClick={() => handleCompleteProject(true)}
          />,
          <Button
            type="button"
            value={t("general.buttons.completeWithoutInvoice")}
            onClick={() => handleCompleteProject(false)}
          />,
        ]}
      >
        <p className="project-edit__deactivate-popup__information-text">
          {t(`pages.project.edit.completePopup`, {
            replace: { orderNumber: projectToEdit?.numberRangeNumber },
          })}
        </p>
        <p className="project-edit__deactivate-popup__information-text">
          {t(
            `pages.project.edit.completePopup${
              hasUnbilledProjects ? "Unbilled" : "Billed"
            }`,
            {
              replace: { orderNumber: projectToEdit?.numberRangeNumber },
            }
          )}
        </p>
      </Popup>
      <Popup
        isOpen={isDeletePopupOpen}
        onClose={() => toggleDeletePopupOpen(false)}
        title={t("pages.project.edit.deactivatePopup")}
        buttons={[
          <Button
            type="button"
            value={t("general.buttons.yes")}
            onClick={() => {
              if (projectToEdit?.id)
                deactivateProject(axios, projectToEdit.id).then(() =>
                  navigate("/project")
                );
            }}
          />,
          <Button
            type="button"
            value={t("general.buttons.cancel")}
            onClick={() => toggleDeletePopupOpen(false)}
          />,
        ]}
      >
        <p className="project-edit__deactivate-popup__information-text">
          {t("pages.project.edit.deletePopup")}
        </p>
      </Popup>

      <MailPopup
        userId={user.id}
        referenceId={projectToEdit?.id || ""}
        isOpen={isMailPopupOpen}
        mailConfig={mailConfig}
        toggleOpen={toggleMailPopupOpen}
        updateConfig={(newConfig) =>
          setMailConfig((old) => ({ ...old, ...newConfig }))
        }
        sendMail={handleSendOrderConfirmation}
        sendDates={projectToEdit?.sendDates}
      />
      <Box title={t("pages.project.edit.baseData")}>
        <Input
          disabled
          value={office?.name || ""}
          label={t("pages.project.edit.office")}
          onChange={() => {}}
          type="text"
        />
        <div className="three-columns">
          <Input
            disabled
            type="text"
            onChange={() => {}}
            label={t("pages.project.edit.customerName")}
            value={customer?.name || ""}
          />
          <Input
            disabled
            value={customerLocation?.name || ""}
            label={t("pages.project.edit.customerLocation")}
            onChange={() => {}}
            type="text"
          />
          <Input
            disabled
            value={`${customerUser?.firstName} ${customerUser?.lastName}`}
            label={t("pages.project.edit.customerContact")}
            onChange={() => {}}
            type="text"
          />
          <Input
            disabled
            type="text"
            onChange={() => {}}
            label={t("pages.project.edit.workingLocationCustomerName")}
            value={
              loadedCustomers.find(
                (customer) => customer.id === order?.workingLocation.customerId
              )?.name || ""
            }
          />
          <Input
            disabled
            value={
              customerLocations.find(
                (location) =>
                  location.id === order?.workingLocation.customerLocationId
              )?.name || ""
            }
            label={t("pages.project.edit.workingLocationCustomerLocation")}
            onChange={() => {}}
            type="text"
          />
          <Input
            disabled
            value={getUserNameForSimpleUser(
              order?.workingLocation.customerContactId || "",
              loadedCustomerUsers
            )}
            label={t("pages.project.edit.workingLocationCustomerContact")}
            onChange={() => {}}
            type="text"
          />
          {order && (
            <>
              <Dropdown
                selectedOption={order.invoiceRecipient?.customerId}
                label={t("pages.project.edit.invoiceRecipient")}
                options={customerDropdownOptions}
                onChange={(customerId) =>
                  setOrder({
                    ...order,
                    invoiceRecipient: {
                      customerUserId: "",
                      customerId,
                    },
                  })
                }
              />
              <Dropdown
                disabled={!order.invoiceRecipient?.customerId}
                selectedOption={order.invoiceRecipient?.customerUserId}
                label={t("pages.project.edit.invoiceContact")}
                options={customerUserDropdownOptions}
                onChange={(customerUserId) => {
                  if (order.invoiceRecipient)
                    setOrder({
                      ...order,
                      invoiceRecipient: {
                        ...order.invoiceRecipient,
                        customerUserId,
                      },
                    });
                }}
              />
            </>
          )}
          <Input
            value={order?.paymentCondition?.description}
            onChange={() => {}}
            type="text"
            disabled
            label={t("pages.project.edit.paymentCondition")}
          />
          {order && (
            <Dropdown
              label={t("pages.project.edit.currency")}
              selectedOption={order.currencyId}
              onChange={(currencyId) => setOrder({ ...order, currencyId })}
              options={generateDropdownOptions(loadedCurrencies, "title", "id")}
            />
          )}
        </div>

        <div className="three-columns">
          <Input
            type="date"
            label={t("pages.project.edit.projectStartDate")}
            value={projectToEdit?.startDate}
            onChangeDate={(startDate) =>
              projectToEdit &&
              startDate &&
              setProjectToEdit({ ...projectToEdit, startDate })
            }
          />
          <Input
            type="time"
            onChangeTime={(startTime) =>
              projectToEdit && setProjectToEdit({ ...projectToEdit, startTime })
            }
            label={t("pages.project.edit.projectStartTime")}
            value={projectToEdit?.startTime}
          />
        </div>

        <div className="three-columns">
          <Input
            type="date"
            label={t("pages.project.edit.endDate")}
            onChangeDate={(endDate) => {
              if (projectToEdit && endDate)
                setProjectToEdit({ ...projectToEdit, endDate });
            }}
            value={projectToEdit?.endDate}
          />
          <Input
            type="time"
            onChangeTime={(endTime) =>
              projectToEdit && setProjectToEdit({ ...projectToEdit, endTime })
            }
            label={t("pages.project.edit.projectEndTime")}
            value={projectToEdit?.endTime}
          />
        </div>

        <div className="three-columns">
          {order && (
            <Input
              type="text"
              label={t("pages.project.edit.customerOrderNumber")}
              onChange={(customerOrderNumber) =>
                setOrder({ ...order, customerOrderNumber })
              }
              value={order.customerOrderNumber}
            />
          )}
          {order && order.offerType !== OfferType.CUSTOMER_PROJECT && (
            <>
              <Input
                onChange={(customerArticleNumber) =>
                  setOrder({ ...order, customerArticleNumber })
                }
                type="text"
                value={order.customerArticleNumber}
                label={t("pages.order.create.customerArticleNumber")}
              />
              <Input
                onChange={(customerArticleDescription) =>
                  setOrder({
                    ...order,
                    customerArticleDescription,
                  })
                }
                type="text"
                value={order.customerArticleDescription}
                label={t("pages.order.create.customerArticleDescription")}
              />
            </>
          )}
          <Dropdown
            options={protocolReceiverOptions}
            multi
            selectedMultiOptions={projectToEdit?.protocolMailing?.receivers}
            label={t("pages.order.create.protocolReceiver")}
            onChangeMultiple={handleChangeReceiver}
          />
          <div>
            <p>{t("pages.order.create.protocolConfig")}</p>
            <Checkbox
              isChecked={
                projectToEdit?.protocolMailing?.setting ===
                ProtocolDocumentConfig.FULL
              }
              onCheck={() =>
                handleMailSettingUpdate(ProtocolDocumentConfig.FULL)
              }
              label={t("pages.order.create.protocolComplete")}
            />
            <Checkbox
              isChecked={
                projectToEdit?.protocolMailing?.setting ===
                ProtocolDocumentConfig.PIECES
              }
              onCheck={() =>
                handleMailSettingUpdate(ProtocolDocumentConfig.PIECES)
              }
              label={t("pages.order.create.protocolParts")}
            />
            <Checkbox
              isChecked={
                projectToEdit?.protocolMailing?.setting ===
                ProtocolDocumentConfig.TIMES
              }
              onCheck={() =>
                handleMailSettingUpdate(ProtocolDocumentConfig.TIMES)
              }
              label={t("pages.order.create.protocolTimes")}
            />
          </div>
          <div>
            <p>{t("pages.order.create.invoiceConfig")}</p>
            <Checkbox
              isChecked={
                order?.invoiceDocumentConfig === ProtocolDocumentConfig.FULL
              }
              onCheck={() =>
                order &&
                setOrder({
                  ...order,
                  invoiceDocumentConfig: ProtocolDocumentConfig.FULL,
                })
              }
              label={t("pages.order.create.protocolComplete")}
            />
            <Checkbox
              isChecked={
                order?.invoiceDocumentConfig === ProtocolDocumentConfig.PIECES
              }
              onCheck={() =>
                order &&
                setOrder({
                  ...order,
                  invoiceDocumentConfig: ProtocolDocumentConfig.PIECES,
                })
              }
              label={t("pages.order.create.protocolParts")}
            />
            <Checkbox
              isChecked={
                order?.invoiceDocumentConfig === ProtocolDocumentConfig.TIMES
              }
              onCheck={() =>
                order &&
                setOrder({
                  ...order,
                  invoiceDocumentConfig: ProtocolDocumentConfig.TIMES,
                })
              }
              label={t("pages.order.create.protocolTimes")}
            />
          </div>
        </div>
      </Box>
      <Box title={t("pages.project.edit.offerData")}>
        <div className="three-columns">
          {offer?.offerType != OfferType.CUSTOMER_PROJECT && (
            <Input
              label={t("pages.project.edit.acceptDate")}
              disabled
              type="date"
              onChangeDate={() => {}}
              value={offer?.acceptDate || new Date()}
            />
          )}
          <Input
            label={t("pages.project.edit.plannedWorkers")}
            type="number"
            onChangeNumber={() => {}}
            value={offer?.plannedAmountWorker || 0}
          />
          <Input
            value={
              t(`pages.project.edit.providedBy.${offer?.providedBy}`) || ""
            }
            type="text"
            disabled
            onChange={() => {}}
            label={t("pages.project.edit.providedBy.title")}
          />
        </div>
        <Input
          type="text"
          onChange={() => {}}
          disabled
          value={offer?.annotation || ""}
          label={t("pages.project.edit.annotation")}
        />
        <Table
          rows={offeredTasksRows}
          header={
            t("pages.project.edit.offeredTasksTableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
        {offer?.offerType !== OfferType.CUSTOMER_PROJECT && (
          <div className="project-edit--offer-buttons">
            <Button
              value={t("general.buttons.showOffer")}
              onClick={handleShowOffer}
            />
            <Button
              value={t("general.buttons.goToOffer")}
              onClick={() =>
                navigate("/offer/create", {
                  state: { offer: offer, isReadOnly: true },
                })
              }
            />
          </div>
        )}
      </Box>
      {projectToEdit && (
        <Box title={t("pages.project.edit.projectData")}>
          <TextArea
            onChange={(annotation) =>
              setProjectToEdit({ ...projectToEdit, annotation })
            }
            value={projectToEdit.annotation}
            label={t("pages.project.edit.annotation")}
            resizable
          />
          <div className="project-edit__project-info-wrapper">
            <Dropdown
              label={t("pages.project.edit.invoiceType")}
              options={Object.values(ProjectInvoiceType).map((type) => ({
                label: t(`general.projectInvoiceType.${type}`),
                value: type,
              }))}
              selectedOption={order?.invoiceType}
              onChange={(type) =>
                order &&
                setOrder({
                  ...order,
                  invoiceType: type as ProjectInvoiceType,
                })
              }
            />
            <Checkbox
              highlighted
              label={t("pages.project.edit.calculatingBreaks")}
              isChecked={projectToEdit.calculatingBreaks}
              onCheck={(calculatingBreaks) =>
                setProjectToEdit({ ...projectToEdit, calculatingBreaks })
              }
            />
          </div>
          {offer?.offerType !== OfferType.CUSTOMER_PROJECT && (
            <div className="three-columns">
              <div>
                <div>
                  <p>{t("pages.project.edit.equipment")}</p>
                  {allWorkingEquipment.map((equipment) => (
                    <Checkbox
                      key={`work-equipment-checkbox-${equipment.id}`}
                      label={equipment.title}
                      isChecked={projectToEdit.equipments.includes(
                        equipment.id
                      )}
                      onCheck={() =>
                        projectToEdit.equipments.includes(equipment.id)
                          ? setProjectToEdit({
                              ...projectToEdit,
                              equipments: projectToEdit.equipments.filter(
                                (existingEquipment) =>
                                  existingEquipment !== equipment.id
                              ),
                            })
                          : setProjectToEdit({
                              ...projectToEdit,
                              equipments: [
                                ...projectToEdit.equipments,
                                equipment.id,
                              ],
                            })
                      }
                    />
                  ))}
                </div>
                <Input
                  label={t("pages.project.edit.additionalEquipment")}
                  value={projectToEdit.additionalEquipment}
                  type="text"
                  onChange={(additionalEquipment) =>
                    setProjectToEdit({ ...projectToEdit, additionalEquipment })
                  }
                />
              </div>
              <div>
                {Object.values(Tooling).map((tool) => (
                  <Checkbox
                    key={`tooling-checkbox-${tool}`}
                    label={t(`general.tools.${tool}`)}
                    isChecked={projectToEdit.toolings.includes(tool)}
                    onCheck={() =>
                      projectToEdit.toolings.includes(tool)
                        ? setProjectToEdit({
                            ...projectToEdit,
                            toolings: projectToEdit.toolings.filter(
                              (existingTooling) =>
                                existingTooling.toString() !== tool
                            ),
                          })
                        : setProjectToEdit({
                            ...projectToEdit,
                            toolings: [
                              ...projectToEdit.toolings,
                              tool as Tooling,
                            ],
                          })
                    }
                  />
                ))}
                <Input
                  label={t("pages.project.edit.additionalTools")}
                  value={projectToEdit.additionalTooling}
                  type="text"
                  onChange={(additionalTooling) =>
                    setProjectToEdit({ ...projectToEdit, additionalTooling })
                  }
                />
              </div>
              <div>
                <Input
                  value={projectToEdit.amountLimit}
                  onChangeNumber={(amountLimit) =>
                    setProjectToEdit({ ...projectToEdit, amountLimit })
                  }
                  type="number"
                  label={t("pages.project.edit.amountLimit")}
                />
                <Input
                  value={projectToEdit.timeLimit}
                  onChangeNumber={(timeLimit) =>
                    setProjectToEdit({ ...projectToEdit, timeLimit })
                  }
                  type="number"
                  label={t("pages.project.edit.timeLimit")}
                />
                <Input
                  value={projectToEdit.moneyLimit}
                  onChangeNumber={(moneyLimit) =>
                    setProjectToEdit({ ...projectToEdit, moneyLimit })
                  }
                  type="number"
                  label={t("pages.project.edit.moneyLimit")}
                />
              </div>
            </div>
          )}
        </Box>
      )}
      <Box
        title={
          <div className="user-create__box-title-wrapper">
            <p className="box__title">{t("pages.project.edit.shifts")} </p>
            <DeleteIcon
              title={t("general.icons.delete")}
              className="user-create__box-title-wrapper__icon__delete"
              onClick={() => {
                if (order) setOrder({ ...order, shifts: [] });
              }}
            />
          </div>
        }
      >
        <div className="four-columns">{generateShiftInputs()}</div>
      </Box>
      {offer?.offerType !== OfferType.CUSTOMER_PROJECT && (
        <Box
          title={
            <div className="user-create__box-title-wrapper">
              <p className="box__title">
                {t("pages.project.edit.instructions")}
              </p>
              <PlusIcon
                title={t("general.icons.add")}
                onClick={() =>
                  navigate("/project/instruction", {
                    state: { project: projectToEdit, order: order },
                  })
                }
                className="user-create__box-title-wrapper__icon"
              />
            </div>
          }
        >
          <Table
            rows={instructions}
            header={
              t("pages.project.edit.instructionTableHeader", {
                returnObjects: true,
              }) as TableHeader[]
            }
          />
        </Box>
      )}
      <Box
        title={
          <div className="user-create__box-title-wrapper">
            <p className="box__title">
              {t("pages.project.edit.errorPatterns")}
            </p>
            {!isEdit && (
              <EditIcon
                title={t("general.icons.edit")}
                onClick={() => {
                  toggleEdit(true);
                  setUneditedPatterns(projectToEdit?.errorPatterns);
                }}
                className="user-create__box-title-wrapper__icon"
              />
            )}
            {isEdit && (
              <div className="user-create__box-title-wrapper">
                <PlusIcon
                  title={t("general.icons.add")}
                  onClick={handleAddErrorPattern}
                  className="user-create__box-title-wrapper__icon"
                />
                <CheckIcon
                  className="user-create__box-title-wrapper__icon"
                  onClick={() => toggleEdit(false)}
                />
                <XIcon
                  className="user-create__box-title-wrapper__icon__delete"
                  onClick={() => {
                    setProjectToEdit({
                      ...projectToEdit!,
                      errorPatterns: uneditedPatterns || [],
                    });
                    toggleEdit(false);
                  }}
                />
              </div>
            )}
          </div>
        }
      >
        <Table
          header={
            t("pages.project.edit.errorPatternsTableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
          rows={errorPatternRows}
        />
      </Box>
      <Box title={t("pages.project.edit.customerFeedback")}>
        <span>{t("pages.project.edit.lastFeedback")}</span>
        {order &&
          Array.from(order.customerFeedback).map(([date, results]) => {
            return (
              <div key={`customer-feedback-entry-${date}`}>
                <span>{new Date(date).toLocaleDateString("de-DE")}</span>
                <div>
                  {Object.entries(results).map(([id, result]) => (
                    <div>
                      {
                        loadedCustomerFeedbackEntries.find(
                          (entry) => entry.id === id
                        )!.title
                      }
                      : {result}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        <Table
          rows={feedbackRows}
          header={
            t("pages.project.edit.feedbackTableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
        <Button
          onClick={() => {
            setOrder((old) => {
              old!.customerFeedback.set(
                new Date().toISOString().split("T")[0],
                newFeedback
              );
              setNewFeedback(new Map<string, number>());
              return old;
            });
          }}
          value={"Speichern"}
        />
      </Box>
      {projectToEdit && (
        <Box>
          <FileOverview
            bucket={DocumentBucket.PROJECT}
            bucketsToShow={[DocumentBucket.PROJECT, DocumentBucket.INVOICE]}
            path={`${order?.customerId}/${projectToEdit.orderId}/`}
          />
        </Box>
      )}
      {projectToEdit && (
        <Box>
          <div className="project-edit__deactivate-wrapper">
            <Button
              maxWidth={200}
              backgroundColor="#BC2E46"
              value={t("general.buttons.delete")}
              onClick={() => toggleDeletePopupOpen(true)}
            />
          </div>
        </Box>
      )}
    </form>
  );
};
