import { AxiosInstance } from "axios";
import { Customer } from "./Customer.types";
import { CustomerUser } from "../user/User.types";
import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";
import i18n from "../../../client/src/i18n/i18n";
import { mapReplacer } from "..";

/**
 * API method to load all customers
 * @param axios instance of axios
 * @returns array of all customers
 */
export const getAllCustomers = async (
  axios: AxiosInstance
): Promise<Customer[]> => {
  return axios
    .get("/customer/all")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading customers", exc);
      return [];
    });
};

/**
 * API method to load all active customers
 * @param axios instance of axios
 * @returns array of all customers
 */
export const getAllActiveCustomers = async (
  axios: AxiosInstance
): Promise<Customer[]> => {
  return axios
    .get("/customer/all/active")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading customers", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllActiveCustomers"),
      });
      return [];
    });
};

/**
 * Get Method to check if a specific vatNumber already exists in the customers
 * @param axios instance of axios
 * @param vatId the vatNumber to check
 * @returns  Promise containing a boolean while true means the vatNumber already exists
 */
export const checkForExistingCustomerVatId = async (
  axios: AxiosInstance,
  newId: string,
  customerId: string = ""
): Promise<boolean> => {
  return axios
    .get("/customer/exists/vat", { params: { newId, customerId } })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.existingCustomerVatId"),
      });
      console.error("Error while checking for existing customer", exc);
      return false;
    });
};

/**
 * API Method to create a new customer
 * @param axios instance of axios
 * @param customer customer to create on the db
 * @returns created customer or undeined on error
 */
export const createNewCustomer = async (
  axios: AxiosInstance,
  customer: Customer
): Promise<Customer | undefined> => {
  return axios
    .post("customer/", JSON.stringify(customer, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while creating customer", exc);
      return undefined;
    });
};

/**
 * API method to update a customer
 * @param axios  instance of axios
 * @param customer  to update on the db
 * @returns  updated customer on success, undefined on error
 */
export const updateCustomer = async (
  axios: AxiosInstance,
  customer: Customer
): Promise<Customer> => {
  return axios
    .post("customer/update", JSON.stringify(customer, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while updating customer", exc);
      return undefined;
    });
};

/**
 * API method to load all customerUsers
 * @param axios
 * @returns
 */
export const getAllCustomerUsers = async (
  axios: AxiosInstance
): Promise<CustomerUser[]> => {
  return axios
    .get("/customer/user/all")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading all customerUsers", exc);
      return [];
    });
};

/**
 * API method to load all active customerUsers
 * @param axios
 * @returns
 */
export const getAllActiveCustomerUsers = async (
  axios: AxiosInstance
): Promise<CustomerUser[]> => {
  return axios
    .get("/customer/user/all")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading all customerUsers", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllActiveCustomerUsers"),
      });
      return [];
    });
};

/**
 * API method to create a new customerUser on the db
 * @param axios instance of axios
 * @param customerUser to create
 * @returns saved customerUser or undefined on error
 */
export const createNewCustomerUser = async (
  axios: AxiosInstance,
  customerUser: CustomerUser
): Promise<CustomerUser | undefined> => {
  return axios
    .post("/customer/user/", customerUser)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while creating customerUser", exc);
      return undefined;
    });
};

/**
 * API Method to update a customerUser
 * @param axios instance of axios
 * @param customerUser updated customerUser object
 * @returns  updated customerUser object or undefined on error
 */
export const updateCustomerUser = async (
  axios: AxiosInstance,
  customerUser: CustomerUser
): Promise<CustomerUser | undefined> => {
  return axios
    .post("/customer/user/update", customerUser)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while updating customerUser", exc);
      return undefined;
    });
};

/**
 * API method to unassign multiple users from their customers
 *
 * @param userIds The ids of the user to update
 * @param axios The axios instance
 */
export const unassignMultipleUserFromCustomer = async (
  userIds: string[],
  axios: AxiosInstance
): Promise<void> => {
  if (userIds.length === 0) return;
  await axios
    .post("/customer/user/update/multiple/unassign", userIds)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateCustomerUser"),
      });
      console.error("Error while updating customer user", exc);
    });
};

/**
 * API method to load all customerUser for a customer
 * @param axios instance of axios
 * @param customerId id of the customer to load the users for
 * @returns array of all found customerUsers
 */
export const getAllCustomerUsersForCustomer = async (
  axios: AxiosInstance,
  customerId: string
): Promise<CustomerUser[]> => {
  return axios
    .get("/customer/user/customer/all", { params: { customerId } })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading customerUsers for a customer", exc);
      return [];
    });
};

/**
 * API method to deactivate a customerUser
 * @param axios instance of axios
 * @param id id of the customerUser to deactivate
 * @returns updated customerUser or undefined on error
 */
export const deactivateCustomerUser = async (
  axios: AxiosInstance,
  id: string
): Promise<CustomerUser> => {
  return axios
    .post("/customer/user/deactivate", id, {
      headers: { "Content-Type": "text/plain" },
    })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while deactivating customer", exc);
      return undefined;
    });
};

/**
 * API method to delete a customerUser
 * @param axios instance of axios
 * @param id id of the customerUser to delete
 * @returns Promise containng true on success or false on error
 */
export const deleteCustomer = async (
  axios: AxiosInstance,
  id: string
): Promise<boolean> => {
  return axios
    .post("/customer/delete", id, {
      headers: { "Content-Type": "text/plain" },
    })
    .then((res) => res.status === 200)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.deleteCustomer"),
      });
      console.error("Error while deleting customer", exc);
      return false;
    });
};
/*
 * API Method to load a customer by id
 * @param axios instance of axios
 * @param id id of the customer to load
 * @returns Found customer or undefined if nothing was found
 */
export const getCustomerById = async (
  axios: AxiosInstance,
  id: string
): Promise<Customer | undefined> => {
  return axios
    .get("/customer/", { params: { id } })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading cutomer by id", exc);
      return undefined;
    });
};
/**
 * API Method to load a customerUser by id
 * @param axios instance of axios
 * @param id id of the customerUser to load
 * @returns  loaded customerUser or undefined if nothing was found
 */
export const getCustomerUserById = async (
  axios: AxiosInstance,
  id: string
): Promise<CustomerUser | undefined> => {
  return axios
    .get("/customer/user/", { params: { id } })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading customerUser", exc);
      return undefined;
    });
};

/**
 *
 * @param axios API Method to check if a customer has active projects
 * @param customerId id of the customer to check
 * @returns true if there is at least one open project
 */
export const checkForOpenProjects = async (
  axios: AxiosInstance,
  customerId: string
): Promise<boolean> => {
  return axios
    .get("/project/customer/open", { params: { customerId } })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.checkForOpenProjects"),
      });
      console.error("Error while checking for open projects", exc);
      return false;
    });
};
