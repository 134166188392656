import { Box, Table, TableRow, TopBar } from "@sam/components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { updateSchambeckUserTableConfig, useData } from "shared";
import { useMemo } from "react";
import { convertBusinessAreasIntoTableEntries } from "../../utils/businessArea/BusinessArea.utils";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import { useUser } from "../../components/UserContext";

export const BusinessAreaOverview: React.FC = () => {
  const { t } = useTranslation();
  const { axios, user, updateContextUser } = useUser();
  const navigate = useNavigate();

  const { data: loadedBusinessAreas } = useData("BUSINESSAREA_ALL", {
    config: { fallbackData: [] },
  });

  const { data: loadedCompanies } = useData("COMPANY_ALL", {
    config: { fallbackData: [] },
  });

  const businessAreaRows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertBusinessAreasIntoTableEntries(
        loadedBusinessAreas,
        navigate,
        loadedCompanies
      ),
    [loadedBusinessAreas, loadedCompanies, navigate]
  );
  return (
    <>
      <TopBar title={t("pages.businessArea.overview.topBarHeadline")}>
        <PlusIcon
          title={t("general.icons.add")}
          onClick={() => navigate("/businessarea/edit")}
        />
      </TopBar>
      <Box>
        <Table
          tableConfig={user.tableConfig?.get(TableKey.BUSINESS_AREA_OVERVIEW)}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.BUSINESS_AREA_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          rows={businessAreaRows}
          header={
            t("pages.businessArea.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
