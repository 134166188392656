import {
  Agenda,
  Box,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Option,
  Table,
  TableRow,
  TopBar,
} from "@sam/components";
import { AgendaEntryGroup } from "@sam/components/src/Agenda/Agenda.types";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Car,
  CarType,
  Comment,
  DocumentBucket,
  FuelType,
  Schedule,
  ScheduleType,
  SimpleUser,
  TransmissionType,
  createNewCar,
  deleteSchedule,
  generateEmptyCar,
  generateNewSchedule,
  generateNotification,
  loadMilageHistoryForCar,
  updateCar,
  updateSchedule,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { FileOverview } from "../../components/files/FileOverview";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";

import { ScheduleEdit } from "../../components/schedule/ScheduleEdit";
import {
  convertMilageHistoryInTableEntries,
  generateCarAgendaScheduleEntries,
} from "../../utils/car/Car.utils";
import {
  convertCommentsIntoTableEntries,
  createComment,
} from "../../utils/comment/Comment.utils";
import { generateDropdownOptionsForOffice } from "../../utils/office/Office.utils";
import {
  generateEmptyAppointmentDetails,
  generateEmptySchedule,
} from "../../utils/project/Project.utils";

const CarCreateOrEdit: React.FC = () => {
  const { user, axios, updateFootnoteConfig } = useUser();
  const location = useLocation<{ carToEdit: Car }>();
  const [scheduleToEdit, setScheduleToEdit] = useState<Schedule>();
  const [carToEdit, setCarToEdit] = useState<Car>(
    location.state?.carToEdit ? location.state.carToEdit : generateEmptyCar()
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  const [newComment, setNewComment] = useState<Comment>(
    createComment(user.id, "")
  );

  const { data: loadedOffices } = useData("OFFICES_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedSimpleUsers } = useData("SIMPLEUSERS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allProject } = useData("PROJECT_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allAbsenceReasons } = useData("ABSENCE_REASONS_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: allCarSchedules, mutate: updateCarSchedules } = useData(
    "SCHEDULE_CAR_ALL",
    {
      config: { fallbackData: [] },
      params: { referenceId: carToEdit.id },
    }
  );

  // lazyload the milage history for the given car
  useEffect(() => {
    if (carToEdit.id)
      loadMilageHistoryForCar(carToEdit.id, axios).then((history) =>
        setCarToEdit((old) => ({ ...old, milageHistories: history }))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Hook to update the footNote containg the create and update data
   */
  useEffect(() => {
    updateFootnoteConfig(carToEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carToEdit.createDate, updateFootnoteConfig]);
  /**
   * Dropdown options to choose the office for the car
   */
  const officeOptions: Option[] = useMemo(
    (): Option[] => generateDropdownOptionsForOffice(loadedOffices),
    [loadedOffices]
  );

  /**
   * Callback function to remove a comment
   * @param id of the comment to remove
   */
  const removeComment = useCallback(
    (id: string): void =>
      setCarToEdit({
        ...carToEdit,
        comments: carToEdit.comments.filter((comment) => comment.id !== id),
      }),
    [carToEdit]
  );

  /**
   * Holds comments as TableRow array
   */
  const commentRows: TableRow[] = useMemo(
    () =>
      convertCommentsIntoTableEntries(
        carToEdit.comments,
        loadedSimpleUsers,
        removeComment
      ),
    [carToEdit.comments, loadedSimpleUsers, removeComment]
  );

  /**
   * Holds the entries needed to display the agenda
   */
  const agendaEntries: AgendaEntryGroup[] = useMemo((): AgendaEntryGroup[] => {
    if (!allCarSchedules) return [];
    return generateCarAgendaScheduleEntries(
      allCarSchedules,
      carToEdit.id,
      allProject,
      allAbsenceReasons
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProject, carToEdit, allCarSchedules]);

  /**
   * Helper to create a new schedule entry on the backend and reset the local state
   */
  const handleScheduleCreate = (): void => {
    if (!scheduleToEdit) return;
    if (!scheduleToEdit.id)
      generateNewSchedule(scheduleToEdit, axios).then((schedule) => {
        if (!schedule) return;
        updateCarSchedules([schedule]);
        setScheduleToEdit(undefined);
      });
    else {
      updateSchedule(axios, scheduleToEdit).then((schedule) => {
        if (!schedule) return;
        updateCarSchedules([schedule]);
        setScheduleToEdit(undefined);
      });
    }
  };

  /**
   * Helper method to delete a schedule
   */
  const handleDeleteSchedule = (): void => {
    if (!scheduleToEdit || !scheduleToEdit.id) return;
    deleteSchedule(axios, scheduleToEdit.id).then((success) => {
      success &&
        generateNotification({
          type: NotificationType.SUCCESS,
          value: t("general.notification.success.deleteSchedule"),
        });
      setScheduleToEdit(undefined);
      updateCarSchedules();
    });
  };

  /**
   * Submit handler to update or create a new car
   * @param redirect decides if the method navigates back
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;

    if (location.state?.carToEdit) {
      updateCar(axios, carToEdit).then((updatedCar) => {
        if (updatedCar && redirect) navigate("/cars");
        else if (updatedCar) {
          setCarToEdit(updatedCar);
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.saveSuccessfull"),
          });
        }
      });
    } else
      createNewCar(axios, {
        ...carToEdit,
        lastUpdated: new Date(),
        updatedBy: user.id,
      }).then((createdCar) => {
        if (createdCar && redirect) navigate("/cars");
        else if (createdCar) {
          setCarToEdit(createdCar);
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.saveSuccessfull"),
          });
        }
      });
  };

  /**
   * Holds a small info text about the assigned user of this car
   */
  const assignedUser: JSX.Element = useMemo((): JSX.Element => {
    if (!carToEdit.userId)
      return <p>{t("pages.carCreateOrEdit.noAssignedUser")}</p>;
    const foundUser: SimpleUser | undefined = loadedSimpleUsers.find(
      (user) => user.id === carToEdit.userId
    );
    if (!foundUser)
      return <p>{t("pages.carCreateOrEdit.assignedUserNotFound")}</p>;
    return (
      <p>
        {t("pages.carCreateOrEdit.assignedUser", {
          replace: {
            FIRSTNAME: foundUser.firstName,
            LASTNAME: foundUser.lastName,
          },
        })}
      </p>
    );
  }, [carToEdit, loadedSimpleUsers, t]);

  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={
          location.state?.carToEdit
            ? t("pages.carCreateOrEdit.editHeadline")
            : t("pages.carCreateOrEdit.createHeadline")
        }
      >
        <SaveButtons buttonRef={button} handleSubmit={handleSubmit} />
      </TopBar>
      <Box title={t("pages.carCreateOrEdit.mainInformation")}>
        <fieldset className="car-create__input-fieldset three-columns">
          <Input
            required
            type="text"
            value={carToEdit.brand}
            onChange={(brand) => setCarToEdit({ ...carToEdit, brand })}
            label={t("pages.carCreateOrEdit.car.brand")}
          />
          <Input
            required
            label={t("pages.carCreateOrEdit.car.model")}
            type="text"
            value={carToEdit.model}
            onChange={(model) => setCarToEdit({ ...carToEdit, model })}
          />
          <Dropdown
            required
            options={officeOptions}
            label={t("pages.carCreateOrEdit.car.office")}
            onChange={(officeId) => setCarToEdit({ ...carToEdit, officeId })}
            selectedOption={carToEdit.officeId}
          />
          <Dropdown
            options={Object.keys(CarType).map((type) => ({
              label: t(`pages.carCreateOrEdit.car.types.${type}`),
              value: type,
            }))}
            label={t("pages.carCreateOrEdit.car.type")}
            onChange={(type) =>
              setCarToEdit({ ...carToEdit, type: type as CarType })
            }
          />
          <Input
            required
            label={t("pages.carCreateOrEdit.car.milage")}
            type="number"
            minValue={0}
            value={carToEdit.milage}
            onChangeNumber={(milage) => {
              if (!milage) return;
              setCarToEdit({
                ...carToEdit,
                milage,
              });
            }}
          />
          <Input
            required
            label={t("pages.carCreateOrEdit.car.registrationPlates")}
            type="text"
            value={carToEdit.registrationPlate}
            onChange={(registrationPlate) =>
              setCarToEdit({ ...carToEdit, registrationPlate })
            }
          />
          <Input
            required
            label={t("pages.carCreateOrEdit.car.registrationDate")}
            type="date"
            value={carToEdit.registrationDate}
            onChangeDate={(registrationDate) => {
              if (!registrationDate) return;
              setCarToEdit({ ...carToEdit, registrationDate });
            }}
          />
          <Input
            label={t("pages.carCreateOrEdit.car.hsn")}
            type="text"
            value={carToEdit.typeKey.hsn}
            onChange={(hsn) =>
              setCarToEdit({
                ...carToEdit,
                typeKey: { tsn: carToEdit.typeKey.tsn, hsn },
              })
            }
          />
          <Input
            label={t("pages.carCreateOrEdit.car.tsn")}
            type="text"
            value={carToEdit.typeKey.tsn}
            onChange={(tsn) =>
              setCarToEdit({
                ...carToEdit,
                typeKey: { hsn: carToEdit.typeKey.hsn, tsn },
              })
            }
          />

          <Checkbox
            isChecked={!carToEdit.disabled}
            onCheck={() =>
              setCarToEdit({ ...carToEdit, disabled: !carToEdit.disabled })
            }
            label={t("pages.carCreateOrEdit.car.active")}
          />
          <Checkbox
            isChecked={carToEdit.poolUsage}
            onCheck={(poolUsage) => setCarToEdit({ ...carToEdit, poolUsage })}
            label={t("pages.carCreateOrEdit.car.poolUsage")}
          />
          {assignedUser}
          <Input
            label={t("pages.carCreateOrEdit.car.vendor")}
            type="text"
            value={carToEdit.vendor}
            onChange={(vendor) =>
              setCarToEdit({
                ...carToEdit,
                vendor,
              })
            }
          />
          <Input
            label={t("pages.carCreateOrEdit.car.leasingNumber")}
            type="text"
            value={carToEdit.leasingNumber}
            onChange={(leasingNumber) =>
              setCarToEdit({
                ...carToEdit,
                leasingNumber,
              })
            }
          />
        </fieldset>
      </Box>
      <Box title={t("pages.carCreateOrEdit.furtherInformation")}>
        <fieldset className="three-columns">
          <Input
            label={t("pages.carCreateOrEdit.car.grossListPrice")}
            type="number"
            minValue={0}
            value={carToEdit.grossListPrice}
            onChangeNumber={(grossListPrice) => {
              if (!grossListPrice) return;
              setCarToEdit({
                ...carToEdit,
                grossListPrice,
              });
            }}
          />
          <Dropdown
            label={t("pages.carCreateOrEdit.car.transmission")}
            onChange={(transmissionType) =>
              setCarToEdit({
                ...carToEdit,
                transmissionType: transmissionType as TransmissionType,
              })
            }
            selectedOption={carToEdit.transmissionType.toString()}
            options={Object.values(TransmissionType).map(
              (transmissionType) => ({
                label: t(
                  `pages.carCreateOrEdit.car.transmissionTypes.${transmissionType}`
                ),
                value: transmissionType,
              })
            )}
          />
          <Input
            type="number"
            value={carToEdit.seats}
            onChangeNumber={(seats) => setCarToEdit({ ...carToEdit, seats })}
            label={t("pages.carCreateOrEdit.car.seats")}
          />
          <Input
            label={t("pages.carCreateOrEdit.car.color")}
            type="text"
            value={carToEdit.color}
            onChange={(color) => setCarToEdit({ ...carToEdit, color })}
          />
          <Dropdown
            label={t("pages.carCreateOrEdit.car.fuelType")}
            selectedOption={carToEdit.fuelType.toString()}
            onChange={(fuelType) =>
              setCarToEdit({
                ...carToEdit,
                fuelType: fuelType as unknown as FuelType,
              })
            }
            options={Object.values(FuelType).map((fuelType) => ({
              label: t(`pages.carCreateOrEdit.car.fuelTypes.${fuelType}`),
              value: fuelType.toString(),
            }))}
          />
          <Input
            type="date"
            value={carToEdit.availableFrom}
            onChangeDate={(availableFrom) => {
              if (!availableFrom) return;
              setCarToEdit({ ...carToEdit, availableFrom });
            }}
            label={t("pages.carCreateOrEdit.car.availableFrom")}
          />
          <Input
            type="number"
            value={carToEdit.enginePowerKw}
            label={t("pages.carCreateOrEdit.car.enginePowerKw")}
            onChangeNumber={(enginePowerKw) => {
              if (!enginePowerKw) return;
              setCarToEdit({ ...carToEdit, enginePowerKw });
            }}
          />
          <Input
            type="number"
            value={Math.round(carToEdit.enginePowerKw * 1.36)}
            label={t("pages.carCreateOrEdit.car.enginePowerPs")}
            onChangeNumber={(powerPS) => {
              if (!powerPS) return;
              setCarToEdit({
                ...carToEdit,
                enginePowerKw: Math.round(powerPS / 1.36),
              });
            }}
          />
          <Input
            value={carToEdit.generalInspection}
            type="date"
            onChangeDate={(generalInspection) => {
              if (!generalInspection) return;
              setCarToEdit({ ...carToEdit, generalInspection });
            }}
            label={t("pages.carCreateOrEdit.car.generalInspection")}
          />
          <Checkbox
            isChecked={carToEdit.restricted}
            onCheck={(restricted) => setCarToEdit({ ...carToEdit, restricted })}
            label={t("pages.carCreateOrEdit.car.restricted")}
          />
          <Input
            type="text"
            value={carToEdit.restrictedReason}
            disabled={carToEdit.restricted}
            onChange={(restrictedReason) =>
              setCarToEdit({ ...carToEdit, restrictedReason })
            }
            label={t("pages.carCreateOrEdit.car.restrictedReason")}
          />
        </fieldset>
      </Box>
      <Box title={t("pages.carCreateOrEdit.boxTitleLeasing")}>
        <fieldset className="three-columns">
          <Input
            value={carToEdit.vehicleContractInformation.insurance}
            label={t("pages.carCreateOrEdit.insurance")}
            type="number"
            onChangeNumber={(insurance) =>
              setCarToEdit({
                ...carToEdit,
                vehicleContractInformation: {
                  ...carToEdit.vehicleContractInformation,
                  insurance,
                },
              })
            }
          />
          <Input
            label={t("pages.carCreateOrEdit.insuranceNumber")}
            value={carToEdit.vehicleContractInformation.insuranceNumber}
            type="text"
            onChange={(insuranceNumber) =>
              setCarToEdit({
                ...carToEdit,
                vehicleContractInformation: {
                  ...carToEdit.vehicleContractInformation,
                  insuranceNumber,
                },
              })
            }
          />
          <Input
            label={t("pages.carCreateOrEdit.leasingRate")}
            type="number"
            value={carToEdit.vehicleContractInformation.leasingRate}
            onChangeNumber={(leasingRate) =>
              setCarToEdit({
                ...carToEdit,
                vehicleContractInformation: {
                  ...carToEdit.vehicleContractInformation,
                  leasingRate,
                },
              })
            }
          />
          <Input
            value={carToEdit.vehicleContractInformation.priceKmOver}
            type="number"
            onChangeNumber={(priceKmOver) =>
              setCarToEdit({
                ...carToEdit,
                vehicleContractInformation: {
                  ...carToEdit.vehicleContractInformation,
                  priceKmOver,
                },
              })
            }
            label={t("pages.carCreateOrEdit.kmOver")}
          />
          <Input
            value={carToEdit.vehicleContractInformation.priceKmUnder}
            type="number"
            onChangeNumber={(priceKmUnder) =>
              setCarToEdit({
                ...carToEdit,
                vehicleContractInformation: {
                  ...carToEdit.vehicleContractInformation,
                  priceKmUnder,
                },
              })
            }
            label={t("pages.carCreateOrEdit.kmUnder")}
          />
          <Input
            value={carToEdit.vehicleContractInformation.tax}
            type="number"
            onChangeNumber={(tax) =>
              setCarToEdit({
                ...carToEdit,
                vehicleContractInformation: {
                  ...carToEdit.vehicleContractInformation,
                  tax,
                },
              })
            }
            label={t("pages.carCreateOrEdit.tax")}
          />
          <Input
            value={carToEdit.vehicleContractInformation?.leasingStart}
            type="date"
            onChangeDate={(leasingStart) => {
              if (!leasingStart) return;
              setCarToEdit({
                ...carToEdit,
                vehicleContractInformation: {
                  ...carToEdit.vehicleContractInformation,
                  leasingStart,
                },
              });
            }}
            label={t("pages.carCreateOrEdit.leasingStart")}
          />
          <Input
            value={carToEdit.vehicleContractInformation.leasingEnd}
            type="date"
            onChangeDate={(leasingEnd) => {
              if (!leasingEnd) return;
              setCarToEdit({
                ...carToEdit,
                vehicleContractInformation: {
                  ...carToEdit.vehicleContractInformation,
                  leasingEnd,
                },
              });
            }}
            label={t("pages.carCreateOrEdit.leasingEnd")}
          />
          <Input
            value={carToEdit.vehicleContractInformation.yearlyMilage}
            type="number"
            onChangeNumber={(yearlyMilage) =>
              setCarToEdit({
                ...carToEdit,
                vehicleContractInformation: {
                  ...carToEdit.vehicleContractInformation,
                  yearlyMilage,
                },
              })
            }
            label={t("pages.carCreateOrEdit.yearlyMilage")}
          />
          <Input
            value={carToEdit.vehicleContractInformation?.totalLeasingMilage}
            type="number"
            onChangeNumber={(yearlyMilage) =>
              setCarToEdit({
                ...carToEdit,
                vehicleContractInformation: {
                  ...carToEdit.vehicleContractInformation,
                  yearlyMilage,
                },
              })
            }
            label={t("pages.carCreateOrEdit.totalLeasingMilage")}
          />
          <Input
            value={carToEdit.vehicleContractInformation.fuelCardStraehuber}
            type="text"
            onChange={(fuelCardStraehuber) =>
              setCarToEdit({
                ...carToEdit,
                vehicleContractInformation: {
                  ...carToEdit.vehicleContractInformation,
                  fuelCardStraehuber,
                },
              })
            }
            label={t("pages.carCreateOrEdit.fuelCardStraehuber")}
          />
          <Input
            value={carToEdit.vehicleContractInformation.pinStraehuber}
            type="text"
            onChange={(pinStraehuber) =>
              setCarToEdit({
                ...carToEdit,
                vehicleContractInformation: {
                  ...carToEdit.vehicleContractInformation,
                  pinStraehuber,
                },
              })
            }
            label={t("pages.carCreateOrEdit.pinStraehuber")}
          />
          <Input
            value={carToEdit.vehicleContractInformation.fuelCardDKV}
            type="text"
            onChange={(fuelCardDKV) =>
              setCarToEdit({
                ...carToEdit,
                vehicleContractInformation: {
                  ...carToEdit.vehicleContractInformation,
                  fuelCardDKV,
                },
              })
            }
            label={t("pages.carCreateOrEdit.fuelCardDKV")}
          />
          <Input
            value={carToEdit.vehicleContractInformation.pinDKV}
            type="text"
            onChange={(pinDKV) =>
              setCarToEdit({
                ...carToEdit,
                vehicleContractInformation: {
                  ...carToEdit.vehicleContractInformation,
                  pinDKV,
                },
              })
            }
            label={t("pages.carCreateOrEdit.pinDKV")}
          />
          <Checkbox
            isChecked={carToEdit.vehicleContractInformation.winterTyres}
            onCheck={(winterTyres) =>
              setCarToEdit({
                ...carToEdit,
                vehicleContractInformation: {
                  ...carToEdit.vehicleContractInformation,
                  winterTyres,
                },
              })
            }
            label={t("pages.carCreateOrEdit.winterTyres")}
          />
        </fieldset>
      </Box>
      <Box title={t("pages.carCreateOrEdit.commentBoxTitle")}>
        <div className="car-create__create-comment-wrapper">
          <Input
            label={t("pages.carCreateOrEdit.newComment")}
            type="text"
            value={newComment.content}
            onChange={(content) => setNewComment({ ...newComment, content })}
          />
          <Button
            type="button"
            value={t("general.buttons.add")}
            onClick={() => {
              setCarToEdit({
                ...carToEdit,
                comments: [...carToEdit.comments, newComment],
              });
              setNewComment(createComment(user.id, ""));
            }}
          />
        </div>
        <Table
          rows={commentRows}
          header={
            t("pages.carCreateOrEdit.commentTableHeaders", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
      <Box title={t("pages.carCreateOrEdit.milageHistoryTitle")}>
        <Table
          header={
            t("pages.carCreateOrEdit.milageHistoryTableHeaders", {
              returnObjects: true,
            }) as TableHeader[]
          }
          rows={convertMilageHistoryInTableEntries(
            carToEdit.milageHistories,
            loadedSimpleUsers
          )}
        />
      </Box>
      {carToEdit.id && (
        <Box title={t("pages.carCreateOrEdit.documents")}>
          <FileOverview
            bucket={DocumentBucket.CAR}
            path={`${carToEdit.id}/`}
            userId={user.id}
          />
        </Box>
      )}
      <Box title={t("pages.user.create.schedules")}>
        <Agenda
          deleteItem={handleDeleteSchedule}
          handleEdit={(itemId) =>
            setScheduleToEdit(allCarSchedules.find((car) => car.id === itemId))
          }
          entryGroups={agendaEntries}
          addItem={(clickTarget) =>
            setScheduleToEdit(
              generateEmptySchedule({
                startDate: new Date(clickTarget.start) || new Date(),
                endDate:
                  new Date(clickTarget.end || clickTarget.start) || new Date(),
                referenceId: carToEdit.id,
                appointmentDetail: generateEmptyAppointmentDetails(),
                type: ScheduleType.VEHICLE_SCHEDULE,
              })
            )
          }
        />
        {scheduleToEdit && (
          <ScheduleEdit
            handleScheduleCreate={handleScheduleCreate}
            handleScheduleDelete={handleDeleteSchedule}
            schedule={scheduleToEdit}
            updateSchedule={setScheduleToEdit}
          />
        )}
      </Box>
    </form>
  );
};
export default CarCreateOrEdit;
