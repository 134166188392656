import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CrmManageType, updateSchambeckUserTableConfig, useData } from "shared";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { convertCrmManageEntriesIntoTableEntries } from "../../utils/crm/Crm.utils";
import { useUser } from "../../components/UserContext";

export const CrmManageOverview: React.FC<{ type: CrmManageType }> = ({
  type,
}) => {
  const { axios, updateContextUser, user } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: allTypes } = useData("CRM_TYPES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allActions } = useData("CRM_ACTIONS_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * TableRows containing all crm manage entries
   */
  const rows: TableRow[] = useMemo((): TableRow[] => {
    if (type === CrmManageType.ACTION)
      return convertCrmManageEntriesIntoTableEntries(allActions, navigate);
    else return convertCrmManageEntriesIntoTableEntries(allTypes, navigate);
  }, [allActions, allTypes, navigate, type]);

  return (
    <>
      <TopBar title={t(`pages.crmManage.overview.topBarHeadline_${type}`)}>
        <PlusIcon
          title={t("general.icons.add")}
          onClick={() => navigate("edit")}
        />
      </TopBar>
      <Box>
        <Table
          tableConfig={user.tableConfig?.get(TableKey.CRM_MANAGE_OVERVIEW)}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.CRM_MANAGE_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          rows={rows}
          header={
            t("pages.crmManage.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
