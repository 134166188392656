import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Receipt,
  InvoiceState,
  getAllInvoicesByStates,
  useData,
  updateSchambeckUserTableConfig,
} from "shared";
import { useUser } from "../../components/UserContext";
import { convertInvoicesIntoAccountingTableEntries } from "../../utils/invoice/Invoice.utils";

export const AccountingOverview: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { axios, user, updateContextUser } = useUser();
  const [invoices, setInvoices] = useState<Receipt[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");

  const { data: loadedOrders } = useData("ORDER_ALL", {
    config: { fallbackData: [] },
  });

  const { data: loadedCustomers } = useData("CUSTOMER_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  const { data: loadedProjects } = useData("PROJECT_ALL", {
    config: { fallbackData: [] },
  });

  const { data: loadedLocations } = useData("CUSTOMER_LOCATION_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * useEffect to load all invoices in the state PENDING
   */
  useEffect(() => {
    getAllInvoicesByStates(axios, [InvoiceState.PENDING]).then(
      (loadedInvoices) => setInvoices(loadedInvoices)
    );
  }, [axios]);

  /**
   * Contains the tableRows with the converted invoices
   */
  const invoiceRows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertInvoicesIntoAccountingTableEntries(
        invoices,
        loadedOrders,
        loadedCustomers,
        navigate,
        loadedProjects,
        loadedLocations
      ),
    [
      invoices,
      loadedOrders,
      loadedCustomers,
      navigate,
      loadedProjects,
      loadedLocations,
    ]
  );

  return (
    <>
      <TopBar
        title={t("pages.accounting.overview.topBarHeadline")}
        onSearch={setFilterValue}
      />
      <Box>
        <Table
          tableConfig={user.tableConfig?.get(TableKey.ACCOUNTING_OVERVIEW)}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.ACCOUNTING_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          filterValue={filterValue}
          rows={invoiceRows}
          header={
            t("pages.accounting.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
