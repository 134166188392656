import { Box, Button, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  addOrRemove,
  Order,
  ProjectState,
  updateSchambeckUserTableConfig,
  useData,
} from "shared";
import { getProjectDescriptionPdf } from "shared/src/project/Project.axios";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { useUser } from "../../components/UserContext";
import { downloadFile } from "../../utils/files/Files.utils";
import { convertProjectsIntoTableEntries } from "../../utils/project/Project.utils";

export const ProjectOverview: React.FC = () => {
  const { axios, user, updateContextUser } = useUser();
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState<string>("");
  const [selectedProjectStates, setSelectedProjectStates] = useState<
    ProjectState[]
  >([]);
  const [selectedBusinessAreas, setSelectedBusinessAreas] = useState<{
    ids: string[];
    name: string;
  }>({ ids: [], name: "" });
  const { data: loadedProjects } = useData("PROJECT_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomers } = useData("CUSTOMER_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerLocations } = useData("CUSTOMER_LOCATION_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerUsers } = useData("CUSTOMER_USERS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedOrders } = useData("ORDER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedBusinessAreas } = useData("BUSINESSAREA_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  const navigate = useNavigate();

  /**
   * Helper method to download a preojectDescription
   */
  const downloadDescription = useCallback((projectId: string): void => {
    getProjectDescriptionPdf(axios, projectId).then(
      (loadedFile) =>
        loadedFile &&
        downloadFile(loadedFile, t("pages.project.overview.descriptionFileNae"))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Holds the options for the business areas
   * multiple areas with the same name are getting grouped
   */
  const businessAreaOptions: { ids: string[]; name: string }[] = useMemo((): {
    ids: string[];
    name: string;
  }[] => {
    let options: { ids: string[]; name: string }[] = [];
    loadedBusinessAreas.map((area) => {
      const existingOption: { ids: string[]; name: string } | undefined =
        options.find(
          (option) =>
            option.name.toLocaleLowerCase() === area.name.toLocaleLowerCase()
        );

      if (existingOption) {
        existingOption.ids.push(area.id);
        options = options.filter(
          (option) => option.name.toLowerCase() !== area.name.toLowerCase()
        );
        options.push({
          ids: [...existingOption.ids, area.id],
          name: area.name,
        });
      } else options.push({ ids: [area.id], name: area.name });
    });
    return options;
  }, [loadedBusinessAreas]);

  //Hook to convert projects into tableRows after all projects and customers are loaded
  const rows: TableRow[] = useMemo((): TableRow[] => {
    if (
      selectedBusinessAreas.ids.length === 0 &&
      selectedProjectStates.length === 0
    )
      return convertProjectsIntoTableEntries(
        loadedProjects,
        loadedCustomers,
        loadedCustomerLocations,
        loadedCustomerUsers,
        loadedOrders,
        navigate,
        downloadDescription
      );

    let filteredProjects = [...loadedProjects];
    if (selectedProjectStates.length > 0) {
      filteredProjects = loadedProjects.filter((project) =>
        selectedProjectStates.includes(project.projectState)
      );
    }
    if (selectedBusinessAreas.ids.length > 0) {
      filteredProjects = filteredProjects.filter((project) => {
        const order: Order | undefined = loadedOrders.find(
          (order) => order.id === project.orderId
        );
        if (!order) return false;
        else return selectedBusinessAreas.ids.includes(order.businessAreaId);
      });
    }
    return convertProjectsIntoTableEntries(
      filteredProjects,
      loadedCustomers,
      loadedCustomerLocations,
      loadedCustomerUsers,
      loadedOrders,
      navigate,
      downloadDescription
    );
  }, [
    downloadDescription,
    loadedCustomerLocations,
    loadedCustomerUsers,
    loadedCustomers,
    loadedOrders,
    loadedProjects,
    navigate,
    selectedBusinessAreas,
    selectedProjectStates,
  ]);
  return (
    <>
      <TopBar
        title={t("pages.project.overview.topBarHeadline")}
        onSearch={setFilterValue}
      >
        <PlusIcon
          title={t("general.icons.add")}
          onClick={() => navigate("/project/create")}
        />
      </TopBar>
      <Box>
        <div className="project-overview__filter-wrapper">
          <p>{t("pages.project.overview.state")}</p>
          <div className="three-columns">
            {Object.values(ProjectState).map((state) => (
              <Button
                value={t(`pages.project.overview.projectState.${state}`)}
                active={selectedProjectStates.includes(state)}
                onClick={() =>
                  setSelectedProjectStates(
                    addOrRemove(selectedProjectStates, state)
                  )
                }
              />
            ))}
          </div>
          <p>{t("pages.project.overview.businessArea")}</p>
          <div className="three-columns">
            {businessAreaOptions.map((area) => (
              <Button
                value={area.name}
                active={
                  selectedBusinessAreas.name.toLowerCase() ===
                  area.name.toLowerCase()
                }
                onClick={() =>
                  setSelectedBusinessAreas(
                    selectedBusinessAreas.name === area.name
                      ? { ids: [], name: "" }
                      : { ids: area.ids, name: area.name }
                  )
                }
              />
            ))}
          </div>
        </div>
      </Box>
      <Box>
        <Table
          tableConfig={user.tableConfig?.get(TableKey.PROJECT_OVERVIEW)}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.PROJECT_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.project.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
