import { Box, Table, TableRow, TopBar } from "@sam/components";
import { useTranslation } from "react-i18next";
import { updateSchambeckUserTableConfig, useData } from "shared";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";
import { useMemo } from "react";
import { convertQualificationsIntoTableRows } from "../../utils/workQualification/WorkQualification.utils";
import { useNavigate } from "react-router-dom";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import { useUser } from "../../components/UserContext";

export const WorkQualificationOverview: React.FC = () => {
  const { user, updateContextUser, axios } = useUser();
  const { data: loadedQualifications } = useData("QUALIFICATIONS_ALL", {
    config: { fallbackData: [] },
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const rows: TableRow[] = useMemo(
    () => convertQualificationsIntoTableRows(loadedQualifications, navigate),
    [loadedQualifications, navigate]
  );

  return (
    <>
      <TopBar title={t("pages.qualifications.overview.topBarHeadline")}>
        <AddIcon
          title={t("general.icons.add")}
          onClick={() => navigate("/qualifications/edit")}
        />
      </TopBar>
      <Box>
        <Table
          tableConfig={user.tableConfig?.get(
            TableKey.WORK_QUALIFICATION_OVERVIEW
          )}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.BRANCHE_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          rows={rows}
          header={
            t("pages.qualifications.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
