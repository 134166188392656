import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import { AxiosResponse } from "axios";
import { AccountType, EmploymentChange, YearlyTimeOverview } from "shared";
import { Language } from "shared/src/Language/Language.types";
import { ShiftType } from "shared/src/customerLocation/CustomerLocation.types";

// regex to check for iso date YYYY-MM-DDThh:mm:ss.mmm+hh:mm and YYYY-MM-DDfrom database
const ISO_DATE_REGEX =
  /^(?:\d{4}-\d{2}-\d{2}|^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,9})?Z?)$/;
/**
 * Helper to check if given value is an iso date string
 *
 * @param value any form of value to check
 * @returns true if value is iso date string
 */
export const isIsoDateString = (value: unknown): value is string => {
  if (typeof value !== "string") return false;
  return ISO_DATE_REGEX.test(value);
};

/**
 * Axios response interceptor to convert data from server to client format.
 *
 * @param response the response from the server
 * @returns converted response
 */
export const handleResponseConversion = <
  T extends AxiosResponse<unknown, unknown>
>(
  response: T
): T => {
  // convert data
  response.data = handleResponseData(response.data);

  return response;
};

/**
 * Convert data to a server compatible format (data should go to server).
 *
 * @param data the data should go to the server
 * @returns converted data
 */
export const handleResponseData = (data: unknown): unknown => {
  // check date string
  if (typeof data === "string" && isIsoDateString(data)) {
    return new Date(data);
  }

  //this is the mailTemplate
  if (
    typeof data === "object" &&
    data !== null &&
    "mailContent" in data &&
    "subject" in data
  ) {
    data["subject"] = new Map<string, string>(
      Object.entries(data["subject"] as Record<Language, string>)
    );
  }
  //detects branches
  if (
    typeof data === "object" &&
    data !== null &&
    "name" in data &&
    "translations" in data
  ) {
    data["translations"] = new Map<string, string>(
      Object.entries(data["translations"] as Record<Language, string>)
    );
  }
  if (
    typeof data === "object" &&
    data !== null &&
    "customerFeedback" in data &&
    "shifts" in data &&
    "lastInvoiced" in data
  ) {
    //this is the order object
    data["customerFeedback"] = new Map<string, number>(
      Object.entries(data["customerFeedback"] as Record<string, number>)
    );
  }
  //detects mailTemplates
  if (
    typeof data === "object" &&
    data !== null &&
    "type" in data &&
    "mailContent" in data
  ) {
    data["mailContent"] = new Map<string, string>(
      Object.entries(data["mailContent"] as Record<Language, string>)
    );
  }

  if (
    typeof data === "object" &&
    data !== null &&
    "role" in data &&
    "customRights" in data
  ) {
    data["customRights"] = new Map<string, number>(
      Object.entries(data["customRights"] as Record<string, number>)
    );
    return data;
  }

  if (
    typeof data === "object" &&
    data !== null &&
    "personalNumber" in data &&
    "workQualifications" in data &&
    "customerTimes" in data &&
    "vacationDaysLeft" in data
  ) {
    data["customerTimes"] = new Map<string, number>(
      Object.entries(data["customerTimes"] as Record<string, number>)
    );
    data["vacationDaysLeft"] = new Map<number, number>(
      Object.entries(data["vacationDaysLeft"] as Record<string, number>).map(
        ([year, value]) => [parseInt(year), value]
      )
    );
  }
  if (
    typeof data === "object" &&
    data !== null &&
    "io" in data &&
    "articleNumber" in data &&
    "nio" in data &&
    "foundErrorPatterns" in data
  ) {
    data["foundErrorPatterns"] = new Map<string, number>(
      Object.entries(data["foundErrorPatterns"] as Record<string, number>)
    );
  }
  if (data === null || data === undefined || typeof data !== "object")
    // check type which can not be converted
    return data;

  if (typeof data === "object" && "yearlyTimes" in data) {
    data["yearlyTimes"] = new Map<number, YearlyTimeOverview>(
      Object.entries(
        data["yearlyTimes"] as Record<string, YearlyTimeOverview>
      ).map((entry) => [parseInt(entry[0]), entry[1]])
    );
  }
  if (typeof data === "object" && "employmentHistory" in data) {
    data["employmentHistory"] = new Map<Date, EmploymentChange>(
      Object.entries(
        data["employmentHistory"] as Record<string, EmploymentChange>
      ).map((entry) => [new Date(entry[0]), entry[1] as EmploymentChange])
    );
  }

  if (
    typeof data === "object" &&
    data !== null &&
    "personalNumber" in data &&
    "tableConfig" in data
  ) {
    data["tableConfig"] = new Map<TableKey, TableHeader[]>(
      Object.entries(data["tableConfig"] as Record<string, TableHeader[]>).map(
        (entry) => [entry[0] as TableKey, entry[1] as TableHeader[]]
      )
    );
  }

  // this is the inventoryitem object
  if (typeof data === "object" && "userHistory" in data) {
    data["userHistory"] = new Map<Date, string>(
      Object.entries(data["userHistory"] as Record<string, string>).map(
        (entry) => [new Date(entry[0]), entry[1] as string]
      )
    );
  }
  // this is the customerFeedbackEntry object
  if (
    typeof data === "object" &&
    data != null &&
    "names" in data &&
    "name" in data &&
    "id" in data
  ) {
    data["names"] = new Map<string, string>(
      Object.entries(data["names"] as Record<Language, string>)
    );
  }
  // this is applicable for a lot of models that are containing a title and
  // translated names like userLanguage, crmType oder crmAction
  if (
    typeof data === "object" &&
    data != null &&
    "names" in data &&
    "title" in data
  ) {
    data["names"] = new Map<string, string>(
      Object.entries(data["names"] as Record<Language, string>)
    );
  }
  if (
    typeof data === "object" &&
    data !== null &&
    "officeType" in data &&
    "generalMailConfiguration" in data &&
    "additionalInfos" in data
  ) {
    data["additionalInfos"] = new Map<string, string>(
      Object.entries(data["additionalInfos"] as Record<string, string>)
    );
  }
  if (
    typeof data === "object" &&
    data !== null &&
    "price" in data &&
    "unit" in data &&
    "translations" in data
  ) {
    data["translations"] = new Map<string, string>(
      Object.entries(data["translations"] as Record<string, string>)
    );
  }
  if (
    typeof data === "object" &&
    data !== null &&
    "officeDistances" in data &&
    "officeDrivingTimes" in data &&
    "officeDrivingFlatRate" in data &&
    "contacts" in data
  ) {
    data["officeDistances"] = new Map<string, number>(
      Object.entries(data["officeDistances"] as Record<string, number>)
    );
    data["officeDrivingTimes"] = new Map<string, number>(
      Object.entries(data["officeDrivingTimes"] as Record<string, number>)
    );
    data["officeDrivingFlatRate"] = new Map<string, number>(
      Object.entries(data["officeDrivingFlatRate"] as Record<string, number>)
    );
  }
  if (
    typeof data === "object" &&
    data !== null &&
    "companyAccountTypes" in data
  ) {
    data["companyAccountTypes"] = new Map<string, AccountType>(
      Object.entries(data["companyAccountTypes"] as Record<string, AccountType>)
    );
  }
  if (typeof data === "object" && data !== null && "officePrices" in data) {
    // Convert each inner object to a Map<string, number>
    const outerMap = new Map<string, Map<string, number>>(
      Object.entries(
        data["officePrices"] as Record<string, Map<string, number>>
      ).map(([outerKey, innerObject]) => [
        outerKey,
        new Map<string, number>(Object.entries(innerObject)),
      ])
    );
    data["officePrices"] = outerMap;
  }
  if (
    typeof data === "object" &&
    "level" in data &&
    "documentInfoTexts" in data &&
    "title" in data
  ) {
    const map: Map<Language, string> = new Map<Language, string>(
      Object.entries(data["documentInfoTexts"] as Record<Language, string>).map(
        (entry) => [entry[0] as Language, entry[1]]
      )
    );
    data["documentInfoTexts"] = map;
  }
  // maps the Map of scheduled users and cars for the project schedule into the correct format
  if (
    typeof data === "object" &&
    "scheduledCars" in data &&
    "scheduledUsers" in data
  ) {
    data["scheduledCars"] = new Map<ShiftType, string[]>(
      Object.entries(data["scheduledCars"] as Record<ShiftType, string[]>).map(
        (entry) => [entry[0] as ShiftType, entry[1]]
      )
    );
    data["scheduledUsers"] = new Map<ShiftType, string[]>(
      Object.entries(data["scheduledUsers"] as Record<ShiftType, string[]>).map(
        (entry) => [entry[0] as ShiftType, entry[1]]
      )
    );
  }
  if (Array.isArray(data)) {
    // checking all object types
    // arrays mapping
    return data.map((value) => handleResponseData(value));
  } else if (data.constructor === Object.prototype.constructor) {
    // plain object
    // iterate over all keys and convert them
    for (const [key, value] of Object.entries(data)) {
      (data as Record<string, unknown>)[key] = handleResponseData(value);
    }
    return data;
  } else {
    // something else (unknown)
    return data;
  }
};
