import { Box, Dropdown, Table, TopBar } from "@sam/components";
import {
  TableHeader,
  TableKey,
  TableRow,
} from "@sam/components/src/Table/Table.types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  InventoryItem,
  InventoryType,
  convertInventoryItemsIntoTableEntries,
  generateDropdownOptionsForInventoryTypes,
  getAllActiveInventoryItemsByType,
  getAllInventoryItemsByType,
  getInventoryOverviewCsv,
  updateSchambeckUserTableConfig,
  useData,
} from "shared";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import { ReactComponent as DisabledIcon } from "../../assets/disabled.svg";
import { useUser } from "../../components/UserContext";
import { downloadFile } from "../../utils/files/Files.utils";

const InventoryOverview: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { axios, updateContextUser, user } = useUser();
  const { data: allOffices } = useData("OFFICES_ALL_ACTIVE", {
    config: { fallbackData: [], revalidateOnMount: true },
  });
  const location = useLocation<{ type?: InventoryType }>();
  const [selectedInventoryType, setSelectedInventoryType] =
    useState<InventoryType>(location.state?.type || InventoryType.HANDY);
  const [loadedInventory, setLoadedInventory] = useState<InventoryItem[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");
  const [includeDeactivated, toggleIncludeDeactivated] =
    useState<boolean>(false);

  // loads the inventory items for the given type
  useEffect(() => {
    if (!axios) return;
    if (includeDeactivated)
      getAllInventoryItemsByType(selectedInventoryType, axios).then(
        setLoadedInventory
      );
    else
      getAllActiveInventoryItemsByType(selectedInventoryType, axios).then(
        setLoadedInventory
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInventoryType, includeDeactivated]);

  /**
   * Holds the single row entries for every inventory item
   */
  const overviewRows: TableRow[] = useMemo((): TableRow[] => {
    const filteredEntries = includeDeactivated
      ? loadedInventory
      : loadedInventory.filter((item) => !item.disabled);
    return convertInventoryItemsIntoTableEntries(
      filteredEntries,
      navigate,
      selectedInventoryType,
      allOffices
    );
  }, [
    allOffices,
    includeDeactivated,
    loadedInventory,
    navigate,
    selectedInventoryType,
  ]);

  /**
   * Helper method to crete and download the overview csv
   */
  const handleCsvDownload = (): void => {
    getInventoryOverviewCsv(axios).then(
      (generatedFile) =>
        generatedFile &&
        downloadFile(generatedFile, t("pages.inventory.overviewFilename"))
    );
  };

  return (
    <>
      <TopBar title={t("pages.inventory.title")} onSearch={setFilterValue}>
        <PlusIcon
          title={t("general.icons.add")}
          onClick={() =>
            navigate("new", { state: { type: selectedInventoryType } })
          }
        />
        <DisabledIcon
          style={{ color: includeDeactivated ? "#bc2e46" : "" }}
          title={t("general.icons.showInactive")}
          onClick={() => toggleIncludeDeactivated(!includeDeactivated)}
        />
        <DownloadIcon width={20} height={20} onClick={handleCsvDownload} />
      </TopBar>
      <Box>
        <Dropdown
          options={generateDropdownOptionsForInventoryTypes()}
          onChange={(selectedValue) =>
            setSelectedInventoryType(selectedValue as InventoryType)
          }
          selectedOption={selectedInventoryType}
        />
        <Table
          tableConfig={user.tableConfig?.get(TableKey.INVENTORY_OVERVIEW)}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.INVENTORY_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          filterValue={filterValue}
          header={
            t("pages.inventory.overviewTable", {
              returnObjects: true,
            }) as TableHeader[]
          }
          rows={overviewRows}
        />
      </Box>
    </>
  );
};

export default InventoryOverview;
