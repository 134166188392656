import {
  Box,
  Button,
  Checkbox,
  Dropdown,
  Input,
  TopBar,
} from "@sam/components";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  County,
  generateCountryCodeOptions,
  generateNotification,
  useData,
} from "shared";
import {
  createNewCustomerLocation,
  updateCustomerLocation,
} from "shared/src/customerLocation/CustomerLocation.axios";
import { CustomerLocation } from "shared/src/customerLocation/CustomerLocation.types";
import { NotificationType } from "shared/src/notification/notification.types";
import { ReactComponent as ArrowLeft } from "../../assets/chevron-left.svg";
import { ReactComponent as SaveIcon } from "../../assets/save.svg";
import { useUser } from "../../components/UserContext";
import { generateEmptyCustomerLocation } from "../../utils/customerLocation/CustomerLocation.utils";
import { generateDropdownOptionsForCounty } from "../../utils/timeTracking/TimeTracking.utils";

export const CreateOrEditCustomerLocation: React.FC = () => {
  const { axios, updateFootnoteConfig } = useUser();
  const location = useLocation<{ location?: CustomerLocation }>();
  const [locationToEdit, setLocationToEdit] = useState<CustomerLocation>(
    location.state?.location || generateEmptyCustomerLocation()
  );

  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  const { data: customers } = useData("CUSTOMER_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: allOffices } = useData("OFFICES_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  /**
   * Hook to update the footNote containg the create and update data
   */
  useEffect(() => {
    updateFootnoteConfig(locationToEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationToEdit.createDate, updateFootnoteConfig]);
  /**
   * Submit handler to create or update a customerLocation depending on wether the id is existing or not
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;
    locationToEdit.id
      ? updateCustomerLocation(axios, locationToEdit).then(
          (updatedCustomerLocation) => {
            if (redirect && updatedCustomerLocation) navigate(-1);
            else if (updatedCustomerLocation) {
              setLocationToEdit(updatedCustomerLocation);
            }
            generateNotification({
              type: NotificationType.SUCCESS,
              value: t("general.notification.success.saveSuccessfull"),
            });
          }
        )
      : createNewCustomerLocation(axios, locationToEdit).then(
          (createdCustomerLocation) => {
            if (redirect && createdCustomerLocation) navigate(-1);
            else if (createdCustomerLocation) {
              setLocationToEdit(createdCustomerLocation);
              generateNotification({
                type: NotificationType.SUCCESS,
                value: t("general.notification.success.saveSuccessfull"),
              });
            }
          }
        );
  };

  /**
   * Helper to quickly update a single office distance
   * @param newValue The user entered value
   * @param officeId The id of the office to update
   * @param key The defined key of the value to change
   */
  const updateOfficeDistance = (
    newValue: number,
    officeId: string,
    key: "officeDistances" | "officeDrivingFlatRate" | "officeDrivingTimes"
  ): void => {
    const locationCopy: CustomerLocation = { ...locationToEdit };
    if (!locationCopy[key]) locationCopy[key] = new Map<string, number>();
    locationCopy[key].set(officeId, newValue);
    setLocationToEdit({ ...locationCopy });
  };

  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        title={t("pages.customerLocation.createOrEdit.topbarHeadlineCreate")}
        onBackClick={() => navigate(-1)}
      >
        <Button
          value={
            <div className="save-and-navigate__wrapper">
              <ArrowLeft className="save-and-navigate__arrow" />
              <SaveIcon className="save-and-navigate__save" />
            </div>
          }
          type="button"
          onClick={() => handleSubmit(true)}
        />
        <Button
          value={<SaveIcon className="save-icon" />}
          type="button"
          onClick={() => handleSubmit(false)}
        />
      </TopBar>
      <Box title={t("pages.customerLocation.createOrEdit.locationInfo")}>
        <div className="three-columns">
          <Dropdown
            onChange={(customerId) =>
              setLocationToEdit({ ...locationToEdit, customerId })
            }
            options={customers.map((customer) => ({
              label: `${customer.name}, ${customer.contact.city}`,
              value: customer.id,
            }))}
            selectedOption={locationToEdit.customerId}
            label={t("pages.customerLocation.createOrEdit.customer")}
          />
          <Input
            type="text"
            value={locationToEdit.name}
            onChange={(name) => setLocationToEdit({ ...locationToEdit, name })}
            label={t("pages.customerLocation.createOrEdit.name")}
          />
          <Checkbox
            isChecked={!locationToEdit.disabled}
            onCheck={(disabled) =>
              setLocationToEdit({ ...locationToEdit, disabled: !disabled })
            }
            label={t("pages.customerLocation.createOrEdit.active")}
          />
          <Input
            type="text"
            value={locationToEdit.annotation}
            onChange={(annotation) =>
              setLocationToEdit({ ...locationToEdit, annotation })
            }
            label={t("pages.customerLocation.createOrEdit.annotation")}
          />
          <Input
            type="text"
            value={locationToEdit.contact.phone}
            label={t("pages.customerLocation.createOrEdit.phone")}
            onChange={(phone) =>
              setLocationToEdit({
                ...locationToEdit,
                contact: { ...locationToEdit.contact, phone },
              })
            }
          />
          <Input
            type="text"
            value={locationToEdit.contact.mobile}
            label={t("pages.customerLocation.createOrEdit.mobile")}
            onChange={(mobile) =>
              setLocationToEdit({
                ...locationToEdit,
                contact: { ...locationToEdit.contact, mobile },
              })
            }
          />
          <Input
            type="email"
            value={locationToEdit.contact.mail}
            label={t("pages.customerLocation.createOrEdit.mail")}
            onChange={(mail) =>
              setLocationToEdit({
                ...locationToEdit,
                contact: { ...locationToEdit.contact, mail },
              })
            }
          />
          <Input
            type="text"
            value={locationToEdit.contact.phonePrivate}
            label={t("pages.customerLocation.createOrEdit.phonePrivate")}
            onChange={(phonePrivate) =>
              setLocationToEdit({
                ...locationToEdit,
                contact: { ...locationToEdit.contact, phonePrivate },
              })
            }
          />
          <Input
            type="text"
            value={locationToEdit.contact.mobilePrivate}
            label={t("pages.customerLocation.createOrEdit.mobilePrivate")}
            onChange={(mobilePrivate) =>
              setLocationToEdit({
                ...locationToEdit,
                contact: { ...locationToEdit.contact, mobilePrivate },
              })
            }
          />
          <Input
            type="email"
            value={locationToEdit.contact.mailPrivate}
            label={t("pages.customerLocation.createOrEdit.mailPrivate")}
            onChange={(mailPrivate) =>
              setLocationToEdit({
                ...locationToEdit,
                contact: { ...locationToEdit.contact, mailPrivate },
              })
            }
          />
          <Input
            value={locationToEdit.contact.fax}
            type="text"
            label={t("pages.customerLocation.createOrEdit.fax")}
            onChange={(fax) =>
              setLocationToEdit({
                ...locationToEdit,
                contact: { ...locationToEdit.contact, fax },
              })
            }
          />
          <Dropdown
            label={t("pages.customerLocation.createOrEdit.country")}
            options={generateCountryCodeOptions()}
            selectedOption={locationToEdit?.contact?.countryCode}
            onChange={(countryCode) =>
              setLocationToEdit({
                ...locationToEdit,
                contact: {
                  ...locationToEdit.contact,
                  countryCode,
                },
              })
            }
          />
        </div>
        <div className="three-columns">
          {locationToEdit?.contact?.countryCode?.toUpperCase() === "DE" && (
            <Dropdown
              label={t("pages.customerLocation.createOrEdit.county")}
              options={generateDropdownOptionsForCounty()}
              onChange={(county) =>
                setLocationToEdit({
                  ...locationToEdit,
                  contact: {
                    ...locationToEdit.contact,
                    county: county as County,
                  },
                })
              }
              selectedOption={locationToEdit.contact.county}
            />
          )}
          <Input
            type="text"
            value={locationToEdit.contact.street}
            label={t("pages.customerLocation.createOrEdit.street")}
            onChange={(street) =>
              setLocationToEdit({
                ...locationToEdit,
                contact: { ...locationToEdit.contact, street },
              })
            }
          />
          <Input
            type="text"
            value={locationToEdit.contact.streetNumber}
            label={t("pages.customerLocation.createOrEdit.streetNumber")}
            onChange={(streetNumber) => {
              if (streetNumber)
                setLocationToEdit({
                  ...locationToEdit,
                  contact: {
                    ...locationToEdit.contact,
                    streetNumber: String(streetNumber),
                  },
                });
            }}
          />
          <Input
            type="text"
            value={locationToEdit.contact.zip}
            label={t("pages.customerLocation.createOrEdit.zip")}
            onChange={(zip) =>
              setLocationToEdit({
                ...locationToEdit,
                contact: { ...locationToEdit.contact, zip },
              })
            }
          />
          <Input
            type="text"
            value={locationToEdit.contact.city}
            onChange={(city) =>
              setLocationToEdit({
                ...locationToEdit,
                contact: { ...locationToEdit.contact, city },
              })
            }
            label={t("pages.customerLocation.createOrEdit.city")}
          />
        </div>
      </Box>
      <Box title={t("pages.customerLocation.createOrEdit.distanceTitle")}>
        {allOffices.map((office) => (
          <p>
            <strong>{office.name}</strong>
            <div className="three-columns">
              <Input
                key={office.id}
                type="number"
                label={t("pages.customerLocation.createOrEdit.distanceOffice")}
                onChangeNumber={(newValue) =>
                  updateOfficeDistance(newValue, office.id, "officeDistances")
                }
                value={locationToEdit.officeDistances?.get(office.id) || 0}
              />
              <Input
                key={office.id}
                type="number"
                label={t("pages.customerLocation.createOrEdit.timeOffice")}
                onChangeNumber={(newValue) =>
                  updateOfficeDistance(
                    newValue,
                    office.id,
                    "officeDrivingTimes"
                  )
                }
                value={locationToEdit.officeDrivingTimes?.get(office.id) || 0}
              />
              <Input
                key={office.id}
                type="number"
                label={t("pages.customerLocation.createOrEdit.flatRateOffice")}
                onChangeNumber={(newValue) =>
                  updateOfficeDistance(
                    newValue,
                    office.id,
                    "officeDrivingFlatRate"
                  )
                }
                value={
                  locationToEdit.officeDrivingFlatRate?.get(office.id) || 0
                }
              />
            </div>
          </p>
        ))}
      </Box>
    </form>
  );
};
