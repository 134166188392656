/**
 * interface for timeTracking object with different keys for start/end/nighttime/sunday etc.
 */
export interface TimeTracking {
  id: string;
  createDate: Date;
  userId: string;
  startTime: number;
  startDate: Date;
  endTime: number;
  endDate?: Date;
  drivingTime?: number;
  breakTime: number;
  totalWorkTime: number;
  comment: string;
  holidayTime: number;
  absenceReasonId?: string;
}

/**
 * interface for workTime to save including timeTracking object and aditional information
 */
export interface WorkTime {
  id: string;
  createDate: Date;
  timeTracking: TimeTracking;
  protocolId: string;
  includingApproach: boolean;
  includingDeparture: boolean;
  clientBreakTime: number;
  clientDrivingTime: number;
}

/**
 * Defines the days of the week. According to the java.time.DayOfWeek the days
 * are 1 based
 */
export enum DayOfWeek {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}
