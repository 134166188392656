import { Box, Table, TableRow, TopBar } from "@sam/components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { updateSchambeckUserTableConfig, useData } from "shared";
import { convertChangeHistoryIntoTableEntries } from "../../utils/ChangeHistory/ChangeHistory.util";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import { useUser } from "../../components/UserContext";

export const ChangesOverview: React.FC = () => {
  const { t } = useTranslation();
  const { user, updateContextUser, axios } = useUser();
  const { data: loadedHistory } = useData("CHANGE_HISTORY_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * Rows to be displayed containing one row for each changeHistory object
   */
  const entryRows: TableRow[] = useMemo(
    () => convertChangeHistoryIntoTableEntries(loadedHistory),
    [loadedHistory]
  );
  return (
    <>
      <TopBar title={t("pages.changes.overview.topBarHeadline")} />
      <Box>
        <Table
          tableConfig={user.tableConfig?.get(TableKey.CHANGE_HISTORY)}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.CHANGE_HISTORY,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          rows={entryRows}
          header={
            t("pages.changes.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
          pagination
        />
      </Box>
    </>
  );
};
