import React, { useEffect, useState } from "react";
import { convertNumberOfMinutesToTime } from "../../../client/src/utils/timeTracking/TimeTracking.utils";
import "./Input.scss";
import { InputProps } from "./Input.types";

export const Input: React.FC<InputProps> = ({
  value,
  label,
  type,
  errorMessage,
  onBlur,
  onChange,
  onChangeDate,
  onChangeNumber,
  onChangeTime,
  required = false,
  disabled = false,
  minValue,
  maxValue,
  maxWidth,
  minLength,
  maxLength,
  form,
  labelBold = true,
  withoutPadding = false,
  onChangeFile,
}) => {
  const [localValue, setLocalValue] = useState<
    string | number | Date | undefined
  >(value);

  //Needed because of different behaviour of firefox and chrome
  const [localTime, setLocalTime] = useState<string | undefined>(
    convertNumberOfMinutesToTime(Number(value), true)
  );

  // this is needed so that a controlled value change overrides the local state
  useEffect(() => {
    if (value !== localValue) setLocalValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  /**
   * Helper to handle the user input in such a way, that the input
   * renders only once but the state keeps updated
   *
   * @param evt The input tag event
   */
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(evt.target.value);
      setLocalValue(evt.target.value);
    } else if (onChangeNumber) {
      onChangeNumber(evt.target.valueAsNumber);
      setLocalValue(evt.target.valueAsNumber);
    } else if (onChangeDate && evt.target.valueAsDate) {
      onChangeDate(evt.target.valueAsDate || undefined);
      setLocalValue(evt.target.valueAsDate);
    } else if (onChangeTime) {
      const times: string[] = evt.target.value.split(":");
      onChangeTime(Number(times[0]) * 60 + Number(times[1]));
      setLocalTime(evt.target.value);
    } else if (onChangeFile && evt.target.files && evt.target.files[0])
      onChangeFile(evt.target.files[0]);
  };
  return (
    <div
      data-testid="InputComponent"
      className="input__wrapper"
      style={{
        maxWidth: maxWidth ? maxWidth : "",
      }}
      key={`input-component-${label}${type}`}
    >
      {label && (
        <label
          htmlFor={`input-${label}`}
          className={["input__label", labelBold ? " bold" : ""].join(" ")}
        >
          {label}
        </label>
      )}
      <input
        form={form}
        minLength={minLength}
        maxLength={maxLength}
        style={{
          maxWidth: maxWidth ? maxWidth : "",
          padding: withoutPadding ? 0 : "",
        }}
        className="input__input"
        min={minValue}
        max={maxValue}
        disabled={disabled}
        id={label ? `input-${label}` : ""}
        type={type}
        required={required}
        accept={type === "file" ? ".pdf" : undefined}
        value={
          type === "date"
            ? localValue
              ? new Date(localValue).toISOString().split("T")[0]
              : undefined
            : type === "time"
            ? localTime
            : typeof localValue === "number"
            ? localValue
            : (localValue as string)
        }
        //needed because the input doesnt update on undefined values for time
        onFocus={() => {
          if (type !== "time" || !!localTime) return;
          setLocalTime(
            convertNumberOfMinutesToTime(
              new Date().getHours() * 60 + new Date().getMinutes(),
              true
            )
          );
        }}
        onChange={handleChange}
        onBlur={(event) => {
          if (!onBlur) return;
          onBlur(event.target.value);
        }}
        key={`input-component-input${label + type}`}
      />
      {errorMessage && <p className="input__error">{errorMessage}</p>}
    </div>
  );
};
