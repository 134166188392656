import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getCarOverviewCsv,
  Right,
  updateSchambeckUserTableConfig,
  useData,
} from "shared";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";
import { ReactComponent as DisabledIcon } from "../../assets/disabled.svg";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import { useUser } from "../../components/UserContext";
import { convertCarIntoTableEntries } from "../../utils/car/Car.utils";
import { isUserAllowedToDo } from "../../utils/user/User.utils";
import { downloadFile } from "../../utils/files/Files.utils";

const CarOverview: React.FC = () => {
  const { user, axios, updateContextUser } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState<string>("");
  const [includeDeactivated, toggleIncludeDeactivated] =
    useState<boolean>(false);

  const { data: loadedCars } = useData("CARS_ALL", {
    config: { fallbackData: [], isPaused: () => !includeDeactivated },
  });
  const { data: loadedActiveCars } = useData("CARS_ALL_ACTIVE", {
    config: { fallbackData: [], isPaused: () => includeDeactivated },
  });
  const { data: loadedOffices } = useData("OFFICES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedSimpleUsers } = useData("SIMPLEUSERS_ALL", {
    config: { fallbackData: [] },
  });

  //Holds TableRows for cars
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertCarIntoTableEntries(
        user.right,
        includeDeactivated ? loadedCars : loadedActiveCars,
        navigate,
        loadedSimpleUsers,
        loadedOffices
      ),
    [
      includeDeactivated,
      loadedActiveCars,
      loadedCars,
      loadedOffices,
      loadedSimpleUsers,
      navigate,
      user.right,
    ]
  );

  /**
   * Helper method to crete and download the overview csv
   */
  const handleCsvDownload = (): void => {
    getCarOverviewCsv(axios).then(
      (generatedFile) =>
        generatedFile &&
        downloadFile(generatedFile, t("pages.carOverview.overviewFilename"))
    );
  };

  return (
    <>
      <TopBar title={t("pages.carOverview.headline")} onSearch={setFilterValue}>
        {isUserAllowedToDo(user.right, Right.CAR_CREATE) && (
          <>
            <AddIcon
              title={t("general.icons.add")}
              onClick={() => navigate("/cars/create")}
            />
            <DisabledIcon
              style={{ color: includeDeactivated ? "#bc2e46" : "" }}
              title={t("general.icons.showInactive")}
              onClick={() => toggleIncludeDeactivated(!includeDeactivated)}
            />
            <DownloadIcon onClick={handleCsvDownload} />
          </>
        )}
      </TopBar>
      <Box>
        <Table
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.carOverview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
          tableConfig={user.tableConfig?.get(TableKey.CAR_OVERVIEW)}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.CAR_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
        />
      </Box>
    </>
  );
};
export default CarOverview;
