import { Box, Button, Input, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Branche, generateNotification } from "shared";
import {
  createNewBranche,
  updateBranche,
} from "shared/src/branche/Branche.axios";
import { NotificationType } from "shared/src/notification/notification.types";
import { ReactComponent as ArrowLeft } from "../../assets/chevron-left.svg";
import { ReactComponent as SaveIcon } from "../../assets/save.svg";
import { useUser } from "../../components/UserContext";
import {
  generateEmptyBranche,
  generateLanguageRowsForBranche,
} from "../../utils/branche/Branche.utils";

export const BranchesEdit: React.FC = () => {
  const location = useLocation<{ branche: Branche | undefined }>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { axios } = useUser();
  const [brancheToEdit, setBrancheToEdit] = useState<Branche>(
    location.state?.branche ? location.state.branche : generateEmptyBranche()
  );
  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);
  /**
   * Helper method to update or create a branche on submit
   * @param redirect boolean to decide if the user should be redirected after save
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;
    location.state?.branche
      ? updateBranche(axios, brancheToEdit).then((updatedBranch) => {
          if (updatedBranch && redirect) navigate("/branche");
          else if (updatedBranch) {
            setBrancheToEdit(updatedBranch);
            generateNotification({
              value: t("general.notification.success.saveSuccessfull"),
              type: NotificationType.SUCCESS,
            });
          }
        })
      : createNewBranche(axios, brancheToEdit).then(() => navigate("/branche"));
  };
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      generateLanguageRowsForBranche(brancheToEdit, setBrancheToEdit),
    [brancheToEdit]
  );

  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={
          brancheToEdit.id
            ? t("pages.branches.edit.topBarHeadlineEdit")
            : t("pages.branches.edit.topBarHeadlineCreate")
        }
      >
        <Button
          value={
            <div className="save-and-navigate__wrapper">
              <ArrowLeft className="save-and-navigate__arrow" />
              <SaveIcon
                className="save-and-navigate__save"
                title={t("general.icons.save")}
              />
            </div>
          }
          type="button"
          onClick={() => handleSubmit(true)}
        />
        <Button
          value={<SaveIcon className="save-icon" />}
          type="button"
          onClick={() => handleSubmit(false)}
        />
      </TopBar>
      <Box>
        <Input
          type="text"
          label={t("pages.branches.edit.name")}
          onChange={(name) => setBrancheToEdit({ ...brancheToEdit, name })}
          value={brancheToEdit.name}
        />
      </Box>
      <Box>
        <Table
          rows={rows}
          header={
            t("pages.branches.edit.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </form>
  );
};
