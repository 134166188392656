import { Box, Button, Input, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ProjectProtocol,
  Right,
  createMultipleInvoicesForProjects,
  generateNotification,
  getAllProjectsToCreateInvoice,
  getAllProtocolsForMultipleProjects,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { Project } from "shared/src/project/Project.types";
import dayjs from "shared/src/tools/Dayjs";
import { useUser } from "../../components/UserContext";
import { downloadFile } from "../../utils/files/Files.utils";
import { convertProjectsIntoInvoiceTableEntries } from "../../utils/invoice/Invoice.utils";
import { useLockDate } from "../../utils/lockDate/useLockDate";
import { isUserAllowedToDo } from "../../utils/user/User.utils";

export const InvoiceCreate: React.FC = () => {
  const { axios, user } = useUser();
  const { t } = useTranslation();

  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [protocols, setProtocols] = useState<ProjectProtocol[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>();
  const [activeLockDate] = useLockDate(selectedCompany);

  const { data: loadedCustomers } = useData("CUSTOMER_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerLocations } = useData(
    "CUSTOMER_LOCATION_ALL_ACTIVE",
    {
      config: { fallbackData: [] },
    }
  );
  const { data: loadedOrders } = useData("ORDER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedOffers } = useData("OFFER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCompanies } = useData("COMPANY_ALL", {
    config: { fallbackData: [] },
  });

  //Hook to get all relevant protocols for the loaded projects after they are loaded
  useEffect(() => {
    projects.length > 0 &&
      getAllProtocolsForMultipleProjects(
        axios,
        selectedEndDate,
        projects.map((project) => project.id)
      ).then(setProtocols);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, selectedEndDate]);

  //Hook to load all Projects to create invoices for
  useEffect(() => {
    getAllProjectsToCreateInvoice(axios, selectedEndDate).then(setProjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEndDate]);

  /**
   * Helper method to add or remove a project from the selectedProjects
   */
  const handleSelectProject = useCallback(
    (projectId: string): void => {
      if (selectedProjects.includes(projectId))
        setSelectedProjects(
          selectedProjects.filter(
            (existingProject) => existingProject !== projectId
          )
        );
      else setSelectedProjects([...selectedProjects, projectId]);
    },
    [selectedProjects]
  );

  //TableRows to display the projects
  const rows: TableRow[] = useMemo((): TableRow[] => {
    let projectsToShow: Project[] = projects;
    if (selectedCompany) {
      const ordersToShow: string[] = loadedOrders
        .filter((order) => order.companyId === selectedCompany)
        .map((order) => order.id);
      projectsToShow = projectsToShow.filter((project) =>
        ordersToShow.includes(project.orderId)
      );
    }
    return convertProjectsIntoInvoiceTableEntries(
      projectsToShow,
      loadedOrders,
      loadedCustomers,
      loadedCustomerLocations,
      protocols,
      loadedOffers,
      handleSelectProject,
      selectedProjects
    );
  }, [
    projects,
    selectedCompany,
    loadedOrders,
    loadedCustomers,
    loadedCustomerLocations,
    protocols,
    loadedOffers,
    handleSelectProject,
    selectedProjects,
  ]);

  /**
   * Helper method to create invoices for the selected projects
   */
  const handleCreateInvoices = (): void => {
    if (selectedProjects.length <= 0) {
      generateNotification({
        type: NotificationType.ERROR,
        value: t("general.notification.error.noProjectsSelected"),
      });
    } else
      createMultipleInvoicesForProjects(axios, {
        projectIds: selectedProjects,
        endDate: selectedEndDate,
        userId: user.id,
      }).then((generatedInvoices) => {
        if (generatedInvoices) {
          downloadFile(generatedInvoices, t("pages.invoice.create.zipName"));
          setProjects(
            projects.filter((project) => !selectedProjects.includes(project.id))
          );
          setSelectedProjects([]);
        }
      });
  };

  return (
    <>
      <TopBar title={t("pages.invoice.create.topBarHeadline")} />
      {isUserAllowedToDo(user.right, Right.INVOICE_CREATE) && (
        <Box title={t("pages.invoice.create.endDate")}>
          <div className="invoice-create__company-wrapper">
            {loadedCompanies.map((company) => (
              <Button
                value={company.name}
                onClick={() =>
                  selectedCompany === company.id
                    ? setSelectedCompany(undefined)
                    : setSelectedCompany(company.id)
                }
                active={selectedCompany === company.id}
              />
            ))}
          </div>
          <div className="invoice-create__header-wrapper">
            <Input
              type="date"
              onChangeDate={(date) => {
                if (!date) return;
                if (dayjs(activeLockDate).isBefore(date))
                  setSelectedEndDate(date);
                else
                  generateNotification({
                    type: NotificationType.ERROR,
                    value: t("general.notification.error.lockedByLockDate"),
                  });
              }}
              value={selectedEndDate}
              maxWidth={500}
            />
            <Button
              value={t("general.buttons.createInvoice")}
              type="button"
              onClick={handleCreateInvoices}
            />
          </div>
          <Table
            rows={rows}
            header={
              t("pages.invoice.create.tableHeader", {
                returnObjects: true,
              }) as TableHeader[]
            }
          />
        </Box>
      )}
    </>
  );
};
