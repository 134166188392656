import { Box, Button, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createAndSendDunnings,
  generateNotification,
  Language,
  updateSchambeckUserTableConfig,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { useUser } from "../../components/UserContext";
import { convertDueInvoicesIntoTableEntries } from "../../utils/invoice/Invoice.utils";
import { generateEmptyMailConfiguration } from "../../utils/mail/Mail.utils";

export const DunningOverview: React.FC = () => {
  const { t } = useTranslation();
  const { axios, user, updateContextUser } = useUser();

  const { data: dueInvoices } = useData("INVOICE_DUE", {
    config: { fallbackData: [] },
  });

  const { data: allCustomers } = useData("CUSTOMER_ALL", {
    config: { fallbackData: [] },
  });

  const { data: allOrders } = useData("ORDER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allDunningLevels } = useData("DUNNING_ALL", {
    config: { fallbackData: [] },
  });

  const maxDunningLevel: number = useMemo(
    (): number => allDunningLevels.sort((a, b) => b.level - a.level)[0]?.level,
    [allDunningLevels]
  );

  const [selectedReceipts, setSelectedReceipts] = useState<string[]>([]);

  /**
   * Holds due invoices as TableRows
   */
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertDueInvoicesIntoTableEntries(
        dueInvoices,
        selectedReceipts,
        setSelectedReceipts,
        allOrders,
        allCustomers,
        maxDunningLevel
      ),
    [allCustomers, allOrders, dueInvoices, maxDunningLevel, selectedReceipts]
  );

  /**
   * Submit method to create the selected dunnings and send them via mail
   * @returns Promise that is used for the button loading state
   */
  const handleSubmit = async (): Promise<void> => {
    if (selectedReceipts.length < 1) return Promise.resolve();
    return createAndSendDunnings(axios, {
      ...generateEmptyMailConfiguration({ language: Language.DE }),
      activeUserId: user.id,
      invoiceIds: selectedReceipts,
    }).then((success) => {
      if (success) {
        generateNotification({
          type: NotificationType.SUCCESS,
          value: t("general.notification.success.sendDunnings"),
        });
      }
    });
  };
  return (
    <>
      <TopBar title={t("pages.dunningOverview.topBarHeadline")}>
        <Button value={t("general.buttons.send")} onClick={handleSubmit} />
      </TopBar>
      <Box>
        <Table
          tableConfig={user.tableConfig?.get(TableKey.DUNNING_OVERVIEW)}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.DUNNING_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          rows={rows}
          header={
            t("pages.dunningOverview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
