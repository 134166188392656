import { Box, Table, TopBar } from "@sam/components";
import {
  TableHeader,
  TableKey,
  TableRow,
} from "@sam/components/src/Table/Table.types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Right, updateSchambeckUserTableConfig, useData } from "shared";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";
import { ReactComponent as DisabledIcon } from "../../assets/disabled.svg";
import { useUser } from "../../components/UserContext";
import { convertBikesIntoTableEntries } from "../../utils/bike/Bike.utils";
import { isUserAllowedToDo } from "../../utils/user/User.utils";

const BikeOverview: React.FC = () => {
  const { user, axios, updateContextUser } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState<string>("");
  const [includeDeactivated, toggleIncludeDeactivated] =
    useState<boolean>(false);

  const { data: bikes } = useData("BIKES_ALL", {
    config: { fallbackData: [], isPaused: () => !includeDeactivated },
  });
  const { data: activeBikes } = useData("BIKES_ALL_ACTIVE", {
    config: { fallbackData: [], isPaused: () => includeDeactivated },
  });

  /**
   * Holds tableRows for bikes
   */
  const rows: TableRow[] = useMemo(
    () =>
      convertBikesIntoTableEntries(
        user.right,
        includeDeactivated ? bikes : activeBikes,
        navigate
      ),
    [activeBikes, bikes, includeDeactivated, navigate, user.right]
  );

  return (
    <>
      <TopBar
        title={t("pages.bikes.overview.headline")}
        onSearch={setFilterValue}
      >
        {isUserAllowedToDo(user.right, Right.BIKE_CREATE) && (
          <>
            <AddIcon
              title={t("general.icons.add")}
              onClick={() => navigate("/bikes/create")}
            />
            <DisabledIcon
              style={{ color: includeDeactivated ? "#bc2e46" : "" }}
              title={t("general.icons.showInactive")}
              onClick={() => toggleIncludeDeactivated(!includeDeactivated)}
            />
          </>
        )}
      </TopBar>
      <Box>
        <Table
          tableConfig={user.tableConfig?.get(TableKey.BIKE_OVERVIEW)}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.BIKE_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.bikes.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};

export default BikeOverview;
