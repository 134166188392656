import { Box, Button, Dropdown, Popup, Table, TableRow } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DocType,
  DocumentBucket,
  FileUploadConfig,
  SchambeckDoc,
  deleteFileFromMinio,
  generateNotification,
  getDocumentListForFolders,
  readFileFromMinio,
  saveNewFile,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import {
  convertSchambeckDocsIntoTableEntries,
  downloadFile,
  getCorrectDocTypesForBucket,
} from "../../utils/files/Files.utils";
import { useUser } from "../UserContext";
import { FileOverviewProps } from "./FileOverview.types";
import "./fileOverview.scss";

export const FileOverview: React.FC<FileOverviewProps> = ({
  path,
  bucket,
  bucketsToShow = [bucket],
  customerId = "",
  userId = "",
  orderId = "",
  upload = true,
}) => {
  const { axios } = useUser();
  const { t } = useTranslation();

  const [docType, setDocType] = useState<DocType>();
  const [files, setFiles] = useState<SchambeckDoc[]>([]);
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [fileToDelete, setFileToDelete] = useState<{
    path: string;
    id: string;
    bucket: DocumentBucket;
  }>();

  const { data: loadedSimpleUsers } = useData("SIMPLEUSERS_ALL", {
    config: { fallbackData: [] },
  });

  // Hook to get a list of all documents for the bucket/path
  useEffect(() => {
    if (!path || bucketsToShow.length === 0) return;
    getDocumentListForFolders(axios, { path, buckets: bucketsToShow }).then(
      setFiles
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  /**
   * Helper method to handle the FileUpload
   * @param file to upload
   */
  const handleFileUpload = (): void => {
    if (!docType || !fileToUpload) return;
    const uploadConfig: FileUploadConfig = {
      bucket,
      customerId,
      docType,
      fileName: fileToUpload.name,
      orderId,
      path,
      userId,
    };
    saveNewFile(axios, uploadConfig, fileToUpload).then((newSchambeckDoc) => {
      if (!newSchambeckDoc) return;
      setFiles([...files, newSchambeckDoc]);
      generateNotification({
        type: NotificationType.SUCCESS,
        value: t("general.notification.success.fileUpload"),
      });
      setDocType(undefined);
      setFileToUpload(undefined);
    });
  };

  /**
   * Callback function to delete a file
   */
  const handleDeleteFile = useCallback(
    (pathToDelete: string, id: string, bucket: DocumentBucket): void => {
      deleteFileFromMinio(axios, {
        bucket,
        path: pathToDelete,
        schmabeckDocId: id,
      }).then(
        (success) => success && setFiles(files.filter((file) => file.id !== id))
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bucketsToShow, files]
  );

  /**
   * Callback funtion to download a file
   */
  const handleDownloadFile = useCallback(
    (
      pathToLoad: string,
      contentType: string,
      fileName: string,
      bucket: DocumentBucket
    ): void => {
      readFileFromMinio(axios, bucket, pathToLoad, contentType).then(
        (loadedDoc) => {
          if (!loadedDoc) return;
          downloadFile(loadedDoc, fileName);
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bucketsToShow]
  );
  /**
   * Holds TableRows containing one file per row
   */
  const rows: TableRow[] = useMemo((): TableRow[] => {
    if (!files) return [];
    return convertSchambeckDocsIntoTableEntries(
      files,
      path,
      setFileToDelete,
      handleDownloadFile,
      loadedSimpleUsers
    );
  }, [files, handleDownloadFile, path, loadedSimpleUsers]);

  /**
   * Get the correct docTypes that can be selected for uploads into the given bucket
   */
  const selectableDocTypes: DocType[] = useMemo((): DocType[] => {
    const docTypes: DocType[] = [];
    bucketsToShow.forEach((bucket) =>
      docTypes.push(...getCorrectDocTypesForBucket(bucket))
    );
    return docTypes;
  }, [bucketsToShow]);

  return (
    <Box>
      <Popup
        title={t("general.fileUpload.deleteFileTitle")}
        isOpen={!!fileToDelete}
        onClose={() => setFileToDelete(undefined)}
        buttons={[
          <Button
            type="button"
            value={t("general.buttons.cancel")}
            onClick={() => setFileToDelete(undefined)}
          />,
          <Button
            type="button"
            value={t("general.buttons.delete")}
            onClick={() => {
              fileToDelete &&
                handleDeleteFile(
                  fileToDelete.path,
                  fileToDelete.id,
                  fileToDelete.bucket
                );
              setFileToDelete(undefined);
            }}
          />,
        ]}
      >
        {t("general.fileUpload.deleteFileText")}
      </Popup>
      <Table
        rows={rows}
        header={
          t("general.fileUpload.tableHeader", {
            returnObjects: true,
          }) as TableHeader[]
        }
      />
      {upload && (
        <div className="files-overview__input-wrapper">
          <Dropdown
            label={t("general.fileUpload.selectFile")}
            options={selectableDocTypes.map((type) => ({
              label: t(`general.docType.${type}`),
              value: type,
            }))}
            onChange={(selectedType) => setDocType(selectedType as DocType)}
          />
          <input
            type="file"
            onChange={(evt) => {
              if (!evt.target.files) return;
              setFileToUpload(evt.target.files[0]);
            }}
          />
          <Button
            type="button"
            onClick={handleFileUpload}
            value={t("general.buttons.save")}
            disabled={!fileToUpload || !docType}
          />
        </div>
      )}
    </Box>
  );
};
