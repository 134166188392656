import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AbsenceReason, updateSchambeckUserTableConfig, useData } from "shared";
import { Right } from "shared/src/userRole/UserRole.types";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { NotAllowed } from "../../components/NotAllowed";
import { useUser } from "../../components/UserContext";
import FilterComponent from "../../components/filterComponent/FilterComponent";
import { convertAbsenceReasonsIntoTableEntries } from "../../utils/absenceReason/AbsenceReason.utils";
import { isUserAllowedToDo } from "../../utils/user/User.utils";

const AbsenceReasonOverview: React.FC = () => {
  const { user, updateContextUser, axios } = useUser();

  const [filteredReasons, setFilteredReasons] = useState<AbsenceReason[]>([]);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: simpleUsers } = useData("SIMPLEUSERS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedReasons } = useData("ABSENCE_REASONS_ALL", {
    config: { fallbackData: [] },
  });

  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertAbsenceReasonsIntoTableEntries(
        user.right,
        filteredReasons,
        simpleUsers,
        navigate
      ),
    [filteredReasons, navigate, simpleUsers, user.right]
  );
  return isUserAllowedToDo(user.right, Right.ABSENCE_REASON_OVERVIEW) ? (
    <>
      <TopBar title={t("pages.absenceReasons.overview.headline")}>
        {isUserAllowedToDo(user.right, Right.ABSENCE_REASON_CREATE) && (
          <PlusIcon
            title={t("general.icons.add")}
            onClick={() => navigate("/absence/reason/create")}
          />
        )}
      </TopBar>
      {loadedReasons[0] && (
        <FilterComponent
          entries={loadedReasons.map((entry) => ({
            ...entry,
          }))}
          setFilteredEntries={(reasons) =>
            setFilteredReasons(reasons as AbsenceReason[])
          }
        />
      )}
      <Box>
        <Table
          tableConfig={user.tableConfig?.get(TableKey.ABSENCE_REASON_OVERVIEW)}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.ABSENCE_REASON_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          rows={rows}
          header={
            t("pages.absenceReasons.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  ) : (
    <NotAllowed neccessaryRight={Right.ABSENCE_REASON_OVERVIEW} />
  );
};
export default AbsenceReasonOverview;
