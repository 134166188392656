import { Box, Button, Input, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { FormEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createNewLockDate,
  generateNotification,
  LockDate,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { useUser } from "../../components/UserContext";
import { generateEmptyLockDate } from "../../utils/lockDate/LockDate.util";

export const LockDatePage: React.FC = () => {
  const { axios } = useUser();
  const [newLockDate, setNewLockDate] = useState<LockDate>(
    generateEmptyLockDate()
  );
  const [activeLockDate, setActiveLockDate] = useState<Date>();
  const { t } = useTranslation();
  const { data: loadedCompanies } = useData("COMPANY_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: activeLockDates, mutate } = useData("LOCKDATE_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  /**
   * Submit handler to create a new lockDate
   * @param evt formEvent to prevent default behaviour
   */
  const handleSubmit = (evt: FormEvent): void => {
    evt.preventDefault();
    if (!newLockDate || newLockDate.companyId === "") {
      generateNotification({
        type: NotificationType.ERROR,
        value: t(
          `general.notification.error.createNewLockDate${
            newLockDate ? "Company" : "Date"
          }`
        ),
      });
      return;
    }
    createNewLockDate(axios, newLockDate).then((createdLockDate) => {
      if (createdLockDate) {
        setNewLockDate(createdLockDate);
        generateNotification({
          type: NotificationType.SUCCESS,
          value: t("general.notification.success.createNewLockDate"),
        });
        mutate();
      }
    });
  };

  /**
   * Holds the entries for the companies
   */
  const rows: TableRow[] = useMemo((): TableRow[] => {
    return loadedCompanies.map((company) => {
      const existingDate: Date | undefined = activeLockDates.find(
        (lockDate) => lockDate.companyId === company.id
      )?.date;
      return {
        id: company.id,
        onClick: () => {
          setActiveLockDate(existingDate);
          setNewLockDate({ ...newLockDate, companyId: company.id });
        },
        content: [
          company.name,
          activeLockDates
            .find((date) => date.companyId === company.id)
            ?.date.toLocaleDateString("DE-de") || "-",
        ],
      };
    });
  }, [activeLockDates, loadedCompanies, newLockDate]);

  return (
    <form onSubmit={handleSubmit}>
      <TopBar title={t("pages.lockDate.topBarHeadline")} />
      <Box title={t("pages.lockDate.editLockDate")}>
        <Input
          label={t("pages.lockDate.selectedCompany")}
          maxWidth={400}
          type="text"
          value={
            loadedCompanies.find(
              (company) => company.id === newLockDate.companyId
            )?.name || "-"
          }
        />

        <div className="lockdate-wrapper">
          <div className="lockdate-wrapper__active-date">
            <p>{t("pages.lockDate.activeDate")}</p>
            <p>
              {activeLockDate
                ? activeLockDate.toLocaleDateString("DE-de")
                : t("pages.lockDate.noDate")}
            </p>
          </div>
          <Input
            maxWidth={300}
            label={t("pages.lockDate.newDate")}
            type="date"
            value={newLockDate.date}
            onChangeDate={(date) =>
              date && setNewLockDate({ ...newLockDate, date })
            }
          />
          <Button
            type="submit"
            value={t("general.buttons.save")}
            disabled={!newLockDate.companyId}
          />
        </div>
      </Box>
      <Box>
        <Table
          rows={rows}
          header={
            t("pages.lockDate.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </form>
  );
};
