/**
 * Interface to store additional infos to saved files
 */
export interface SchambeckDoc {
  id: string;
  createdBy: string;
  createDate: Date;
  updatedBy: string;
  lastUpdate: Date;
  type: DocType;
  name: string;
  etag: string;
  userId: string;
  customerId: string;
  orderId: string;
  contentType: string;
  bucket: DocumentBucket;
}

/**
 * Different types for documents
 */
export enum DocType {
  REQUEST_DOCUMENT = "REQUEST_DOCUMENT",
  CALCULATION = "CALCULATION",
  FEASIBILITY = "FEASIBILITY",
  OTHERS = "OTHERS",
  OFFER = "OFFER",
  TEST_INSTRUCTION = "TEST_INSTRUCTION",
  ERROR_CATALOG = "ERROR_CATALOG",
  WORK_INSTRUCTION = "WORK_INSTRUCTION",
  PROJECT_ORDER = "PROJECT_ORDER",
  PROTOCOL = "PROTOCOL",
  CONTRACT_INFORMATION = "CONTRACT_INFORMATION",
  USER_DATA = "USER_DATA",
  PERSONAL_DATA = "PERSONAL_DATA",
  CERTIFICATE = "CERTIFICATE",
  WRITING_TRAFFIC = "WRITING_TRAFFIC",
  DAMAGE_REPORT = "DAMAGE_REPORT",
  DELIVERY_NOTE = "DELIVERY_NOTE",
  CREDIT_NOTE = "CREDIT_NOTE",
  CANCEL = "CANCEL",
  AGB = "AGB",
}
/**
 * Buckets to save files in on the minio objectStorage
 */
export enum DocumentBucket {
  OFFER = "OFFER",
  PROJECT = "PROJECT",
  USER = "USER",
  CAR = "CAR",
  BIKE = "BIKE",
  INVOICE = "INVOICE",
  INVENTORY = "INVENTORY",
  COMPANY = "COMPANY",
}

/**
 * Request model containing the path and bucket to search a file
 */
export interface FileRequest {
  bucket: DocumentBucket;
  path: string;
}
/**
 * Request model containing the path and bucket to search a file
 */
export interface MultiFileRequest {
  buckets: DocumentBucket[];
  path: string;
}

/**
 * Neccessary config to upload a file with all information
 */
export interface FileUploadConfig {
  path: string;
  bucket: DocumentBucket;
  fileName: string;
  docType: DocType;
  userId: string;
  orderId: string;
  customerId: string;
}

/**
 * Interface for a delete file Request containing the path and the corrosponding schambeckDoc
 */
export interface FileDeleteRequest {
  path: string;
  bucket: DocumentBucket;
  schmabeckDocId: string;
}
