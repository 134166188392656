import { TableRow } from "@sam/components";
import { Dispatch, SetStateAction } from "react";
import {
  DocType,
  DocumentBucket,
  SchambeckDoc,
} from "../../../../shared/src/files/Files.types";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import i18n from "../../i18n/i18n";
import { SchambeckUser, SimpleUser } from "shared";
import { getUserNameForSimpleUser } from "../user/User.utils";
/**
 * Util method to trigger download of a file
 * @param blob to download
 * @param filename of the download file
 */
export const downloadFile = (blob: Blob, filename: string): void => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

/**
 * Util method to convert SchambeckDocs into TableEntries
 * @param docs to convert
 * @param path path to be used for delete and download action
 * @param handleDelete method to delete a file from minio and delete the corrosponding schambeckDoc
 * @param handleDownloadFile method to download a file onClick
 * @returns Array of generated TableEntries
 */
export const convertSchambeckDocsIntoTableEntries = (
  docs: SchambeckDoc[],
  path: string,
  handleDelete: Dispatch<
    SetStateAction<
      { path: string; id: string; bucket: DocumentBucket } | undefined
    >
  >,
  handleDownloadFile: (
    path: string,
    contentType: string,
    fileName: string,
    bucket: DocumentBucket
  ) => void,
  users: SimpleUser[] | SchambeckUser[]
): TableRow[] => {
  return docs.map((doc) => ({
    id: doc.id,
    content: [
      doc.name,
      doc.createDate.toLocaleDateString("de-DE"),
      getUserNameForSimpleUser(doc.createdBy, users),
      <div>
        <DeleteIcon
          title={i18n.t("general.icons.delete")}
          width={30}
          onClick={(evt) => {
            evt.preventDefault();
            handleDelete({
              path: path + doc.name,
              id: doc.id,
              bucket: doc.bucket,
            });
          }}
        />
        <DownloadIcon
          width={30}
          onClick={(evt) => {
            evt.preventDefault();
            handleDownloadFile(
              path + doc.name,
              doc.contentType,
              doc.name,
              doc.bucket
            );
          }}
        />
      </div>,
    ],
  }));
};

/**
 * Util method to get the correct Doctypes for a given bucket
 * @param bucket to get the doctypes for
 * @returns  Array containing all possible doctypes
 */
export const getCorrectDocTypesForBucket = (
  bucket: DocumentBucket
): DocType[] => {
  switch (bucket) {
    case DocumentBucket.OFFER:
      return [
        DocType.REQUEST_DOCUMENT,
        DocType.CALCULATION,
        DocType.FEASIBILITY,
        DocType.OTHERS,
      ];
    case DocumentBucket.USER:
      return [
        DocType.CONTRACT_INFORMATION,
        DocType.USER_DATA,
        DocType.PERSONAL_DATA,
        DocType.CERTIFICATE,
        DocType.WRITING_TRAFFIC,
        DocType.OTHERS,
      ];
    case DocumentBucket.PROJECT:
      return [
        DocType.OFFER,
        DocType.TEST_INSTRUCTION,
        DocType.ERROR_CATALOG,
        DocType.WORK_INSTRUCTION,
        DocType.PROJECT_ORDER,
        DocType.PROTOCOL,
        DocType.OTHERS,
      ];
    case DocumentBucket.CAR:
      return [
        DocType.CONTRACT_INFORMATION,
        DocType.DAMAGE_REPORT,
        DocType.OTHERS,
      ];
    case DocumentBucket.BIKE:
      return [
        DocType.CONTRACT_INFORMATION,
        DocType.DAMAGE_REPORT,
        DocType.OTHERS,
      ];
    case DocumentBucket.INVENTORY:
      return [DocType.OTHERS];
    case DocumentBucket.INVOICE:
      return [DocType.OTHERS];
    default:
      return [];
  }
};
