import { TableRow } from "@sam/components";
import { Company, Language } from "shared";
import i18n from "../../i18n/i18n";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { NavigateFunction } from "react-router-dom";

/**
 * Util method to convert companies into TableRows
 * @param companies companies to convert
 * @param navigate navigateFunction
 * @returns Array of TableRows
 */
export const convertCompaniesToTableRows = (
  companies: Company[],
  navigate: NavigateFunction
): TableRow[] => {
  return companies.map((company) => ({
    id: company.id,
    onClick: () => navigate("/company/edit", { state: { company } }),
    content: [
      company.name,
      company.disabled
        ? i18n.t("pages.company.overview.deactivated")
        : i18n.t("pages.company.overview.active"),
      company.numberIdentifier,
      <div className="table-action__icon-wrapper">
        <EditIcon
          title={i18n.t("general.icons.edit")}
          onClick={() => navigate("/company/edit", { state: { company } })}
        />
      </div>,
    ],
  }));
};

/**
 * Util method to generate an empty company
 * @param override partial of a company to adjust
 * @returns created Company
 */
export const generateEmptyCompany = (override?: Partial<Company>): Company => ({
  createDate: new Date(),
  createdBy: "",
  disabled: false,
  id: undefined!,
  name: "",
  numberIdentifier: 0,
  ceoName: "",
  courtLocation: "",
  location: "",
  nightBonusEnd: 0,
  nightBonusStart: 0,
  receiptLanguage: Language.DE,
  taxRate: 0,
  wkz: "",
  agbPath: new Map<Language, string>(),
  ...override,
});
