import { Box, Table, TableRow, TopBar } from "@sam/components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { updateSchambeckUserTableConfig, useData } from "shared";
import { useMemo } from "react";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import { convertWorkEquipmentIntoTableEntries } from "../../utils/workEquipment/WorkEquipment.utils";
import { useUser } from "../../components/UserContext";

export const WorkEquipmentOverview: React.FC = () => {
  const { t } = useTranslation();
  const { user, axios, updateContextUser } = useUser();
  const navigate = useNavigate();
  const { data: loadedWorkEquipment } = useData("WORK_EQUIPMENT_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * TableRows containing all workEquipmentEntries
   */
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertWorkEquipmentIntoTableEntries(loadedWorkEquipment, navigate),
    [loadedWorkEquipment, navigate]
  );
  return (
    <>
      <TopBar title={t("pages.workEquipment.overview.topBarHeadline")}>
        <PlusIcon
          title={t("general.icons.add")}
          onClick={() => navigate("edit")}
        />
      </TopBar>
      <Box>
        <Table
          tableConfig={user.tableConfig?.get(TableKey.WORK_EQUIPMENT_OVERVIEW)}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.WORK_EQUIPMENT_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          rows={rows}
          header={
            t("pages.workEquipment.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
